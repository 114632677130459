import { useContext } from "react";
import find from "lodash/find";
import get from "lodash/get";
import forEach from "lodash/forEach";
import lowerCase from "lodash/lowerCase";
import { DataContext } from "../../Contexts/MainContext";
import { BUTTON, INPUT, MONO_COUNTER, WALLONIA } from "../../../utils/constants";
import {
	computeConsumptionAndInjection,
	formatFlags,
	getRegionByPostCode
} from "../../../utils/mappers";

const applyQ3RG = (energy: string) => {
	if (energy == "G") {
		// If Energy is Gas.
		return "Q4";
	} else {
		// If Energy is E or EG.
		return "Q7";
	}
}
const applyQ7RG = (hasPanels: boolean, postCode: string) => {
	const isWallonia = getRegionByPostCode(postCode) == WALLONIA;

	if (hasPanels) {
		if (isWallonia) {
			// If user has panels.
			return "Q21";
		} else {
			// If User doesn't have panels.
			return "Q8";
		}
	} else {
		// If User doesn't have panels.
		return "Q4";
	}
}
const applyQ4RG = (energy: string, isFluviusGRD: boolean) => {
	if (energy == "G") {
		// If Energy is Gas.
		return "Q9";
	} else {
		// If Energy is E or EG
		if (isFluviusGRD) {// TODO: Add souldBypassLead restriction.
			// If isFluviusGRD && !state.partner.shouldBypassLead.
			return "Q20";
		} else {
			// Else.
			return "Q5";
		}
	}
}
const applyQ5RG = (remote2Way: boolean) => {
	if (remote2Way) {
		// If Energy is Gas.
		return "Q19";
	} else {
		// If Energy is E or EG
		return "Q9";
	}
}
export const applyQ9RG = (hasConsumption: boolean, isPro: boolean) => {
	if (hasConsumption) {
		// If user knows his consumption.
		return "Q10";
	} else {
		// If user doesn't know his consumption.
		if (isPro) {
			// If user is a professional.
			return "Q17";
		} else {
			// If user is an individual.
			return "Q13";
		}
	}
}
const applyQ10RG = (shouldAskInj: boolean) => {
	if (shouldAskInj) {

		return "Q12";
	} else {

		return "END";
	}
}

export const getNextQuestionIdWithProfile = (currentState: any, currentQuestionId: string, currentQuestionAnswer: any): any => {
	switch (currentQuestionId) {
		case "Q1":

			return {
				updatedUserDetails: {
					userType: get(currentQuestionAnswer, `values.0.value`, "")
				},
				nextQuestionId: "Q2"
			};
		case "Q2":

			return {
				updatedUserDetails: {
					energy: get(currentQuestionAnswer, `values.0.value`, "")
				},
				nextQuestionId: "Q3"
			};
		case "Q3":
			const questionQ2 = find(currentState.questionsPile, (itemQ) => itemQ.questionId == "Q2");

			return {
				updatedUserDetails: {
					postCode: get(currentQuestionAnswer, `values.0.value.code`, ""),
					locality: get(currentQuestionAnswer, `values.0.value.locality`, ""),
					isMainResidence: get(currentQuestionAnswer, `flags.AN46`, true)
				},
				nextQuestionId: applyQ3RG(get(questionQ2, `values.0.value`, ""))
			};
		case "Q4":
			const userEnergy = get(currentState, "userDetails.energy", "");
			const funnelGrd = get(currentState, "userDetails.funnelGrd", "");
			const isFluvius = lowerCase(funnelGrd) == "fluvius";

			return {
				updatedUserDetails: {
					hasDigitalMeter: formatFlags(get(currentQuestionAnswer, `values.0.value`, "false"))
				},
				nextQuestionId: applyQ4RG(userEnergy, isFluvius)
			};
		case "Q7":
			const question7Ans = formatFlags(get(currentQuestionAnswer, `values.0.value`, "false"));
			const postCode = get(currentState, "userDetails.postCode", "");

			return {
				updatedUserDetails: {
					hasPanels: question7Ans
				},
				nextQuestionId: applyQ7RG(question7Ans, postCode)
			};
		case "Q5":
			const isRemote2Way = get(currentState, "userDetails.isRemote2Way", "");

			return {
				updatedUserDetails: {
					isMono: get(currentQuestionAnswer, `values.0.value`, "1") == MONO_COUNTER,
					hasExclNight: formatFlags(String(get(currentQuestionAnswer, `flags.AN11`, "false")))
				},
				nextQuestionId: applyQ5RG(isRemote2Way)
			};
		case "Q8":
			const panelsPower = get(currentQuestionAnswer, `values.0.value`, "");

			return {
				updatedUserDetails: {
					panelsPower: panelsPower
				},
				nextQuestionId: "Q4"
			};
		case "Q9":
			const userType = get(currentState, "userDetails.userType", "");
			const isConsumptionKnown = formatFlags(String(get(currentQuestionAnswer, `values.0.value`, "false")));

			return {
				updatedUserDetails: {
					isConsumptionKnown: isConsumptionKnown
				},
				nextQuestionId: applyQ9RG(isConsumptionKnown, userType == "P")
			};
		case "Q10":
			const shouldAskInj = formatFlags(get(currentState, "userDetails.shouldAskInj", "false"));
			const isConsumptionNet = formatFlags(String(get(currentQuestionAnswer, `flags.AN41`, "false")));
			let indexes = {
				elecIndex: "",
				elecNightIndex: "",
				elecExclNightIndex: "",
				gasIndex: ""
			};

			forEach(get(currentQuestionAnswer, `values`, []), (item: any) => {
				if (item.id == "AN19") {
					indexes.elecIndex = item.value;
				}
				if (item.id == "AN38") {
					indexes.elecNightIndex = item.value;
				}
				if (item.id == "AN39") {
					indexes.elecExclNightIndex = item.value;
				}
				if (item.id == "AN40") {
					indexes.gasIndex = item.value;
				}
			});

			if (shouldAskInj) {
				const calculatedConsumption = computeConsumptionAndInjection(currentState.userDetails, indexes, isConsumptionNet);

				indexes = {
					...indexes,
					...calculatedConsumption
				};
			}

			return {
				updatedUserDetails: {
					isConsumptionNet: isConsumptionNet,
					...indexes,
				},
				nextQuestionId: applyQ10RG(shouldAskInj)
			};
		case "Q13":

			return {
				updatedUserDetails: {
					housingType: get(currentQuestionAnswer, `values.0.value`, "")
				},
				nextQuestionId: "Q14"
			};
		case "Q14":

			return {
				updatedUserDetails: {
					familyComposition: get(currentQuestionAnswer, `values.0.value`, "")
				},
				nextQuestionId: "Q15"
			};
		case "Q15":

			return {
				updatedUserDetails: {
					heatingEnergy: get(currentQuestionAnswer, `values.0.value`, "")
				},
				nextQuestionId: "END"
			};
		case "Q17":

			return {
				updatedUserDetails: {
					professionalProfile: get(currentQuestionAnswer, `values.0.value`, "")
				},
				nextQuestionId: "Q18"
			};
		case "Q18":

			return {
				updatedUserDetails: {
					heatingEnergy: get(currentQuestionAnswer, `values.0.value`, "")
				},
				nextQuestionId: "END"
			};
		case "Q12":
			let injIndexes = {
				elecPreIndex: "",
				elecPreNightIndex: "",
				elecInjIndex: "",
				elecInjNightIndex: ""
			};

			forEach(get(currentQuestionAnswer, `values`, []), (item: any) => {
				if (item.id == "AN42") {
					injIndexes.elecPreIndex = item.value;
				}
				if (item.id == "AN43") {
					injIndexes.elecPreNightIndex = item.value;
				}
				if (item.id == "AN44") {
					injIndexes.elecInjIndex = item.value;
				}
				if (item.id == "AN45") {
					injIndexes.elecInjNightIndex = item.value;
				}
			});

			return {
				updatedUserDetails: injIndexes,
				nextQuestionId: "END"
			};
		case "Q19":

			return {
				updatedUserDetails: {
					userPeakPower: get(currentQuestionAnswer, `values.0.value`, "")
				},
				nextQuestionId: "Q9"
			};
		case "Q20":

			return {
				updatedUserDetails: {
					hasEDrive: formatFlags(get(currentQuestionAnswer, `values.0.value`, "false"))
				},
				nextQuestionId: "Q5"
			};
		case "Q21":
			let compensationValue = true;
			const isWallonia = getRegionByPostCode(currentState.userDetails.postCode) == WALLONIA;
			const hasPanelsFlag = get(currentState, "userDetails.hasPanels", false);
			const question8Ans = formatFlags(get(currentQuestionAnswer, `values.0.value`, "true"));

			if (isWallonia && hasPanelsFlag && !question8Ans) {
				compensationValue = false;
			}

			return {
				updatedUserDetails: {
					compensationIsAuth: compensationValue
				},
				nextQuestionId: "Q8"
			};
		default:
			return {
				nextQuestionId: ""
			};
	}
};

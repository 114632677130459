import React, { useReducer } from "react";
import { emptyState, IState } from "../Models/Main";
import { partnerReducer } from "./partner/reducer";
import { leadReducer } from "./lead/reducer";
import { StateAction } from "./stateAction";
import { surveyReducer } from "./Survey/reducer";
import { globalReducer } from "./global/reducer";
import { profilReducer } from "./Profil/reducer";
import { simulationReducer } from "./Simulation/reducer";
import { bspLeadReducer } from "./BspLead/reducer";

const initialDispatch: React.Dispatch<StateAction> = () => { };

const DataContext = React.createContext({
	state: emptyState,
	dispatch: initialDispatch
});

const mainReducer = (state: IState, action: StateAction) => ({
	global: globalReducer(state.global, action),
	lead: leadReducer(state.lead, action),
	partner: partnerReducer(state.partner, action),
	survey: surveyReducer(state.survey, action),
	profil: profilReducer(state.profil, action),
	simulation: simulationReducer(state.simulation, action),
	BSP: bspLeadReducer(state.BSP, action)
});

const DataProvider = ({ children }: { children: React.ReactNode }) => {
	// @ts-ignore
	const [state, dispatch] = useReducer(mainReducer, emptyState);

	return (
		<DataContext.Provider value={{ state, dispatch }}>
			{children}
		</DataContext.Provider>
	);
};

export { DataProvider, DataContext };

import * as _ from "lodash";
import { IQuestion } from "../src/Models/Question";
import { emptyAnswer, IAnswer } from "../src/Models/Answer";
import { ISurvey } from "../src/Models/Survey";

export const ERR_MATRIX_CODES: number[] = [16, 17, 25, 26, 34, 35];

export const BRUSSELS = "BRUSSELS";
export const WALLONIA = "WALLONIA";
export const FLANDERS = "FLANDERS";

export const BUTTON = "BUTTON";
export const LINK = "LINK";
export const INPUT = "INPUT";
export const CHECKBOX = "CHECKBOX";
export const ANNUM_CONSUMPTION = "ANNUM_CONSUMPTION";
export const ANNUM_INJECTION = "ANNUM_INJECTION";
export const POSTCODE_QUESTION = "POSTCODE_QUESTION";

export const INDIVIDUAL = "R";
export const PROFESSIONAL = "P";

export const IS_ELEC = "E";
export const IS_GAS = "G";
export const IS_EG = "EG";

export const MONO_COUNTER = "1";
export const BI_COUNTER = "2";

export const ANSWERS_DATA: IAnswer[] = [
    {
        answerID: "AN1",
        answerLabel: "survey.answers.AN1",
        answerType: "",
        nextQuestionID: "Q2",
        answerValue: "pro",
        answerValueType: "",
        keyPath: "personalData.isPro"
    },
    {
        answerID: "AN2",
        answerLabel: "survey.answers.AN2",
        answerType: "",
        nextQuestionID: "Q2",
        answerValue: "part",
        answerValueType: "",
        keyPath: "personalData.isPro"
    },
    {
        answerID: "AN3",
        answerLabel: "survey.answers.AN3",
        answerType: "radio",
        nextQuestionID: "Q3",
        answerValue: "E",
        answerValueType: ""
    },
    {
        answerID: "AN4",
        answerLabel: "survey.answers.AN4",
        answerType: "radio",
        nextQuestionID: "Q3",
        answerValue: "G",
        answerValueType: ""
    },
    {
        answerID: "AN5",
        answerLabel: "survey.answers.AN5",
        answerType: "radio",
        nextQuestionID: "Q3",
        answerValue: "EG",
        answerValueType: ""
    },
    {
        answerID: "AN6",
        answerLabel: "survey.answers.AN6",
        answerType: "input",
        nextQuestionID: "Q7",
        answerValue: "",
        answerValueType: "",
        keyPath: "delivery.address.postalCode"
    },
    {
        answerID: "AN7",
        answerLabel: "survey.answers.AN7",
        answerType: "",
        nextQuestionID: "Q20",
        answerValue: "yes",
        answerValueType: "",
        keyPath: "delivery.elec.hasDigitalCounter"
    },
    {
        answerID: "AN8",
        answerLabel: "survey.answers.AN8",
        answerType: "",
        nextQuestionID: "Q20",
        answerValue: "no",
        answerValueType: "",
        keyPath: "delivery.elec.hasDigitalCounter"
    },
    {
        answerID: "AN9",
        answerLabel: "survey.answers.AN9",
        answerType: "radio",
        nextQuestionID: "Q9",
        answerValue: "1",
        answerValueType: "",
        icon: "monohoraire.png",
        keyPath: "delivery.elec.elecCounterType"
    },
    {
        answerID: "AN10",
        answerLabel: "survey.answers.AN10",
        answerType: "radio",
        nextQuestionID: "Q9",
        answerValue: "2",
        answerValueType: "",
        icon: "bihoraire.png",
        keyPath: "delivery.elec.elecCounterType"
    },
    {
        answerID: "AN11",
        answerLabel: "survey.answers.AN11",
        answerType: "select",
        nextQuestionID: "Q9",
        answerValue: "",
        answerValueType: "",
        keyPath: "delivery.elec.hasExlNightEanCode"
    },
    {
        answerID: "AN12",
        answerLabel: "survey.answers.AN12",
        answerType: "radio",
        nextQuestionID: "Q8",// TODO: Q21 If PostCode is in Wallonia
        answerValue: "yes",
        answerValueType: "",
        keyPath: "delivery.elec.hasPanels"
    },
    {
        answerID: "AN13",
        answerLabel: "survey.answers.AN13",
        answerType: "radio",
        nextQuestionID: "Q4",
        answerValue: "no",
        answerValueType: "",
        keyPath: "delivery.elec.hasPanels"
    },
    {
        answerID: "AN14",
        answerLabel: "survey.answers.AN14",
        answerType: "input",
        nextQuestionID: "Q4",
        answerValue: "",
        answerValueType: "",
        valueType: "Number",
        unit: "survey.commons.kva"
    },
    {
        answerID: "AN15",
        answerLabel: "survey.answers.AN15",
        answerType: "link",
        nextQuestionID: "Q4",
        answerValue: "4.5",
        answerChecked: false,
        answerValueType: ""
    },
    {
        answerID: "AN16",
        answerLabel: "survey.answers.AN16",
        answerType: "select",
        nextQuestionID: "Q10",
        answerValue: "yes",
        answerValueType: "",
        keyPath: "delivery.autoDeterminedInj"
    },
    {
        answerID: "AN17",
        answerLabel: "survey.answers.AN17",
        answerType: "select",
        nextQuestionID: "Q17",
        answerValue: "no",
        answerValueType: "",
        isProfessional: true,
        keyPath: "delivery.autoDeterminedInj"
    },
    {
        answerID: "AN18",
        answerLabel: "survey.answers.AN18",
        answerType: "select",
        nextQuestionID: "Q13",
        answerValue: "no",
        answerValueType: "",
        isProfessional: false,
        keyPath: "delivery.autoDeterminedInj"
    },
    {
        answerID: "AN19",
        answerLabel: "survey.answers.AN19",
        answerType: "input",
        nextQuestionID: "Q12",
        answerValue: "",
        answerValueType: ""
    },
    {
        answerID: "AN20",
        answerLabel: "survey.answers.AN20",
        answerType: "",
        nextQuestionID: "",
        answerValue: "",
        answerValueType: "select"
    },
    {
        answerID: "AN21",
        answerLabel: "survey.answers.AN21",
        answerType: "radio",
        nextQuestionID: "Q14",
        answerValue: "2",
        answerValueType: ""
    },
    {
        answerID: "AN22",
        answerLabel: "survey.answers.AN22",
        answerType: "radio",
        nextQuestionID: "Q14",
        answerValue: "1",
        answerValueType: ""
    },
    {
        answerID: "AN23",
        answerLabel: "survey.answers.AN23",
        answerType: "radio",
        nextQuestionID: "Q14",
        answerValue: "3",
        answerValueType: ""
    },
    {
        answerID: "AN24",
        answerLabel: "survey.answers.AN24",
        answerType: "radio",
        nextQuestionID: "Q14",
        answerValue: "4",
        answerValueType: ""
    },
    {
        answerID: "AN25",
        answerLabel: "survey.answers.AN25",
        answerType: "radio",
        nextQuestionID: "Q15",
        answerValue: "1",
        answerValueType: ""
    },
    {
        answerID: "AN26",
        answerLabel: "survey.answers.AN26",
        answerType: "radio",
        nextQuestionID: "Q15",
        answerValue: "2",
        answerValueType: ""
    },
    {
        answerID: "AN27",
        answerLabel: "survey.answers.AN27",
        answerType: "radio",
        nextQuestionID: "Q15",
        answerValue: "3",
        answerValueType: ""
    },
    {
        answerID: "AN28",
        answerLabel: "survey.answers.AN28",
        answerType: "radio",
        nextQuestionID: "Q15",
        answerValue: "4",
        answerValueType: ""
    },
    {
        answerID: "AN29",
        answerLabel: "survey.answers.AN29",
        answerType: "radio",
        nextQuestionID: "Q15",
        answerValue: "5",
        answerValueType: ""
    },
    {
        answerID: "AN30",
        answerLabel: "survey.answers.AN30",
        answerType: "",
        nextQuestionID: "",
        answerValue: "2",
        answerValueType: ""
    },
    {
        answerID: "AN31",
        answerLabel: "survey.answers.AN31",
        answerType: "",
        nextQuestionID: "",
        answerValue: "1",
        answerValueType: ""
    },
    {
        answerID: "AN32",
        answerLabel: "survey.answers.AN32",
        answerType: "",
        nextQuestionID: "",
        answerValue: "3",
        answerValueType: ""
    },
    {
        answerID: "AN33",
        answerLabel: "survey.answers.AN33",
        answerType: "",
        nextQuestionID: "",
        answerValue: "4",
        answerValueType: ""
    },
    {
        answerID: "AN34",
        answerLabel: "survey.answers.AN34",
        answerType: "",
        nextQuestionID: "",
        answerValue: "5",
        answerValueType: ""
    },
    {
        answerID: "AN35",
        answerLabel: "survey.answers.AN35",
        answerType: "",
        nextQuestionID: "Q18",
        answerValue: "1",
        answerValueType: "",
        icon: "type.png"
    },
    {
        answerID: "AN36",
        answerLabel: "survey.answers.AN36",
        answerType: "",
        nextQuestionID: "Q18",
        answerValue: "2",
        answerValueType: "",
        icon: "pme.png"
    },
    {
        answerID: "AN37",
        answerLabel: "survey.answers.AN37",
        answerType: "",
        nextQuestionID: "Q18",
        answerValue: "3",
        answerValueType: "",
        icon: "enterprise.png"
    },
    {
        answerID: "AN38",
        answerLabel: "survey.answers.AN38",
        answerType: "input",
        nextQuestionID: "Q12",
        answerValue: "",
        answerValueType: ""
    },
    {
        answerID: "AN39",
        answerLabel: "survey.answers.AN39",
        answerType: "input",
        nextQuestionID: "Q12",
        answerValue: "",
        answerValueType: ""
    },
    {
        answerID: "AN40",
        answerLabel: "survey.answers.AN40",
        answerType: "input",
        nextQuestionID: "Q12",
        answerValue: "",
        answerValueType: ""
    },
    {
        answerID: "AN41",
        answerLabel: "survey.answers.AN42",
        answerType: "select",
        nextQuestionID: "Q12",
        answerValue: "AN41",
        answerValueType: "",
        answerChecked: false,
    },
    {
        answerID: "AN42",
        answerLabel: "survey.answers.AN42",
        answerType: "input",
        nextQuestionID: "",
        answerValue: "",
        answerValueType: ""
    },
    {
        answerID: "AN43",
        answerLabel: "survey.answers.AN43",
        answerType: "input",
        nextQuestionID: "",
        answerValue: "",
        answerValueType: ""
    },
    {
        answerID: "AN44",
        answerLabel: "survey.answers.AN44",
        answerType: "input",
        nextQuestionID: "",
        answerValue: "",
        answerValueType: ""
    },
    {
        answerID: "AN45",
        answerLabel: "survey.answers.AN45",
        answerType: "input",
        nextQuestionID: "",
        answerValue: "",
        answerValueType: ""
    },
    {
        answerID: "AN46",
        answerLabel: "survey.answers.AN46",
        answerType: "select",
        nextQuestionID: "Q7",
        answerValue: "",
        answerValueType: "",
        answerChecked: true,
        isProfessional: false
    },
    {
        answerID: "AN47",
        answerLabel: "survey.answers.AN47",
        answerType: "input",
        nextQuestionID: "Q9",
        answerValue: "",
        answerValueType: "",
        valueType: "Number",
        unit: "survey.commons.kw"
    },
    {
        answerID: "AN48",
        answerLabel: "survey.answers.AN48",
        answerType: "link",
        nextQuestionID: "Q9",
        answerValue: "2.5",
        answerChecked: false,
        answerValueType: ""
    },
    {
        answerID: "AN49",
        answerLabel: "survey.answers.AN49",
        answerType: "",
        nextQuestionID: "Q5",
        answerValue: "yes",
        answerValueType: "",
        keyPath: "delivery.elec.hasEDrive"
    },
    {
        answerID: "AN50",
        answerLabel: "survey.answers.AN50",
        answerType: "",
        nextQuestionID: "Q5",
        answerValue: "no",
        answerValueType: "",
        keyPath: "delivery.elec.hasEDrive"
    },
    {
        answerID: "AN51",
        answerLabel: "survey.answers.AN51",
        answerType: "",
        nextQuestionID: "Q8",// TODO: To Verify next Question.
        answerValue: "yes",
        answerValueType: "",
        keyPath: ""
    },
    {
        answerID: "AN52",
        answerLabel: "survey.answers.AN52",
        answerType: "",
        nextQuestionID: "Q8",// TODO: To Verify next Question.
        answerValue: "no",
        answerValueType: "",
        keyPath: ""
    }
];

export const QUESTIONS_DATA: IQuestion[] = [
    {
        questionID: "Q1",
        questionLabel: "Quel est votre profil ?",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN2") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN1") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "",
        hasMultipleOptions: true,
        hasUniqueAnswer: true,
        questionType: 'radio'
    },
    {
        questionID: "Q2",
        questionLabel: "Quel prix voulez-vous calculer ?",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN3") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN5") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN4") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q1",
        hasMultipleOptions: true,
        hasUniqueAnswer: true,
        questionType: 'radio'
    },
    {
        questionID: "Q3",
        questionLabel: "Quel est votre code postal ?",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN6") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q2",
        hasMultipleOptions: true,
        hasUniqueAnswer: false,
        questionType: 'custom',
        questionBloc: "postal"
    },
    {
        questionID: "Q4",
        questionLabel: "Disposez-vous d'un compteur digital ?",
        questionSubLabel: "",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN7") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN8") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q20",
        hasMultipleOptions: true,
        hasUniqueAnswer: true,
        questionType: 'radio'
    },
    {
        questionID: "Q5",
        questionLabel: "De quel type de compteur votre habitation dispose-t-elle ?",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN9") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN10") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN11") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q4",
        hasMultipleOptions: true,
        hasUniqueAnswer: false,
        questionType: 'mixte'
    },
    {
        questionID: "Q7",
        questionLabel: "Disposez-vous de panneaux solaires ?",
        questionSubLabel: "Ou d'une autre installation de production d'électricité.",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN12") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN13") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q3",
        hasMultipleOptions: true,
        hasUniqueAnswer: true,
        questionType: 'radio'
    },
    {
        questionID: "Q8",
        questionLabel: "Quelle est la puissance de votre onduleur ?",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN14") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN15") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q7",
        hasMultipleOptions: true,
        hasUniqueAnswer: false,
        hasUniqueValue: true,
        questionType: 'mixte'
    },
    {
        questionID: "Q9",
        questionLabel: "Connaissez-vous votre consommation annuelle ?",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN16") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN17") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN18") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q5",
        hasMultipleOptions: true,
        hasUniqueAnswer: true,
        questionType: 'radio'
    },
    {
        questionID: "Q10",
        questionLabel: "Quelle est votre consommation annuelle ?",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN19") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN38") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN39") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q9",
        hasMultipleOptions: true,
        hasUniqueAnswer: false,
        questionType: 'custom',
        questionBloc: 'conso',
        consumptionKnown: true
    },
    {
        questionID: "Q13",
        questionLabel: "Dans quel type d'habitation vivez vous ?",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN22") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN21") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN23") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN24") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q9",
        hasMultipleOptions: true,
        hasUniqueAnswer: true,
        questionType: 'radio',
        consumptionKnown: false
    },
    {
        questionID: "Q14",
        questionLabel: "Combien de personnes vivent dans le ménage ?",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN25") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN26") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN27") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN28") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN29") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q13",
        hasMultipleOptions: true,
        hasUniqueAnswer: true,
        questionType: 'radio',
        consumptionKnown: false
    },
    {
        questionID: "Q15",
        questionLabel: "Comment chauffez-vous votre habitation/bâtiment ?",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN30") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN31") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN32") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN33") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN34") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q14",
        hasMultipleOptions: true,
        hasUniqueAnswer: true,
        questionType: 'radio',
        consumptionKnown: false
    },
    {
        questionID: "Q17",
        questionLabel: "Quel est votre profil de consommation ?",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN35") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN36") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN37") || emptyAnswer
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q9",
        hasMultipleOptions: true,
        hasUniqueAnswer: true,
        questionType: 'radio',
        consumptionKnown: false
    },
    {
        questionID: "Q18",
        questionLabel: "Vous chauffer vous ...?",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN30") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN31") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN32") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN33") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN34") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q17",
        hasMultipleOptions: true,
        hasUniqueAnswer: true,
        questionType: 'radio',
        consumptionKnown: false
    },
    {
        questionID: "Q12",
        questionLabel: "Votre prélèvement et injection annuels",
        questionSubLabel: "Sur la base de votre consommation d'énergie, nous avons calculé votre prélèvement et votre injection.",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN42") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN43") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN44") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN45") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q10",
        hasMultipleOptions: true,
        hasUniqueAnswer: false,
        questionType: 'custom',
        questionBloc: 'injection',
        consumptionKnown: true
    },
    {
        questionID: "Q19",
        questionLabel: "Veuillez introduire le PicPower",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN47") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN48") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q5",
        hasMultipleOptions: true,
        hasUniqueAnswer: false,
        hasUniqueValue: true,
        questionType: 'mixte'
    },
    {
        questionID: "Q20",
        questionLabel: "Disposez-vous d'une voiture électrique ou pompe à chaleur ?",
        questionSubLabel: "Exemple: chauffage via pompe à chaleur, piscine chauffée, airco...",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN49") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN50") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q4",
        hasMultipleOptions: true,
        hasUniqueAnswer: true,
        questionType: 'radio'
    },
    {
        questionID: "Q21",
        questionLabel: "Disposez-vous d'une voiture électrique ou pompe à chaleur ?",
        questionSubLabel: "Exemple: chauffage via pompe à chaleur, piscine chauffée, airco...",
        questionOptions: [
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN51") || emptyAnswer,
            _.find(ANSWERS_DATA, (element) => element.answerID === "AN52") || emptyAnswer,
        ],
        order: 1,
        selectedAnswer: emptyAnswer,
        previousQuestionID: "Q7",
        hasMultipleOptions: true,
        hasUniqueAnswer: true,
        questionType: 'radio'
    }
];

export const IMAGES_BASE_URL = "static/img";

export const postalCodeList = [
    { key: 0, code: 1050, locality: "Ixelles" },
    { key: 1, code: 1040, locality: "Etterbeek" },
    { key: 2, code: 1030, locality: "Saint-gilles" },
    { key: 3, code: 1060, locality: "Auderghem" },
    { key: 4, code: 2100, locality: "Woluwe-saint-lambert" }
];

export const legalFormList = [
    { value: "5", i18nKey: "legalFormOptions.value5" },
    { value: "6", i18nKey: "legalFormOptions.value6" },
    { value: "7", i18nKey: "legalFormOptions.value7" },
    { value: "8", i18nKey: "legalFormOptions.value8" },
    { value: "9", i18nKey: "legalFormOptions.value9" },
    { value: "13", i18nKey: "legalFormOptions.value13" },
    { value: "14", i18nKey: "legalFormOptions.value14" },
    { value: "15", i18nKey: "legalFormOptions.value15" },
    { value: "16", i18nKey: "legalFormOptions.value16" },
    { value: "17", i18nKey: "legalFormOptions.value17" },
    { value: "18", i18nKey: "legalFormOptions.value18" },
    { value: "19", i18nKey: "legalFormOptions.value19" },
    { value: "22", i18nKey: "legalFormOptions.value22" },
    { value: "23", i18nKey: "legalFormOptions.value23" },
    { value: "25", i18nKey: "legalFormOptions.value25" },
    { value: "26", i18nKey: "legalFormOptions.value26" },
    { value: "27", i18nKey: "legalFormOptions.value27" },
    { value: "28", i18nKey: "legalFormOptions.value28" },
    { value: "29", i18nKey: "legalFormOptions.value29" },
    { value: "30", i18nKey: "legalFormOptions.value30" },
    { value: "31", i18nKey: "legalFormOptions.value31" },
    { value: "32", i18nKey: "legalFormOptions.value32" },
    { value: "999", i18nKey: "legalFormOptions.value999" }
];

export const civilityTypes = [
    { i18nKey: "civilityOptions.sir", value: 1 },
    { i18nKey: "civilityOptions.madam", value: 2 }
];

export const civilityTypesFull = [
    { i18nKey: "civilityOptions.fullSir", value: 1 },
    { i18nKey: "civilityOptions.fullMadam", value: 2 }
];

export const isProOptions = [
    { i18nKey: "yesNoOptions.no", value: 0 },
    { i18nKey: "yesNoOptions.yes", value: 1 }
];

export const withNewsletterCheckOptions = [
    { label: "confirmationFields.withNewsletter", value: true }
];

export const withMarketingCheckOptions = [
    { label: "confirmationFields.withMarketing", value: true }
];

export const telSupportCheckOptions = [
    { label: "confirmationFields.telSupport", value: true }
];

export const generalConditionCheckOptions = [
    { label: "confirmationFields.generalConditionLabel", value: true }
];

export const tvaNumberInProgressCheckOptions = [
    { label: "userDetails.tvaNumberInProgress", value: true }
];

export const tvaNumberAvailabilityOptions = [
    { i18nKey: "yesNoOptions.yes", value: 1 },
    { i18nKey: "yesNoOptions.no", value: 0 }
];

export const domAuthorizationCheckOptions = [
    { label: "billingDetails.domAuthorization", value: true }
];

export const allInvoicesCheckOptions = [
    { label: "billingDetails.allInvoices", value: true }
];

export const sameAsInvoicingAddrCheckOptions = [
    { label: "addressFields.sameAsInvoicing", value: true }
];

export const lastSupplierOptions = [
    { value: "Aspiravi Energy", i18nKey: "Aspiravi Energy" },
    { value: "Bolt", i18nKey: "Bolt" },
    { value: "Dats24", i18nKey: "Dats24" },
    { value: "Ebem", i18nKey: "Ebem" },
    { value: "Ecopower", i18nKey: "Ecopower" },
    { value: "Elegant", i18nKey: "Elegant" },
    { value: "Eneco", i18nKey: "Eneco" },
    { value: "Energie.be", i18nKey: "Energie.be" },
    { value: "Engie", i18nKey: "Engie" },
    { value: "Luminus", i18nKey: "Luminus" },
    { value: "Mega", i18nKey: "Mega" },
    { value: "OCTA+", i18nKey: "OCTA+" },
    { value: "TotalEnergies (Lampiris)", i18nKey: "TotalEnergies (Lampiris)" },
    { value: "Trevion", i18nKey: "Trevion" },
    { value: "Wase Wind", i18nKey: "Wase Wind" },
    { value: "Wind Voor 'A'", i18nKey: "Wind Voor 'A'" },
    { value: "Autre", i18nKey: "addressFields.other" }
];

export const addInjectionOptions = [
    { i18nKey: "yesNoOptions.yes", value: 2 },
    { i18nKey: "yesNoOptions.no", value: 1 }
];

export const currentSituationOptions = [
    { i18nKey: "currentSituationOptions.providerChange", value: 1 },
    { i18nKey: "currentSituationOptions.moveOut", value: 2 },
    { i18nKey: "currentSituationOptions.emptyHouse", value: 3 }
];

export const getInvoiceMethodOptions = (
    hasInternetProduct: boolean,
    isMobile: boolean
) => [
        {
            label: isMobile ? "invoiceMethodOptions.emailMobile" : "invoiceMethodOptions.email",
            value: 1,
            hasTooltip: isMobile,
            tooltipText: "invoiceMethodOptions.emailTooltip"
        },
        {
            label:
                hasInternetProduct === false || isMobile === true
                    ? "invoiceMethodOptions.post"
                    : "invoiceMethodOptions.postWithCosts",
            value: 2,
            hasTooltip: hasInternetProduct && isMobile,
            tooltipText: "invoiceMethodOptions.postWithCostsTooltip"
        }
    ];

// Not used. Checked 17/06/22.
export const productList = [
    { key: 5, logo: "productLogo.flex" },
    { key: 1, logo: "productLogo.omnium" },
    { key: 2, logo: "productLogo.relax" },
    { key: 3, logo: "productLogo.berelax" },
    { key: 10, logo: "productLogo.activplus" },
    { key: 15, logo: "productLogo.activ" },
    { key: 20, logo: "productLogo.smartfixe" },
    { key: 25, logo: "productLogo.smartvariable" },
    { key: 30, logo: "productLogo.safe" },
    { key: 5020, logo: "productLogo.cera" },
    { key: 8040, logo: "productLogo.besparenkan" },
    { key: 8040, logo: "productLogo.besparenkan" }
];

export const SURVEY_VIEW = "survey_view";
export const SIMULATION_VIEW = "simulation_view";
export const LEAD_VIEW = "lead_view";

export const counterStatusList = [
    { value: 1, i18nKey: "counterStatusOptions.open" },
    { value: 2, i18nKey: "counterStatusOptions.exist" },
    { value: 3, i18nKey: "counterStatusOptions.new" }
];

export const counterTypesList = [
    { value: 1, i18nKey: "counterTypesOptions.single" },
    { value: 2, i18nKey: "counterTypesOptions.multiple" }
];

export const panelsOptionsList = [
    { value: 1, i18nKey: "yesNoOptions.yes" },
    { value: 2, i18nKey: "yesNoOptions.no" }
];

export const billingOptionsList = [
    { value: 1, i18nKey: "billingOptionsList.dom" },
    { value: 2, i18nKey: "billingOptionsList.transfer" }
];

export const billingFrequencyList = [
    { value: 1, i18nKey: "billingFrequencyList.monthly" },
    { value: 2, i18nKey: "billingFrequencyList.bimonthly" },
    { value: 3, i18nKey: "billingFrequencyList.quarterly" }
];

export const billingSendInvoiceList = [
    { value: 1, i18nKey: "sendInvoiceModeOptions.email" },
    { value: 2, i18nKey: "sendInvoiceModeOptions.post" }
];

export const POSTAL_QUESTION_ID = "Q3";
export const PANELS_POWER_QUESTION_ID = "Q8";
export const PANELS_POWER_ANSWER_ID = "AN14";
export const AVERAGE_PANELS_POWER_ANSWER_ID = "AN15";
export const CONSUMPTION_QUESTION_ID = "Q9";
export const METERTYPE_QUESTION_ID = "Q5";
export const HAS_PANELS_QUESTION_ID = "Q7";
export const CONSUMPTION_DETAILS_QUESTION_ID = "Q10";
export const INJECTION_CONSUMPTION_DETAILS_QUESTION_ID = "Q12";
export const E_DRIVE_QUESTION_ID = "Q20";

export const mandateInfo = {
    BillingCompany: "BEC",
    CustomerNumber: "359413",
    FirstAndLastname: "DE HEER VAES GLENN",
    Street: "VINKENLAAN",
    Number: "22",
    Box: "",
    Postal: "3078",
    Locality: "EVERBERG",
    Country: "BELGIE",
    NoCompteRemb: "",
    CodeBICremb: "",
    MandateNumber: "BEC359413E20200701",
    BillingCompanyName: "OCTA+ Energie n.v.",
    BillingCompanyStreet: "Schaarbeeklei, 600",
    BillingCompanyTown: "B-1800 Vilvoorde",
    BillingCompanyContract: "BE91ZZZ0401934742"
};

export const RECONDUCTION_PRODUCT = "8";
export const GREY_PRODUCT = "11";
export const GREEN_PRODUCT = "12";
export const DYNAMIC_PRODUCT = "13";

export const INTERNET_PRODUCTS = ["", "flex", "activ+", "activ"];

/*
export const PRODUCT_INFO = [
    {
        name: 'flex',
        formattedName: 'Flex',
        greenProductName: 'flex-vert',
        order: 0,
        logo: 'logos.flex',
        codeProduct: "356",
        greencodeProduct: "357",
        advantages: [{
            text: "offers.electricity.flex.first_property",
            tooltip: "infobulles.electricity.flex.first_property"
        },
        {
            text: "offers.electricity.flex.second_property",
            tooltip: "infobulles.electricity.flex.second_property"
        },
        {
            text: "offers.electricity.flex.third_property",
            tooltip: "infobulles.electricity.flex.third_property"
        },
        {
            text: "offers.electricity.flex.fourth_property",
            tooltip: ""
        }]
    }, {
        name: 'activ',
        formattedName: 'Activ',
        greenProductName: 'activ-vert',
        order: 0,
        logo: 'logos.activ',
        codeProduct: "350",
        greencodeProduct: "351",
        advantages: [{
            text: "offers.electricity.activ.first_property",
            tooltip: "infobulles.electricity.activ.first_property"
        },
        {
            text: "offers.electricity.activ.second_property",
            tooltip: "infobulles.electricity.activ.second_property"
        },
        {
            text: "offers.electricity.activ.third_property",
            tooltip: "infobulles.electricity.activ.third_property"
        },
        {
            text: "offers.electricity.activ.fourth_property",
            tooltip: ""
        }]
    }, {
        name: 'activ-plus',
        formattedName: 'Activ+',
        greenProductName: 'activ-plus-vert',
        order: 1,
        logo: 'logos.activPlus',
        codeProduct: "352",
        greencodeProduct: "353",

        advantages: [{
            text: "offers.electricity.activ-plus.first_property",
            tooltip: "infobulles.electricity.activ-plus.first_property"
        },
        {
            text: "offers.electricity.activ-plus.second_property",
            tooltip: "infobulles.electricity.activ-plus.second_property"
        },
        {
            text: "offers.electricity.activ-plus.third_property",
            tooltip: "infobulles.electricity.activ-plus.third_property"
        },
        {
            text: "offers.electricity.activ-plus.fourth_property",
            tooltip: ""
        }]
    }, {
        name: 'smart-fix',
        formattedName: 'Smart Fixe',
        greenProductName: 'smart-fix-vert',
        order: 2,
        logo: 'logos.smartFixe',
        codeProduct: "450",
        greencodeProduct: "451",
        advantages: [{
            text: "offers.electricity.smart-fix.first_property",
            tooltip: "infobulles.electricity.smart-fix.first_property"
        },
        {
            text: "offers.electricity.smart-fix.second_property",
            tooltip: "infobulles.electricity.smart-fix.second_property"
        },
        {
            text: "offers.electricity.smart-fix.third_property",
            tooltip: "infobulles.electricity.smart-fix.third_property"
        },
        {
            text: "offers.electricity.smart-fix.fourth_property",
            tooltip: ""
        }]
    }, {
        name: 'smart-var',
        formattedName: 'Smart Variable',
        greenProductName: 'smart-var-vert',
        order: 3,
        logo: 'logos.smartVariable',
        codeProduct: "458",
        greencodeProduct: "459",
        advantages: [{
            text: "offers.electricity.smart-var.first_property",
            tooltip: "infobulles.electricity.smart-var.first_property"
        },
        {
            text: "offers.electricity.smart-var.third_property",
            tooltip: "infobulles.electricity.smart-var.third_property"
        },
        {
            text: "offers.electricity.smart-var.fourth_property",
            tooltip: ""
        }]
    },
    {
        name: 'smart-var',
        formattedName: 'Smart Variable',
        greenProductName: 'smart-var-vert',
        order: 3,
        logo: 'logos.smartVariable',
        codeProduct: "500",
        greencodeProduct: "501",
        advantages: [{
            text: "offers.electricity.smart-var.first_property",
            tooltip: "infobulles.electricity.smart-var.first_property"
        },
        {
            text: "offers.electricity.smart-var.third_property",
            tooltip: "infobulles.electricity.smart-var.third_property"
        },
        {
            text: "offers.electricity.smart-var.fourth_property",
            tooltip: ""
        }]
    }, {
        name: 'safe',
        formattedName: 'Safe Fixe',
        greenProductName: 'safe-vert',
        order: 4,
        logo: 'logos.safe',
        codeProduct: "452",
        greencodeProduct: "453",
        advantages: [{
            text: "offers.electricity.safe.first_property",
            tooltip: "infobulles.electricity.safe.first_property"
        },
        {
            text: "offers.electricity.safe.second_property",
            tooltip: ""
        },
        {
            text: "offers.electricity.safe.third_property",
            tooltip: "infobulles.electricity.safe.third_property"
        },
        {
            text: "offers.electricity.safe.fourth_property",
            tooltip: ""
        }]
    },
    {
        name: 'activ',
        formattedName: 'Activ',
        greenProductName: 'activ-vert',
        order: 0,
        logo: 'logos.activ',
        codeProduct: "354",
        advantages: [{
            text: "offers.gas.activ.first_property",
            tooltip: "infobulles.gas.activ.first_property"
        },
        {
            text: "offers.gas.activ.second_property",
            tooltip: "infobulles.gas.activ.second_property"
        },
        {
            text: "offers.gas.activ.third_property",
            tooltip: ""
        },
        {
            text: "offers.gas.activ.fourth_property",
            tooltip: "infobulles.gas.activ.fourth_property"
        }]
    }, {
        name: 'activ-plus',
        formattedName: 'Activ+',
        greenProductName: 'activ-plus-vert',
        order: 1,
        logo: 'logos.activPlus',
        codeProduct: "355",
        advantages: [{
            text: "offers.gas.activ-plus.first_property",
            tooltip: "infobulles.gas.activ-plus.first_property"
        },
        {
            text: "offers.gas.activ-plus.second_property",
            tooltip: "infobulles.gas.activ-plus.second_property"
        },
        {
            text: "offers.gas.activ-plus.third_property",
            tooltip: ""
        },
        {
            text: "offers.gas.activ-plus.fourth_property",
            tooltip: "infobulles.gas.activ-plus.fourth_property"
        }]
    }, {
        name: 'smart-fix',
        formattedName: 'Smart Fixe',
        greenProductName: 'smart-fix-vert',
        order: 2,
        logo: 'logos.smartFixe',
        codeProduct: "456",
        advantages: [{
            text: "offers.gas.smart-fix.first_property",
            tooltip: "infobulles.gas.smart-fix.first_property"
        },
        {
            text: "offers.gas.smart-fix.second_property",
            tooltip: "infobulles.gas.smart-fix.second_property"
        },
        {
            text: "offers.gas.smart-fix.third_property",
            tooltip: ""
        },
        {
            text: "offers.gas.smart-fix.fourth_property",
            tooltip: "infobulles.gas.smart-fix.fourth_property"
        }]
    }, {
        name: 'smart-var',
        formattedName: 'Smart Variable',
        greenProductName: 'smart-var-vert',
        order: 3,
        logo: 'logos.smartVariable',
        codeProduct: "460",
        advantages: [{
            text: "offers.gas.smart-var.first_property",
            tooltip: "infobulles.gas.smart-var.first_property"
        },
        {
            text: "offers.gas.smart-var.second_property",
            tooltip: "infobulles.gas.smart-var.second_property"
        },
        {
            text: "offers.gas.smart-var.third_property",
            tooltip: ""
        },
        {
            text: "offers.gas.smart-var.fourth_property",
            tooltip: "infobulles.gas.smart-var.fourth_property"
        }]
    }
];
*/

// TODO: Update list with new products info.
export const PRODUCT_INFO = [
    {
        name: 'smart-var',
        formattedName: 'products.smart-var',
        energy: IS_ELEC,
        category: RECONDUCTION_PRODUCT,
        order: 99,
        logo: "logos.smartVariable",
        logoInverted: "logos.smartVariable",
        codeProduct: "454",
        duration: 1,
        advantages: "offers.electricity.smart-var.advantages",
        summary: "offers.electricity.smart-var.summary"
    },
    {
        name: 'smart-var-vert',
        formattedName: 'products.smart-var-vert',
        energy: IS_ELEC,
        category: RECONDUCTION_PRODUCT,
        order: 99,
        logo: "logos.greenSmartVariable",
        logoInverted: "logos.greenSmartVariable",
        codeProduct: "455",
        duration: 1,
        advantages: "offers.electricity.smart-var-vert.advantages",
        summary: "offers.electricity.smart-var-vert.summary"
    },
    {
        name: 'smart-var',
        formattedName: 'products.smart-var',
        energy: IS_ELEC,
        category: RECONDUCTION_PRODUCT,
        order: 99,
        logo: "logos.smartVariable",
        logoInverted: "logos.smartVariable",
        codeProduct: "458",
        duration: 1,
        advantages: "offers.electricity.smart-var.advantages",
        summary: "offers.electricity.smart-var.summary"
    },
    {
        name: 'smart-var-vert',
        formattedName: 'products.smart-var-vert',
        energy: IS_ELEC,
        category: RECONDUCTION_PRODUCT,
        order: 99,
        logo: "logos.greenSmartVariable",
        logoInverted: "logos.greenSmartVariable",
        codeProduct: "459",
        duration: 1,
        advantages: "offers.electricity.smart-var-vert.advantages",
        summary: "offers.electricity.smart-var-vert.summary"
    },
    {
        name: 'smart-var',
        formattedName: 'products.smart-var',
        energy: IS_GAS,
        category: RECONDUCTION_PRODUCT,
        order: 99,
        logo: "logos.smartVariable",
        logoInverted: "logos.smartVariable",
        codeProduct: "457",
        duration: 1,
        advantages: "offers.gas.smart-var.advantages",
        summary: "offers.gas.smart-var.summary"
    },
    {
        name: 'clear',
        formattedName: "products.clear",
        energy: IS_ELEC,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.elecGrey',
        logoInverted: 'logos.elecGreyInverted',
        codeProduct: "644",
        duration: 99,
        advantages: "offers.electricity.grey.chill.advantages",
        summary: "offers.electricity.grey.chill.summary"
    },
    {
        name: 'clear',
        formattedName: "products.clear",
        energy: IS_ELEC,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.elecGrey',
        logoInverted: 'logos.elecGreyInverted',
        codeProduct: "650",
        duration: 99,
        advantages: "offers.electricity.grey.chill.advantages",
        summary: "offers.electricity.grey.chill.summary"
    },
    {
        name: 'clear',
        formattedName: "products.clear",
        energy: IS_ELEC,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.elecGrey',
        logoInverted: 'logos.elecGreyInverted',
        codeProduct: "664",
        duration: 99,
        advantages: "offers.electricity.grey.chill.advantages",
        summary: "offers.electricity.grey.chill.summary"
    },
    {
        name: 'chill',
        formattedName: "products.chill",
        energy: IS_ELEC,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.elecGrey',
        logoInverted: 'logos.elecGreyInverted',
        codeProduct: "629",
        duration: 99,
        advantages: "offers.electricity.grey.chill.advantages",
        summary: "offers.electricity.grey.chill.summary"
    },
    {
        name: 'care',
        formattedName: "products.care",
        energy: IS_ELEC,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.elecGrey',
        logoInverted: 'logos.elecGreyInverted',
        codeProduct: "601",
        duration: 99,
        advantages: "offers.electricity.grey.care.advantages",
        summary: "offers.electricity.grey.care.summary"
    },
    {
        name: 'calm',
        formattedName: "products.calm",
        energy: IS_ELEC,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.elecGrey',
        logoInverted: 'logos.elecGreyInverted',
        codeProduct: "602",
        duration: 99,
        advantages: "offers.electricity.grey.calm.advantages",
        summary: "offers.electricity.grey.calm.summary"
    },
    {
        name: 'cool',
        formattedName: "products.cool",
        energy: IS_ELEC,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.elecGrey',
        logoInverted: 'logos.elecGreyInverted',
        codeProduct: "603",
        duration: 99,
        advantages: "offers.electricity.grey.cool.advantages",
        summary: "offers.electricity.grey.cool.summary"
    },
    {
        name: 'ecoclear',
        formattedName: "products.ecoClear",
        energy: IS_ELEC,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.elecGreen',
        logoInverted: 'logos.elecGreenInverted',
        codeProduct: "646",
        duration: 99,
        advantages: "offers.electricity.green.ecochill.advantages",
        summary: "offers.electricity.green.ecochill.summary"
    },
    {
        name: 'ecoclear',
        formattedName: "products.ecoClear",
        energy: IS_ELEC,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.elecGreen',
        logoInverted: 'logos.elecGreenInverted',
        codeProduct: "651",
        duration: 99,
        advantages: "offers.electricity.green.ecochill.advantages",
        summary: "offers.electricity.green.ecochill.summary"
    },
    {
        name: 'ecoclear',
        formattedName: "products.ecoClear",
        energy: IS_ELEC,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.elecGreen',
        logoInverted: 'logos.elecGreenInverted',
        codeProduct: "658",
        duration: 99,
        advantages: "offers.electricity.green.ecochill.advantages",
        summary: "offers.electricity.green.ecochill.summary"
    },
    {
        name: 'ecochill',
        formattedName: "products.ecochill",
        energy: IS_ELEC,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.elecGreen',
        logoInverted: 'logos.elecGreenInverted',
        codeProduct: "640",
        duration: 99,
        advantages: "offers.electricity.green.ecochill.advantages",
        summary: "offers.electricity.green.ecochill.summary"
    },
    {
        name: 'ecocare',
        formattedName: "products.ecocare",
        energy: IS_ELEC,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.elecGreen',
        logoInverted: 'logos.elecGreenInverted',
        codeProduct: "605",
        duration: 99,
        advantages: "offers.electricity.green.ecocare.advantages",
        summary: "offers.electricity.green.ecocare.summary"
    },
    {
        name: 'ecocalm',
        formattedName: "products.ecocalm",
        energy: IS_ELEC,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.elecGreen',
        logoInverted: 'logos.elecGreenInverted',
        codeProduct: "606",
        duration: 99,
        advantages: "offers.electricity.green.ecocalm.advantages",
        summary: "offers.electricity.green.ecocalm.summary"
    },
    {
        name: 'ecocool',
        formattedName: "products.ecocool",
        energy: IS_ELEC,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.elecGreen',
        logoInverted: 'logos.elecGreenInverted',
        codeProduct: "607",
        duration: 99,
        advantages: "offers.electricity.green.ecocool.advantages",
        summary: "offers.electricity.green.ecocool.summary"
    },
    {
        name: "smart-var",
        formattedName: "products.smart-var",
        energy: IS_ELEC,
        category: RECONDUCTION_PRODUCT,
        order: 99,
        logo: "logos.smartVariable",
        logoInverted: "logos.smartVariable",
        codeProduct: "633",
        duration: 1,
        advantages: "offers.electricity.smart-var.advantages",
        summary: "offers.electricity.smart-var.summary"
    },
    {
        name: 'smart-var-vert',
        formattedName: "products.smart-var-vert",
        energy: IS_ELEC,
        category: RECONDUCTION_PRODUCT,
        order: 99,
        logo: 'logos.greenSmartVariable',
        logoInverted: 'logos.greenSmartVariable',
        codeProduct: "642",
        duration: 1,
        advantages: "offers.electricity.smart-var-vert.advantages",
        summary: "offers.electricity.smart-var-vert.summary"
    },
    {
        name: 'smart-var-vert',
        formattedName: "products.smart-var-vert",
        energy: IS_ELEC,
        category: RECONDUCTION_PRODUCT,
        order: 99,
        logo: 'logos.greenSmartVariable',
        logoInverted: 'logos.greenSmartVariable',
        codeProduct: "653",
        duration: 1,
        advantages: "offers.electricity.smart-var-vert.advantages",
        summary: "offers.electricity.smart-var-vert.summary"
    },
    {
        name: 'chill',
        formattedName: "products.chill",
        energy: IS_ELEC,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.elecGrey',
        logoInverted: 'logos.elecGreyInverted',
        codeProduct: "630",
        duration: 99,
        advantages: "offers.electricity.grey.chill.advantages",
        summary: "offers.electricity.grey.chill.summary"
    },
    {
        name: 'clear',
        formattedName: "products.clear",
        energy: IS_ELEC,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.elecGrey',
        logoInverted: 'logos.elecGreyInverted',
        codeProduct: "645",
        duration: 99,
        advantages: "offers.electricity.grey.chill.advantages",
        summary: "offers.electricity.grey.chill.summary"
    },
    {
        name: 'chill',
        formattedName: "products.chill",
        energy: IS_ELEC,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.elecGrey',
        logoInverted: 'logos.elecGreyInverted',
        codeProduct: "600",
        duration: 99,
        advantages: "offers.electricity.grey.chill.advantages",
        summary: "offers.electricity.grey.chill.summary"
    },
    {
        name: 'care',
        formattedName: "products.care",
        energy: IS_ELEC,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.elecGrey',
        logoInverted: 'logos.elecGreyInverted',
        codeProduct: "611",
        duration: 99,
        advantages: "offers.electricity.grey.care.advantages",
        summary: "offers.electricity.grey.care.summary"
    },
    {
        name: 'calm',
        formattedName: "products.calm",
        energy: IS_ELEC,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.elecGrey',
        logoInverted: 'logos.elecGreyInverted',
        codeProduct: "612",
        duration: 99,
        advantages: "offers.electricity.grey.calm.advantages",
        summary: "offers.electricity.grey.calm.summary"
    },
    {
        name: 'cool',
        formattedName: "products.cool",
        energy: IS_ELEC,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.elecGrey',
        logoInverted: 'logos.elecGreyInverted',
        codeProduct: "613",
        duration: 99,
        advantages: "offers.electricity.grey.cool.advantages",
        summary: "offers.electricity.grey.cool.summary"
    },
    {
        name: 'ecoclear',
        formattedName: "products.ecoClear",
        energy: IS_ELEC,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.elecGreen',
        logoInverted: 'logos.elecGreenInverted',
        codeProduct: "647",
        duration: 99,
        advantages: "offers.electricity.green.ecochill.advantages",
        summary: "offers.electricity.green.ecochill.summary"
    },
    {
        name: 'ecochill',
        formattedName: "products.ecochill",
        energy: IS_ELEC,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.elecGreen',
        logoInverted: 'logos.elecGreenInverted',
        codeProduct: "641",
        duration: 99,
        advantages: "offers.electricity.green.ecochill.advantages",
        summary: "offers.electricity.green.ecochill.summary"
    },
    {
        name: 'ecocare',
        formattedName: "products.ecocare",
        energy: IS_ELEC,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.elecGreen',
        logoInverted: 'logos.elecGreenInverted',
        codeProduct: "615",
        duration: 99,
        advantages: "offers.electricity.green.ecocare.advantages",
        summary: "offers.electricity.green.ecocare.summary"
    },
    {
        name: 'ecocalm',
        formattedName: "products.ecocalm",
        energy: IS_ELEC,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.elecGreen',
        logoInverted: 'logos.elecGreenInverted',
        codeProduct: "616",
        duration: 99,
        advantages: "offers.electricity.green.ecocalm.advantages",
        summary: "offers.electricity.green.ecocalm.summary"
    },
    {
        name: 'ecocool',
        formattedName: "products.ecocool",
        energy: IS_ELEC,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.elecGreen',
        logoInverted: 'logos.elecGreenInverted',
        codeProduct: "617",
        duration: 99,
        advantages: "offers.electricity.green.ecocool.advantages",
        summary: "offers.electricity.green.ecocool.summary"
    },
    {
        name: "smart-var",
        formattedName: "products.smart-var",
        energy: IS_ELEC,
        category: RECONDUCTION_PRODUCT,
        order: 99,
        logo: "logos.smartVariable",
        logoInverted: "logos.smartVariable",
        codeProduct: "634",
        duration: 1,
        advantages: "offers.electricity.smart-var.advantages",
        summary: "offers.electricity.smart-var.summary"
    },
    {
        name: "smart-var",
        formattedName: "products.smart-var",
        energy: IS_ELEC,
        category: RECONDUCTION_PRODUCT,
        order: 99,
        logo: "logos.smartVariable",
        logoInverted: "logos.smartVariable",
        codeProduct: "652",
        duration: 1,
        advantages: "offers.electricity.smart-var.advantages",
        summary: "offers.electricity.smart-var.summary"
    },
    {
        name: 'smart-var-vert',
        formattedName: "products.smart-var-vert",
        energy: IS_ELEC,
        category: RECONDUCTION_PRODUCT,
        order: 99,
        logo: 'logos.greenSmartVariable',
        logoInverted: 'logos.greenSmartVariable',
        codeProduct: "643",
        duration: 1,
        advantages: "offers.electricity.smart-var-vert.advantages",
        summary: "offers.electricity.smart-var-vert.summary"
    },
    {
        name: 'dynamic',
        formattedName: "products.dynamic",
        energy: IS_ELEC,
        category: DYNAMIC_PRODUCT,
        order: 0,
        logo: 'logos.elecDynamic',
        logoInverted: 'logos.elecDynamicInverted',
        codeProduct: "700",
        duration: 99,
        advantages: "offers.electricity.grey.dynamic.advantages",
        summary: "offers.electricity.grey.dynamic.summary"
    },
    {
        name: 'ecodynamic',
        formattedName: "products.ecoDynamic",
        energy: IS_ELEC,
        category: DYNAMIC_PRODUCT,
        order: 0,
        logo: 'logos.elecEcoDynamic',
        logoInverted: 'logos.elecEcoDynamicInverted',
        codeProduct: "702",
        duration: 99,
        advantages: "offers.electricity.green.ecoDynamic.advantages",
        summary: "offers.electricity.green.ecoDynamic.summary"
    },
    {
        name: 'clear',
        formattedName: "products.clear",
        energy: IS_GAS,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.gasGrey',
        logoInverted: 'logos.gasGreyInverted',
        codeProduct: "648",
        duration: 99,
        advantages: "offers.gas.grey.chill.advantages",
        summary: "offers.gas.grey.chill.summary"
    },
    {
        name: 'clear',
        formattedName: "products.clear",
        energy: IS_GAS,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.gasGrey',
        logoInverted: 'logos.gasGreyInverted',
        codeProduct: "660",
        duration: 99,
        advantages: "offers.gas.grey.chill.advantages",
        summary: "offers.gas.grey.chill.summary"
    },
    {
        name: 'chill',
        formattedName: "products.chill",
        energy: IS_GAS,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.gasGrey',
        logoInverted: 'logos.gasGreyInverted',
        codeProduct: "637",
        duration: 99,
        advantages: "offers.gas.grey.chill.advantages",
        summary: "offers.gas.grey.chill.summary"
    },
    {
        name: 'care',
        formattedName: "products.care",
        energy: IS_GAS,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.gasGrey',
        logoInverted: 'logos.gasGreyInverted',
        codeProduct: "621",
        duration: 99,
        advantages: "offers.gas.grey.care.advantages",
        summary: "offers.gas.grey.care.summary"
    },
    {
        name: 'calm',
        formattedName: "products.calm",
        energy: IS_GAS,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.gasGrey',
        logoInverted: 'logos.gasGreyInverted',
        codeProduct: "622",
        duration: 99,
        advantages: "offers.gas.grey.calm.advantages",
        summary: "offers.gas.grey.calm.summary"
    },
    {
        name: 'cool',
        formattedName: "products.cool",
        energy: IS_GAS,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.gasGrey',
        logoInverted: 'logos.gasGreyInverted',
        codeProduct: "623",
        duration: 99,
        advantages: "offers.gas.grey.cool.advantages",
        summary: "offers.gas.grey.cool.summary"
    },
    {
        name: 'ecoclear',
        formattedName: "products.ecoClear",
        energy: IS_GAS,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.gasGreen',
        logoInverted: 'logos.gasGreenInverted',
        codeProduct: "649",
        duration: 99,
        advantages: "offers.gas.green.ecochill.advantages",
        summary: "offers.gas.green.ecochill.summary"
    },
    {
        name: 'ecoclear',
        formattedName: "products.ecoClear",
        energy: IS_GAS,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.gasGreen',
        logoInverted: 'logos.gasGreenInverted',
        codeProduct: "661",
        duration: 99,
        advantages: "offers.gas.green.ecochill.advantages",
        summary: "offers.gas.green.ecochill.summary"
    },
    {
        name: 'ecochill',
        formattedName: "products.ecochill",
        energy: IS_GAS,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.gasGreen',
        logoInverted: 'logos.gasGreenInverted',
        codeProduct: "638",
        duration: 99,
        advantages: "offers.gas.green.ecochill.advantages",
        summary: "offers.gas.green.ecochill.summary"
    },
    {
        name: 'ecocare',
        formattedName: "products.ecocare",
        energy: IS_GAS,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.gasGreen',
        logoInverted: 'logos.gasGreenInverted',
        codeProduct: "625",
        duration: 99,
        advantages: "offers.gas.green.ecocare.advantages",
        summary: "offers.gas.green.ecocare.summary"
    },
    {
        name: 'ecocalm',
        formattedName: "products.ecocalm",
        energy: IS_GAS,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.gasGreen',
        logoInverted: 'logos.gasGreenInverted',
        codeProduct: "626",
        duration: 99,
        advantages: "offers.gas.green.ecocalm.advantages",
        summary: "offers.gas.green.ecocalm.summary"
    },
    {
        name: 'ecocool',
        formattedName: "products.ecocool",
        energy: IS_GAS,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.gasGreen',
        logoInverted: 'logos.gasGreenInverted',
        codeProduct: "627",
        duration: 99,
        advantages: "offers.gas.green.ecocool.advantages",
        summary: "offers.gas.green.ecocool.summary"
    },
    {
        name: "smart-var",
        formattedName: "products.smart-var",
        energy: IS_GAS,
        category: RECONDUCTION_PRODUCT,
        order: 99,
        logo: "logos.smartVariable",
        logoInverted: "logos.smartVariable",
        codeProduct: "639",
        duration: 1,
        advantages: "offers.gas.smart-var.advantages",
        summary: "offers.gas.smart-var.summary"
    },
    {
        name: 'fixed',
        formattedName: "products.fixed",
        energy: IS_ELEC,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.elecFixedGrey',
        logoInverted: 'logos.elecFixedGrey',
        codeProduct: "654",
        duration: 99,
        advantages: "offers.electricity.grey.fixed.advantages",
        summary: "offers.electricity.grey.fixed.summary"
    },
    {
        name: 'ecofixed',
        formattedName: "products.ecoFixed",
        energy: IS_ELEC,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.elecFixedGreen',
        logoInverted: 'logos.elecFixedGreen',
        codeProduct: "655",
        duration: 99,
        advantages: "offers.electricity.green.ecofixed.advantages",
        summary: "offers.electricity.green.ecofixed.summary"
    },
    {
        name: 'ecofixed',
        formattedName: "products.ecoFixed",
        energy: IS_ELEC,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.elecFixedGreen',
        logoInverted: 'logos.elecFixedGreen',
        codeProduct: "659",
        duration: 99,
        advantages: "offers.electricity.green.ecofixed.advantages",
        summary: "offers.electricity.green.ecofixed.summary"
    },
    {
        name: 'fixed',
        formattedName: "products.fixed",
        energy: IS_GAS,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.gasFixedGrey',
        logoInverted: 'logos.gasFixedGrey',
        codeProduct: "656",
        duration: 99,
        advantages: "offers.gas.grey.fixed.advantages",
        summary: "offers.gas.grey.fixed.summary"
    },
    {
        name: 'fixed',
        formattedName: "products.fixed",
        energy: IS_GAS,
        category: GREY_PRODUCT,
        order: 0,
        logo: 'logos.gasFixedGrey',
        logoInverted: 'logos.gasFixedGrey',
        codeProduct: "662",
        duration: 99,
        advantages: "offers.gas.grey.fixed.advantages",
        summary: "offers.gas.grey.fixed.summary"
    },
    {
        name: 'ecofixed',
        formattedName: "products.ecoFixed",
        energy: IS_GAS,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.gasFixedGreen',
        logoInverted: 'logos.gasFixedGreen',
        codeProduct: "657",
        duration: 99,
        advantages: "offers.gas.green.ecofixed.advantages",
        summary: "offers.gas.green.ecofixed.summary"
    },
    {
        name: 'ecofixed',
        formattedName: "products.ecoFixed",
        energy: IS_GAS,
        category: GREEN_PRODUCT,
        order: 0,
        logo: 'logos.gasFixedGreen',
        logoInverted: 'logos.gasFixedGreen',
        codeProduct: "663",
        duration: 99,
        advantages: "offers.gas.green.ecofixed.advantages",
        summary: "offers.gas.green.ecofixed.summary"
    }
];

// Not used anymore. 22/06/2022
export const PRODUCTS_NAMES_MAPPING = [
    { key: "Activ", energy: "E", i18nKey: "details.electricity.activ.description" },
    { key: "Activ+", energy: "E", i18nKey: "details.electricity.activ-plus.description" },
    { key: "Flex", energy: "E", i18nKey: "details.electricity.flex.description" },
    { key: "Smart Vast", energy: "E", i18nKey: "details.electricity.smart-fix.description" },
    { key: "Smart Fixe", energy: "E", i18nKey: "details.electricity.smart-fix.description" },
    { key: "Smart Variabel", energy: "E", i18nKey: "details.electricity.smart-var.description" },
    { key: "Smart Variable", energy: "E", i18nKey: "details.electricity.smart-var.description" },
    { key: "Safe Fixe", energy: "E", i18nKey: "details.electricity.safe.description" },
    { key: "Safe Vast", energy: "E", i18nKey: "details.electricity.safe.description" },
    { key: "Smart Vast (100% groen)", energy: "E", i18nKey: "details.electricity.smart-fix.description" },
    { key: "Smart Fixe (100% vert)", energy: "E", i18nKey: "details.electricity.smart-fix.description" },
    { key: "Smart Variabel (100% groen)", energy: "E", i18nKey: "details.electricity.smart-var.description" },
    { key: "Smart Variable (100% vert)", energy: "E", i18nKey: "details.electricity.smart-var.description" },
    { key: "Safe Fixe (100% vert)", energy: "E", i18nKey: "details.electricity.safe.description" },
    { key: "Safe Vast (100% groen)", energy: "E", i18nKey: "details.electricity.safe.description" },
    { key: "Activ", energy: "G", i18nKey: "details.gas.activ.description" },
    { key: "Activ+", energy: "G", i18nKey: "details.gas.activ-plus.description" },
    { key: "Smart Vast", energy: "G", i18nKey: "details.gas.smart-fix.description" },
    { key: "Smart Fixe", energy: "G", i18nKey: "details.gas.smart-fix.description" },
    { key: "Smart Variabel", energy: "G", i18nKey: "details.gas.smart-var.description" },
    { key: "Smart Variable", energy: "G", i18nKey: "details.gas.smart-var.description" },
    { key: "Safe Fixe", energy: "G", i18nKey: "details.gas.safe.description" },
    { key: "Safe Vast", energy: "G", i18nKey: "details.gas.safe.description" },
    { key: "Chill", energy: "E", i18nKey: "details.electricity.flex.description" },
    { key: "Eco Chill", energy: "E", i18nKey: "" },
    { key: "Care", energy: "E", i18nKey: "" },
    { key: "Eco Care", energy: "E", i18nKey: "" },
    { key: "Calm", energy: "E", i18nKey: "" },
    { key: "Eco Calm", energy: "E", i18nKey: "" },
    { key: "Cool", energy: "E", i18nKey: "" },
    { key: "Eco Cool", energy: "E", i18nKey: "" },
    { key: "Fixed", energy: "E", i18nKey: "" },
    { key: "Eco Fixed", energy: "E", i18nKey: "" },
    { key: "Chill", energy: "G", i18nKey: "" },
    { key: "Eco Chill", energy: "G", i18nKey: "" },
    { key: "Care", energy: "G", i18nKey: "" },
    { key: "Eco Care", energy: "G", i18nKey: "" },
    { key: "Calm", energy: "G", i18nKey: "" },
    { key: "Eco Calm", energy: "G", i18nKey: "" },
    { key: "Cool", energy: "G", i18nKey: "" },
    { key: "Eco Cool", energy: "G", i18nKey: "" },
    { key: "Fixed", energy: "G", i18nKey: "" },
    { key: "Eco Fixed", energy: "G", i18nKey: "" },
];

// Not used. Checked 16/06/22.
export const PRODUCT_NAME_MAPPING = [
    { key: "Flex", mapping: "flex", translateFr: 'Flex', translateNl: 'Flex' },
    { key: "Flex Vert", mapping: "flex-vert", translateFr: 'Flex (100% vert)', translateNl: 'Flex (100% groen)' },
    { key: "Activ", mapping: "activ", translateFr: 'Activ', translateNl: 'Activ' },
    { key: "Activ+", mapping: "activ-plus", translateFr: 'Activ+', translateNl: 'Activ+' },
    { key: "Smart Variable", mapping: "smart-var", translateFr: 'Smart Variable', translateNl: 'Smart Variabel' },
    { key: "Smart Fixe", mapping: "smart-fix", translateFr: 'Smart Fixe', translateNl: 'Smart Vast' },
    { key: "Safe", mapping: "safe", translateFr: 'Safe', translateNl: 'Safe' },
    { key: "Safe Vert", mapping: "safe-vert", translateFr: 'Safe (100% vert)', translateNl: 'Safe (100% groen)' },
    { key: "Activ Vert", mapping: "activ-vert", translateFr: 'Activ (100% vert)', translateNl: 'Activ (100% groen)' },
    { key: "Activ+ Vert", mapping: "activ-plus-vert", translateFr: 'Activ+ (100% vert)', translateNl: 'Activ+ (100% groen)' },
    { key: "Smart Variable Vert", mapping: "smart-var-vert", translateFr: 'Smart Variable (100% vert)', translateNl: 'Smart Variabel (100% groen)' },
    { key: "Smart Fixe Vert", mapping: "smart-fix-vert", translateFr: 'Smart Fixe (100% vert)', translateNl: 'Smart Vast (100% groen)' }
];

export const PARTNERS = [{
    partnerId: 0,
    partnerName: "website",
    salesGenerator: "",// AppAffaireRef WEG
    shouldBypassLead: false,
    canalId: "WEG",
    agent: "WEBSITE20",
    salesGeneratorForSimulation: "WEBSITE20",
    agentMember: "",
    redirectUrl: ""
}, {
    partnerId: 1,
    partnerName: "bsp",
    salesGenerator: "",// AppAffaireRef
    shouldBypassLead: true,
    canalId: "BP",
    agent: "YOUROCALL",
    salesGeneratorForSimulation: "YC2022PROMO",
    agentMember: "",
    redirectUrl: "https://portal.octaplus.be/leads"
}, {
    partnerId: 2,
    partnerName: "monEnergie",
    salesGenerator: "",// AppAffaireRef MEC
    shouldBypassLead: false,
    canalId: "LP",
    agent: "mec2",
    salesGeneratorForSimulation: "",
    agentMember: "",
    redirectUrl: ""
}];

import { emptyProduct, IProduct } from "./Product";

export interface ISelectedProduct {
    elec: IProduct;
    gas: IProduct;
}

export interface ISimulation {
    selectedProduct: ISelectedProduct;
    productsList: any;
    params: any;
    hasError: boolean
}

export const emptySimulation: ISimulation = {
    selectedProduct: {
        elec: emptyProduct,
        gas: emptyProduct
    },
    productsList: [],
    params: "",
    hasError: false
};

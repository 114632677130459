import { emptyElec, IElec } from "./Elec";
import { emptyGas, IGas } from "./Gas";
import { emptyAddress, IAddress } from "./Address";

export interface IDelivery {
	address: IAddress;
	elec: IElec;
	gas: IGas;
	startDate: string;
	sameAsInvoicing: boolean;
	hasInternetProduct: boolean;
	autoDeterminedInj: boolean;
	currentSituation: number;
	lastSupplier?: string;
}

export const emptyDelivery: IDelivery = {
	address: emptyAddress,
	elec: emptyElec,
	gas: emptyGas,
	startDate: "",
	sameAsInvoicing: true,
	hasInternetProduct: false,
	autoDeterminedInj: false,
	currentSituation: 1,
	lastSupplier: ""
};

import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import colors from "../utils/colors";

const theme = responsiveFontSizes(
	createTheme({
		palette: {
			primary: {
				main: colors.octaBlue
			},
			secondary: {
				main: colors.octaRed
			},
			error: {
				main: red.A400
			},
			background: {
				default: colors.white,
				paper: colors.white
			}
		},
		typography: {
			fontFamily: "din-2014, sans-serif"
		}
	})
);

export default theme;

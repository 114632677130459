import { StateAction } from "../stateAction";
import { profilActionTypes } from "./actionTypes";
import { IProfil } from "../../Models/Profil";

export const profilReducer = (state: IProfil, action: StateAction): IProfil => {
    switch (action.type) {
        case profilActionTypes.UPDATE_PROFIL:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };
        default:
            return state;
    }
};

export interface IConfirmation {
	withNewsletter: boolean;
	withMarketing: boolean;
	telSupport: boolean;
	generalConditions: boolean;
	privacyDate: string;
	privacyHour: string;
	generalCDate: string;
	generalCHour: string;
	remark?: string;
}

export const emptyConfirmation: IConfirmation = {
	withNewsletter: false,
	withMarketing: false,
	telSupport: false,
	generalConditions: false,
	privacyDate: "",
	privacyHour: "",
	generalCDate: "",
	generalCHour: "",
	remark: ""
};

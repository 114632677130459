import React, { useEffect } from "react";
import Head from "next/head";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { I18nextProvider } from "react-i18next";
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';

import { DataProvider } from "../src/Contexts/MainContext";
import Wrapper from "../src/Components/Commons/Wrapper";
import GlobalLoader from "../src/Components/Commons/GlobalLoader";
import GlobalStyle from "../utils/global-styles";
import i18n from "../i18n";
import theme from "../src/theme";

const MyApp = ({
	Component,
	pageProps
}: {
	Component: any;
	pageProps: any;
}) => {
	useEffect(() => {
		const jssStyles = document.querySelector("#jss-server-side");
		if (jssStyles && jssStyles.parentNode) {
			jssStyles.parentNode.removeChild(jssStyles);
		}
	}, []);
	const GlobalStyleProxy: any = GlobalStyle;

	return (
		<div style={{ height: "100%" }}>
			<I18nextProvider i18n={i18n} />
			<Head>
				<meta
					name="viewport"
					content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
				/>
				<title>
					{i18n.language === "fr"
						? "Calculez votre acompte"
						: "Bereken je voorschot"}
				</title>
			</Head>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<ThemeProvider theme={theme}>
					{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
					<CssBaseline />
					<DataProvider>
						<GlobalLoader />
						<Wrapper>
							<Component {...pageProps} />
						</Wrapper>
					</DataProvider>
				</ThemeProvider>
				<GlobalStyleProxy />
			</LocalizationProvider>
		</div>
	);
};

export default MyApp;

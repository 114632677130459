import get from "lodash/get";
import map from "lodash/map";
import nth from "lodash/nth";
import findIndex from "lodash/findIndex";
import size from "lodash/size";

import { StateAction } from "../stateAction";
import { surveyActionTypes } from "./actionTypes";
import { ISurvey } from "../../Models/Survey";
import { BUTTON, CHECKBOX, INPUT, LINK } from "../../../utils/constants";
import { applyQ9RG, getNextQuestionIdWithProfile } from "../../Components/Survey/flowGenerator";
import { dropRight } from "lodash";
import { formatFlags } from "../../../utils/mappers";

export const surveyReducer = (state: ISurvey, action: StateAction): ISurvey => {
    switch (action.type) {
        case surveyActionTypes.UPDATE_SURVEY:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };
        case surveyActionTypes.NEXT_QUESTION:

            const questionId = get(action, `payload.questionId`, "");
            const buttonAnswer = get(action, `payload.${BUTTON}`, null);
            const linkAnswer = get(action, `payload.${LINK}`, null);
            const inputAnswer = get(action, `payload.${INPUT}`, null);
            const checkboxAnswer = get(action, `payload.${CHECKBOX}`, null);
            const values = get(action, `payload.values`, []);
            const flags = get(action, `payload.flags`, {});

            let currentQuestionAnswer = {
                questionId,
                // [BUTTON]: buttonAnswer,
                // [LINK]: linkAnswer,
                // [INPUT]: inputAnswer,
                // [CHECKBOX]: checkboxAnswer,
                values,
                flags,
            };
            let updatedPile = state.questionsPile;
            let nextCursor = state.cursor + 1;
            let nextQuestion = {};
            let { updatedUserDetails, nextQuestionId } = getNextQuestionIdWithProfile(state, questionId, currentQuestionAnswer);
            const savedQuestionIndex = findIndex(state.questionsPile, (questionItem) => questionItem.questionId == questionId);
            const savedNextQuestionId = get(state.questionsPile, `${(savedQuestionIndex + 1)}.questionId`)
            // findIndex(state.questionsPile, (questionItem, index) => questionItem.questionId == nextQuestionId && index == savedQuestionIndex + 1);

            if (savedQuestionIndex != -1) {
                updatedPile[savedQuestionIndex] = currentQuestionAnswer;
            } else {
                updatedPile.push(currentQuestionAnswer);
            }

            if (!!savedNextQuestionId && savedNextQuestionId == nextQuestionId) {
                nextQuestion = updatedPile[savedQuestionIndex + 1];
            } else {
                const isDifferentFlow = get(updatedPile, `${(savedQuestionIndex + 1)}.questionId`, "") != nextQuestionId;

                if (savedQuestionIndex != -1 && savedQuestionIndex < updatedPile.length - 1) {
                    updatedPile = dropRight(updatedPile, (state.questionsPile.length - 1) - savedQuestionIndex);
                }

                nextQuestion = {
                    questionId: nextQuestionId,
                    // [BUTTON]: null,
                    // [LINK]: null,
                    // [INPUT]: null,
                    // [CHECKBOX]: null,
                    values: [],
                    flags: {}
                };
            }

            return {
                ...state,
                questionsPile: updatedPile,
                currentQuestion: nextQuestion,
                cursor: nextCursor,
                userDetails: {
                    ...state.userDetails,
                    ...(updatedUserDetails) ? updatedUserDetails : {}
                }
            };
        case surveyActionTypes.PREV_QUESTION:

            let prevQuestion = state.currentQuestion;
            let prevCursor = state.cursor;

            if (prevCursor > 0) {
                prevQuestion = nth(state.questionsPile, prevCursor - 2),
                prevCursor = prevCursor - 1;
            }

            return {
                ...state,
                currentQuestion: prevQuestion,
                cursor: prevCursor
            };
        case surveyActionTypes.UPDATE_USER_DETAILS:
            const newUserDetails = get(action, `payload`, {});

            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    ...newUserDetails
                }
            };
        case surveyActionTypes.SWITCH_CONSUMPTION_MODE:
            const newConsumptionMode = formatFlags(get(action, `payload.isConsumptionKnown`, ""));
            const userType = get(state, `userDetails.userType`, "");

            const consumptionQuestionIndex = findIndex(state.questionsPile, (questionItem) => questionItem.questionId == "Q9");
            const newPile = dropRight(state.questionsPile, (state.questionsPile.length - 1) - consumptionQuestionIndex);

            let postConsumptionQuestion = {
                questionId: applyQ9RG(newConsumptionMode, userType == "P"),
                values: [],
                flags: {}
            };

            return {
                ...state,
                questionsPile: newPile,
                currentQuestion: postConsumptionQuestion,
                cursor: newPile.length + 1,
                userDetails: {
                    ...state.userDetails,
                    isConsumptionKnown: newConsumptionMode
                }
            };
        default:
            return state;
    }
};

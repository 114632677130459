const colors = {
	octaBlue: "rgb(4, 17, 150)",
	octaBlueInk: "rgb(2, 11, 105)",
	octaRed: "rgb(252, 64, 62)",
	octaRedInk: "rgb(180, 46, 45)",
	background: "rgb(234, 235, 240)",
	gray: "rgb(128, 142, 169)",
	opaquedGray: "rgba(189, 189, 189, 0.34901960784313724)",
	white: "rgb(255, 255, 255)",
	transparent: "rgba(255, 255, 255, 0)",
	blackTransparent: "rgba(0, 0, 0, 0.0784313725490196)",
	darkBlue: "rgb(1, 0, 153)",
	lightGray: "rgba(128, 142, 169, 0.6)",
	menuGray: "rgba(219, 221, 229, 0.3803921568627451)",
	hoverGray: "rgb(230, 230, 230)",
	dividerColor: "rgb(225, 225, 225)",
	lightBlue: "rgb(117, 129, 248)",
	descriptionBlue: "rgb(127, 142, 170)",
	lightBlueBackground: "rgb(241, 244, 250)",
	scrollGray: "rgb(208, 210, 219)",
	scrollWhite: "rgb(245, 245, 245)",
	progressBarColor: "rgb(241, 25, 70)",
	emptyStateText: "rgb(208, 207, 207)",
	borderGray: "rgb(189, 189, 189)",
	borderLight: "rgb(227, 233, 244)",
	lightRed: "rgb(242, 95, 92)",
	black: "rgb(38, 38, 61)",
	linkColor: "rgb(51, 122, 183)",
	red: "rgb(255, 0, 0)",
	inputBackground: "#e5e7f4" // rgb(4 17 150 / 10%)
};

export default colors;

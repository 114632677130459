export interface IProfil {
    elecProfil: any;
    gasProfil: any;
    catalogueList: any;
}

export const emptyProfil: IProfil = {
    elecProfil: "",
    gasProfil: "",
    catalogueList: ""
};

import React, { useEffect } from "react";
import { useRouter } from 'next/router'
import { Grid, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";

import FormStepper from "../Lead/FormStepper";
import SelectLanguage from "./SelectLanguage";
import theme from "../../theme";

const useStyle = makeStyles({
	mainContainer: {
		justifyContent: "space-between"
	},
	img: {
		margin: "5px",
		width: "12em",
		cursor: "pointer",
		[theme.breakpoints.only("sm")]: {
			width: "18em"
		}
	},
	languageContainer: {
		display: "flex",
		justifyContent: "flex-end",
		[theme.breakpoints.only("sm")]: {
			flexGrow: 0,
			maxWidth: "12%",
			flexBasis: "12%",
			height: "60px",
			padding: 0
		}
	},
	language: {
		backgroundColor: theme.palette.primary.main,
		padding: "0px",
		width: "100px",
		height: "7em",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textDecoration: "none",
		"&:hover": {
			backgroundColor: "#041196bf",
			cursor: "pointer",
			textDecoration: "none"
		},
		[theme.breakpoints.only("sm")]: {
			padding: 0
		}
	},
	partnerBoxContainer: {
		display: "flex",
		justifyContent: "flex-end"
	},
	logoContainer: {
		height: "7em",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		paddingLeft: "30px",
		[theme.breakpoints.only("sm")]: {
			height: "unset",
			padding: "12px 10px"
		},
		[theme.breakpoints.only("xs")]: {
			height: "80px",
			padding: "12px 10px"
		}
	},
	cartBtnContainer: {
		height: "7em",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "flex-end",
		paddingLeft: "30px",
		[theme.breakpoints.down('md')]: {
			height: "80px",
			padding: "12px 16px"
		}
	}
});

const Header = () => {
	const classes = useStyle();
	const router = useRouter();
	const { t } = useTranslation("common");
	const [isBspView, setIsBspView] = React.useState(false);

	useEffect(() => {
		if (router && !isBspView && _.includes(router.pathname, 'contract')) {
			setIsBspView(true)
		}
	}, [router]);

	const mdDown = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

	return (
		<Grid
			container
			direction="row"
			className={classes.mainContainer}
			justifyContent="space-between"
			alignItems="flex-start"
		>
			{(!mdDown) ? (
				<Grid
					item
					lg={3}
					md={3}
					sm={3}
					xs={9}
					className={classes.logoContainer}
				>
					<img
						src="/img/octa-logo.svg"
						className={classes.img}
						alt="Octa_logo"
						onClick={() =>
							window.location.assign(`${t("thanksPage.websiteLink")}`)
						}
					/>
				</Grid>
			) : null}
			{(!isBspView) &&
				<Grid
					container
					item
					className={classes.partnerBoxContainer}
					sm={12}
					md={6}
				>
					<FormStepper />
				</Grid>
			}
			{(mdDown) ? (
				<>
					<Grid
						item
						lg={3}
						md={3}
						sm={6}
						xs={6}
						className={classes.logoContainer}
					>
						<img
							src="/img/octa-logo.svg"
							className={classes.img}
							alt="Octa_logo"
							onClick={() =>
								window.location.assign(`${t("thanksPage.websiteLink")}`)
							}
						/>
					</Grid>
					<Grid
						item
						lg={3}
						md={3}
						sm={6}
						xs={6}
						className={classes.cartBtnContainer}
					>
						<SelectLanguage />
					</Grid>
				</>
			) : (
				<Grid
					container
					item
					justifyContent="center"
					xs={3}
					md={3}
					lg={3}
					className={classes.languageContainer}
				>
					<Grid
						container
						item
						justifyContent="center"
						xs={12}
						sm={12}
						md={4}
						lg={4}
						className={classes.language}
					>
						<SelectLanguage />
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

export default Header;

import { emptyLead, ILead } from "./Lead";
import { emptyPartner, IPartner } from "./Partner";
import { emptyProfil, IProfil } from "./Profil";
import { emptySimulation, ISimulation } from "./Simulation";
import { emptySurvey, ISurvey } from "./Survey";
import { emptyFlags, IFlags } from "./Flags";
import { emptyGlobalState, IGlobalState } from "./GlobalState";
import { emptyBSP, emptyBspLead, IBSP, IBspLead } from "./BspLead";

export interface IState {
	global: IGlobalState;
	partner: IPartner;
	survey: ISurvey;
	profil: IProfil;
	simulation: ISimulation;
	lead: ILead;
	BSP: IBSP
}

export const emptyState: IState = {
	global: emptyGlobalState,
	partner: emptyPartner,
	survey: emptySurvey,
	profil: emptyProfil,
	simulation: emptySimulation,
	lead: emptyLead,
	BSP: emptyBSP
};

export const postalCodeList = [
  {"code": 1000, "locality": "BRUSSEL"},
  {"code": 1000, "locality": "BRUXELLES"},
  {"code": 1020, "locality": "LAKEN (BRUSSEL)"},
  {"code": 1020, "locality": "LAEKEN (BRUXELLES)"},
  {"code": 1030, "locality": "SCHAARBEEK"},
  {"code": 1030, "locality": "SCHAERBEEK"},
  {"code": 1040, "locality": "ETTERBEEK"},
  {"code": 1040, "locality": "ETTERBEEK"},
  {"code": 1043, "locality": "SCHAARBEEK"},
  {"code": 1043, "locality": "SCHAARBEEK"},
  {"code": 1050, "locality": "ELSENE"},
  {"code": 1050, "locality": "IXELLES"},
  {"code": 1060, "locality": "SINT-GILLIS"},
  {"code": 1060, "locality": "SAINT-GILLES"},
  {"code": 1070, "locality": "ANDERLECHT"},
  {"code": 1070, "locality": "ANDERLECHT"},
  {"code": 1080, "locality": "SINT-JANS-MOLENBEEK"},
  {"code": 1080, "locality": "MOLENBEEK-SAINT-JEAN"},
  {"code": 1081, "locality": "KOEKELBERG"},
  {"code": 1081, "locality": "KOEKELBERG"},
  {"code": 1082, "locality": "SINT-AGATHA-BERCHEM"},
  {"code": 1082, "locality": "BERCHEM-SAINTE-AGATHE"},
  {"code": 1083, "locality": "GANSHOREN"},
  {"code": 1083, "locality": "GANSHOREN"},
  {"code": 1090, "locality": "JETTE"},
  {"code": 1090, "locality": "JETTE"},
  {"code": 1099, "locality": "BRUSSEL X"},
  {"code": 1099, "locality": "BRUXELLES X"},
  {"code": 1110, "locality": "OTAN - NATO"},
  {"code": 1110, "locality": "OTAN - NATO"},
  {"code": 1120, "locality": "NEDER-OVER-HEEMBEEK (BRUSSEL)"},
  {"code": 1120, "locality": "NEDER-OVER-HEEMBEEK (BRUXELLES)"},
  {"code": 1130, "locality": "HAREN (BRUSSEL)"},
  {"code": 1130, "locality": "HAREN (BRUXELLES)"},
  {"code": 1140, "locality": "EVERE"},
  {"code": 1140, "locality": "EVERE"},
  {"code": 1150, "locality": "SINT-PIETERS-WOLUWE"},
  {"code": 1150, "locality": "WOLUWE-SAINT-PIERRE"},
  {"code": 1160, "locality": "OUDERGEM"},
  {"code": 1160, "locality": "AUDERGHEM"},
  {"code": 1170, "locality": "WATERMAAL-BOSVOORDE"},
  {"code": 1170, "locality": "WATERMAEL-BOITSFORT"},
  {"code": 1180, "locality": "UKKEL"},
  {"code": 1180, "locality": "UCCLE"},
  {"code": 1190, "locality": "VORST"},
  {"code": 1190, "locality": "FOREST"},
  {"code": 1200, "locality": "SINT-LAMBRECHTS-WOLUWE"},
  {"code": 1200, "locality": "WOLUWE-SAINT-LAMBERT"},
  {"code": 1210, "locality": "SINT-JOOST-TEN-NODE"},
  {"code": 1210, "locality": "SAINT-JOSSE-TEN-NOODE"},
  {"code": 1300, "locality": "LIMAL"},
  {"code": 1300, "locality": "WAVRE"},
  {"code": 1301, "locality": "BIERGES"},
  {"code": 1310, "locality": "LA HULPE"},
  {"code": 1315, "locality": "GLIMES"},
  {"code": 1315, "locality": "INCOURT"},
  {"code": 1315, "locality": "OPPREBAIS"},
  {"code": 1315, "locality": "PIETREBAIS"},
  {"code": 1315, "locality": "ROUX-MIROIR"},
  {"code": 1320, "locality": "BEAUVECHAIN"},
  {"code": 1320, "locality": "HAMME-MILLE"},
  {"code": 1320, "locality": "L'ECLUSE"},
  {"code": 1320, "locality": "NODEBAIS"},
  {"code": 1320, "locality": "TOURINNES-LA-GROSSE"},
  {"code": 1325, "locality": "BONLEZ"},
  {"code": 1325, "locality": "CHAUMONT-GISTOUX"},
  {"code": 1325, "locality": "CORROY-LE-GRAND"},
  {"code": 1325, "locality": "DION-VALMONT"},
  {"code": 1325, "locality": "LONGUEVILLE"},
  {"code": 1330, "locality": "RIXENSART"},
  {"code": 1331, "locality": "ROSIERES"},
  {"code": 1332, "locality": "GENVAL"},
  {"code": 1340, "locality": "OTTIGNIES"},
  {"code": 1340, "locality": "OTTIGNIES-LOUVAIN-LA-NEUVE"},
  {"code": 1341, "locality": "CEROUX-MOUSTY"},
  {"code": 1342, "locality": "LIMELETTE"},
  {"code": 1348, "locality": "LOUVAIN-LA-NEUVE"},
  {"code": 1350, "locality": "ENINES"},
  {"code": 1350, "locality": "FOLX-LES-CAVES"},
  {"code": 1350, "locality": "JANDRAIN-JANDRENOUILLE"},
  {"code": 1350, "locality": "JAUCHE"},
  {"code": 1350, "locality": "MARILLES"},
  {"code": 1350, "locality": "NODUWEZ"},
  {"code": 1350, "locality": "ORP-JAUCHE"},
  {"code": 1350, "locality": "ORP-LE-GRAND"},
  {"code": 1357, "locality": "HELECINE"},
  {"code": 1357, "locality": "LINSMEAU"},
  {"code": 1357, "locality": "NEERHEYLISSEM"},
  {"code": 1357, "locality": "OPHEYLISSEM"},
  {"code": 1360, "locality": "MALEVES-SAINTE-MARIE-WASTINES"},
  {"code": 1360, "locality": "ORBAIS"},
  {"code": 1360, "locality": "PERWEZ"},
  {"code": 1360, "locality": "THOREMBAIS-LES-BEGUINES"},
  {"code": 1360, "locality": "THOREMBAIS-SAINT-TROND"},
  {"code": 1367, "locality": "AUTRE-EGLISE"},
  {"code": 1367, "locality": "BOMAL (BT.)"},
  {"code": 1367, "locality": "GEEST-GEROMPONT-PETIT-ROSIERE"},
  {"code": 1367, "locality": "GEROMPONT"},
  {"code": 1367, "locality": "GRAND-ROSIERE-HOTTOMONT"},
  {"code": 1367, "locality": "HUPPAYE"},
  {"code": 1367, "locality": "MONT-SAINT-ANDRE"},
  {"code": 1367, "locality": "RAMILLIES"},
  {"code": 1370, "locality": "DONGELBERG"},
  {"code": 1370, "locality": "JAUCHELETTE"},
  {"code": 1370, "locality": "JODOIGNE"},
  {"code": 1370, "locality": "JODOIGNE-SOUVERAINE"},
  {"code": 1370, "locality": "LATHUY"},
  {"code": 1370, "locality": "MELIN"},
  {"code": 1370, "locality": "PIETRAIN"},
  {"code": 1370, "locality": "SAINT-JEAN-GEEST"},
  {"code": 1370, "locality": "SAINT-REMY-GEEST"},
  {"code": 1370, "locality": "ZETRUD-LUMAY"},
  {"code": 1380, "locality": "COUTURE-SAINT-GERMAIN"},
  {"code": 1380, "locality": "LASNE"},
  {"code": 1380, "locality": "LASNE-CHAPELLE-SAINT-LAMBERT"},
  {"code": 1380, "locality": "MARANSART"},
  {"code": 1380, "locality": "OHAIN"},
  {"code": 1380, "locality": "PLANCENOIT"},
  {"code": 1390, "locality": "ARCHENNES"},
  {"code": 1390, "locality": "BIEZ"},
  {"code": 1390, "locality": "BOSSUT-GOTTECHAIN"},
  {"code": 1390, "locality": "GREZ-DOICEAU"},
  {"code": 1390, "locality": "NETHEN"},
  {"code": 1400, "locality": "MONSTREUX"},
  {"code": 1400, "locality": "NIVELLES"},
  {"code": 1401, "locality": "BAULERS"},
  {"code": 1402, "locality": "THINES"},
  {"code": 1404, "locality": "BORNIVAL"},
  {"code": 1410, "locality": "WATERLOO"},
  {"code": 1420, "locality": "BRAINE-L'ALLEUD"},
  {"code": 1421, "locality": "OPHAIN-BOIS-SEIGNEUR-ISAAC"},
  {"code": 1428, "locality": "LILLOIS-WITTERZEE"},
  {"code": 1430, "locality": "BIERGHES"},
  {"code": 1430, "locality": "QUENAST"},
  {"code": 1430, "locality": "REBECQ"},
  {"code": 1430, "locality": "REBECQ-ROGNON"},
  {"code": 1435, "locality": "CORBAIS"},
  {"code": 1435, "locality": "HEVILLERS"},
  {"code": 1435, "locality": "MONT-SAINT-GUIBERT"},
  {"code": 1440, "locality": "BRAINE-LE-CHATEAU"},
  {"code": 1440, "locality": "WAUTHIER-BRAINE"},
  {"code": 1450, "locality": "CHASTRE"},
  {"code": 1450, "locality": "CHASTRE-VILLEROUX-BLANMONT"},
  {"code": 1450, "locality": "CORTIL-NOIRMONT"},
  {"code": 1450, "locality": "GENTINNES"},
  {"code": 1450, "locality": "SAINT-GERY"},
  {"code": 1457, "locality": "NIL-SAINT-VINCENT-SAINT-MARTIN"},
  {"code": 1457, "locality": "TOURINNES-SAINT-LAMBERT"},
  {"code": 1457, "locality": "WALHAIN"},
  {"code": 1457, "locality": "WALHAIN-SAINT-PAUL"},
  {"code": 1460, "locality": "ITTRE"},
  {"code": 1460, "locality": "VIRGINAL-SAMME"},
  {"code": 1461, "locality": "HAUT-ITTRE"},
  {"code": 1470, "locality": "BAISY-THY"},
  {"code": 1470, "locality": "BOUSVAL"},
  {"code": 1470, "locality": "GENAPPE"},
  {"code": 1471, "locality": "LOUPOIGNE"},
  {"code": 1472, "locality": "VIEUX-GENAPPE"},
  {"code": 1473, "locality": "GLABAIS"},
  {"code": 1474, "locality": "WAYS"},
  {"code": 1476, "locality": "HOUTAIN-LE-VAL"},
  {"code": 1480, "locality": "CLABECQ"},
  {"code": 1480, "locality": "OISQUERCQ"},
  {"code": 1480, "locality": "SAINTES"},
  {"code": 1480, "locality": "TUBIZE"},
  {"code": 1490, "locality": "COURT-SAINT-ETIENNE"},
  {"code": 1495, "locality": "MARBAIS (BT.)"},
  {"code": 1495, "locality": "MELLERY"},
  {"code": 1495, "locality": "SART-DAMES-AVELINES"},
  {"code": 1495, "locality": "TILLY"},
  {"code": 1495, "locality": "VILLERS-LA-VILLE"},
  {"code": 1500, "locality": "HALLE"},
  {"code": 1501, "locality": "BUIZINGEN"},
  {"code": 1502, "locality": "LEMBEEK"},
  {"code": 1540, "locality": "HERFELINGEN"},
  {"code": 1540, "locality": "HERNE"},
  {"code": 1541, "locality": "SINT-PIETERS-KAPELLE (BT.)"},
  {"code": 1547, "locality": "BEVER"},
  {"code": 1547, "locality": "BEVER"},
  {"code": 1560, "locality": "HOEILAART"},
  {"code": 1570, "locality": "GALMAARDEN"},
  {"code": 1570, "locality": "TOLLEMBEEK"},
  {"code": 1570, "locality": "VOLLEZELE"},
  {"code": 1600, "locality": "OUDENAKEN"},
  {"code": 1600, "locality": "SINT-LAUREINS-BERCHEM"},
  {"code": 1600, "locality": "SINT-PIETERS-LEEUW"},
  {"code": 1601, "locality": "RUISBROEK (BT.)"},
  {"code": 1602, "locality": "VLEZENBEEK"},
  {"code": 1620, "locality": "DROGENBOS"},
  {"code": 1630, "locality": "LINKEBEEK"},
  {"code": 1640, "locality": "SINT-GENESIUS-RODE"},
  {"code": 1640, "locality": "SINT-GENESIUS-RODE"},
  {"code": 1650, "locality": "BEERSEL"},
  {"code": 1651, "locality": "LOT"},
  {"code": 1652, "locality": "ALSEMBERG"},
  {"code": 1653, "locality": "DWORP"},
  {"code": 1654, "locality": "HUIZINGEN"},
  {"code": 1670, "locality": "BOGAARDEN"},
  {"code": 1670, "locality": "HEIKRUIS"},
  {"code": 1670, "locality": "PEPINGEN"},
  {"code": 1671, "locality": "ELINGEN"},
  {"code": 1673, "locality": "BEERT"},
  {"code": 1674, "locality": "BELLINGEN"},
  {"code": 1700, "locality": "DILBEEK"},
  {"code": 1700, "locality": "SINT-MARTENS-BODEGEM"},
  {"code": 1700, "locality": "SINT-ULRIKS-KAPELLE"},
  {"code": 1701, "locality": "ITTERBEEK"},
  {"code": 1702, "locality": "GROOT-BIJGAARDEN"},
  {"code": 1703, "locality": "SCHEPDAAL"},
  {"code": 1730, "locality": "ASSE"},
  {"code": 1730, "locality": "BEKKERZEEL"},
  {"code": 1730, "locality": "KOBBEGEM"},
  {"code": 1730, "locality": "MOLLEM"},
  {"code": 1731, "locality": "RELEGEM"},
  {"code": 1731, "locality": "ZELLIK"},
  {"code": 1740, "locality": "TERNAT"},
  {"code": 1741, "locality": "WAMBEEK"},
  {"code": 1742, "locality": "SINT-KATHERINA-LOMBEEK"},
  {"code": 1745, "locality": "MAZENZELE"},
  {"code": 1745, "locality": "OPWIJK"},
  {"code": 1750, "locality": "GAASBEEK"},
  {"code": 1750, "locality": "LENNIK"},
  {"code": 1750, "locality": "SINT-KWINTENS-LENNIK"},
  {"code": 1750, "locality": "SINT-MARTENS-LENNIK"},
  {"code": 1755, "locality": "GOOIK"},
  {"code": 1755, "locality": "KESTER"},
  {"code": 1755, "locality": "LEERBEEK"},
  {"code": 1755, "locality": "OETINGEN"},
  {"code": 1760, "locality": "ONZE-LIEVE-VROUW-LOMBEEK"},
  {"code": 1760, "locality": "PAMEL"},
  {"code": 1760, "locality": "ROOSDAAL"},
  {"code": 1760, "locality": "STRIJTEM"},
  {"code": 1761, "locality": "BORCHTLOMBEEK"},
  {"code": 1770, "locality": "LIEDEKERKE"},
  {"code": 1780, "locality": "WEMMEL"},
  {"code": 1785, "locality": "BRUSSEGEM"},
  {"code": 1785, "locality": "HAMME (BT.)"},
  {"code": 1785, "locality": "MERCHTEM"},
  {"code": 1790, "locality": "AFFLIGEM"},
  {"code": 1790, "locality": "ESSENE"},
  {"code": 1790, "locality": "HEKELGEM"},
  {"code": 1790, "locality": "TERALFENE"},
  {"code": 1800, "locality": "PEUTIE"},
  {"code": 1800, "locality": "VILVOORDE"},
  {"code": 1804, "locality": "VILVOORDE"},
  {"code": 1820, "locality": "MELSBROEK"},
  {"code": 1820, "locality": "PERK"},
  {"code": 1820, "locality": "STEENOKKERZEEL"},
  {"code": 1830, "locality": "MACHELEN (BT.)"},
  {"code": 1831, "locality": "DIEGEM"},
  {"code": 1840, "locality": "LONDERZEEL"},
  {"code": 1840, "locality": "MALDEREN"},
  {"code": 1840, "locality": "STEENHUFFEL"},
  {"code": 1850, "locality": "GRIMBERGEN"},
  {"code": 1851, "locality": "HUMBEEK"},
  {"code": 1852, "locality": "BEIGEM"},
  {"code": 1853, "locality": "STROMBEEK-BEVER"},
  {"code": 1860, "locality": "MEISE"},
  {"code": 1861, "locality": "WOLVERTEM"},
  {"code": 1880, "locality": "KAPELLE-OP-DEN-BOS"},
  {"code": 1880, "locality": "NIEUWENRODE"},
  {"code": 1880, "locality": "RAMSDONK"},
  {"code": 1910, "locality": "BERG (BT.)"},
  {"code": 1910, "locality": "BUKEN"},
  {"code": 1910, "locality": "KAMPENHOUT"},
  {"code": 1910, "locality": "NEDEROKKERZEEL"},
  {"code": 1930, "locality": "NOSSEGEM"},
  {"code": 1930, "locality": "ZAVENTEM"},
  {"code": 1931, "locality": "BRUCARGO"},
  {"code": 1932, "locality": "SINT-STEVENS-WOLUWE"},
  {"code": 1933, "locality": "STERREBEEK"},
  {"code": 1950, "locality": "KRAAINEM"},
  {"code": 1970, "locality": "WEZEMBEEK-OPPEM"},
  {"code": 1980, "locality": "EPPEGEM"},
  {"code": 1980, "locality": "ZEMST"},
  {"code": 1981, "locality": "HOFSTADE (BT.)"},
  {"code": 1982, "locality": "ELEWIJT"},
  {"code": 1982, "locality": "WEERDE"},
  {"code": 2000, "locality": "ANTWERPEN"},
  {"code": 2018, "locality": "ANTWERPEN"},
  {"code": 2020, "locality": "ANTWERPEN-KIEL"},
  {"code": 2020, "locality": "ANTWERPEN (HAVEN)"},
  {"code": 2030, "locality": "ANTWERPEN (IMEA)"},
  {"code": 2030, "locality": "ANTWERPEN (HAVEN)"},
  {"code": 2040, "locality": "ANTWERPEN"},
  {"code": 2040, "locality": "ANTWERPEN (HAVEN)"},
  {"code": 2040, "locality": "BERENDRECHT"},
  {"code": 2040, "locality": "LILLO"},
  {"code": 2040, "locality": "ZANDVLIET"},
  {"code": 2050, "locality": "ANTWERPEN"},
  {"code": 2060, "locality": "ANTWERPEN"},
  {"code": 2070, "locality": "BURCHT"},
  {"code": 2070, "locality": "ZWIJNDRECHT"},
  {"code": 2100, "locality": "DEURNE (ANTWERPEN)"},
  {"code": 2110, "locality": "WIJNEGEM"},
  {"code": 2140, "locality": "BORGERHOUT (ANTWERPEN)"},
  {"code": 2150, "locality": "BORSBEEK (ANTWERPEN)"},
  {"code": 2160, "locality": "WOMMELGEM"},
  {"code": 2170, "locality": "MERKSEM (ANTWERPEN)"},
  {"code": 2180, "locality": "EKEREN (ANTWERPEN)"},
  {"code": 2200, "locality": "HERENTALS"},
  {"code": 2200, "locality": "MORKHOVEN"},
  {"code": 2200, "locality": "NOORDERWIJK"},
  {"code": 2220, "locality": "HALLAAR"},
  {"code": 2220, "locality": "HEIST-OP-DEN-BERG"},
  {"code": 2221, "locality": "BOOISCHOT"},
  {"code": 2222, "locality": "ITEGEM"},
  {"code": 2222, "locality": "WIEKEVORST"},
  {"code": 2223, "locality": "SCHRIEK"},
  {"code": 2230, "locality": "HERSELT"},
  {"code": 2230, "locality": "RAMSEL"},
  {"code": 2235, "locality": "HOUTVENNE"},
  {"code": 2235, "locality": "HULSHOUT"},
  {"code": 2235, "locality": "WESTMEERBEEK"},
  {"code": 2240, "locality": "MASSENHOVEN"},
  {"code": 2240, "locality": "VIERSEL"},
  {"code": 2240, "locality": "ZANDHOVEN"},
  {"code": 2242, "locality": "PULDERBOS"},
  {"code": 2243, "locality": "PULLE"},
  {"code": 2250, "locality": "OLEN"},
  {"code": 2260, "locality": "OEVEL"},
  {"code": 2260, "locality": "TONGERLO (ANTW.)"},
  {"code": 2260, "locality": "WESTERLO"},
  {"code": 2260, "locality": "ZOERLE-PARWIJS"},
  {"code": 2270, "locality": "HERENTHOUT"},
  {"code": 2275, "locality": "GIERLE"},
  {"code": 2275, "locality": "LILLE"},
  {"code": 2275, "locality": "POEDERLEE"},
  {"code": 2275, "locality": "WECHELDERZANDE"},
  {"code": 2280, "locality": "GROBBENDONK"},
  {"code": 2288, "locality": "BOUWEL"},
  {"code": 2290, "locality": "VORSELAAR"},
  {"code": 2300, "locality": "TURNHOUT"},
  {"code": 2310, "locality": "RIJKEVORSEL"},
  {"code": 2320, "locality": "HOOGSTRATEN"},
  {"code": 2321, "locality": "MEER"},
  {"code": 2322, "locality": "MINDERHOUT"},
  {"code": 2323, "locality": "WORTEL"},
  {"code": 2328, "locality": "MEERLE"},
  {"code": 2330, "locality": "MERKSPLAS"},
  {"code": 2340, "locality": "BEERSE"},
  {"code": 2340, "locality": "VLIMMEREN"},
  {"code": 2350, "locality": "VOSSELAAR"},
  {"code": 2360, "locality": "OUD-TURNHOUT"},
  {"code": 2370, "locality": "ARENDONK"},
  {"code": 2380, "locality": "RAVELS"},
  {"code": 2381, "locality": "WEELDE"},
  {"code": 2382, "locality": "POPPEL"},
  {"code": 2387, "locality": "BAARLE-HERTOG"},
  {"code": 2390, "locality": "MALLE"},
  {"code": 2390, "locality": "OOSTMALLE"},
  {"code": 2390, "locality": "WESTMALLE"},
  {"code": 2400, "locality": "MOL"},
  {"code": 2430, "locality": "EINDHOUT"},
  {"code": 2430, "locality": "LAAKDAL"},
  {"code": 2430, "locality": "VORST (KEMPEN)"},
  {"code": 2431, "locality": "LAAKDAL - VARENDONK"},
  {"code": 2431, "locality": "VEERLE"},
  {"code": 2440, "locality": "GEEL"},
  {"code": 2450, "locality": "MEERHOUT"},
  {"code": 2460, "locality": "KASTERLEE"},
  {"code": 2460, "locality": "LICHTAART"},
  {"code": 2460, "locality": "TIELEN"},
  {"code": 2470, "locality": "RETIE"},
  {"code": 2480, "locality": "DESSEL"},
  {"code": 2490, "locality": "BALEN"},
  {"code": 2491, "locality": "OLMEN"},
  {"code": 2500, "locality": "KONINGSHOOIKT"},
  {"code": 2500, "locality": "LIER"},
  {"code": 2520, "locality": "BROECHEM"},
  {"code": 2520, "locality": "EMBLEM"},
  {"code": 2520, "locality": "OELEGEM"},
  {"code": 2520, "locality": "RANST"},
  {"code": 2530, "locality": "BOECHOUT"},
  {"code": 2531, "locality": "VREMDE"},
  {"code": 2540, "locality": "HOVE"},
  {"code": 2547, "locality": "LINT"},
  {"code": 2550, "locality": "KONTICH"},
  {"code": 2550, "locality": "WAARLOOS"},
  {"code": 2560, "locality": "BEVEL"},
  {"code": 2560, "locality": "KESSEL"},
  {"code": 2560, "locality": "NIJLEN"},
  {"code": 2570, "locality": "DUFFEL"},
  {"code": 2580, "locality": "BEERZEL"},
  {"code": 2580, "locality": "PUTTE"},
  {"code": 2590, "locality": "BERLAAR"},
  {"code": 2590, "locality": "GESTEL"},
  {"code": 2600, "locality": "BERCHEM (ANTWERPEN)"},
  {"code": 2610, "locality": "WILRIJK (ANTWERPEN)"},
  {"code": 2620, "locality": "HEMIKSEM"},
  {"code": 2627, "locality": "SCHELLE"},
  {"code": 2630, "locality": "AARTSELAAR"},
  {"code": 2640, "locality": "MORTSEL"},
  {"code": 2650, "locality": "EDEGEM"},
  {"code": 2660, "locality": "HOBOKEN (ANTWERPEN)"},
  {"code": 2800, "locality": "MECHELEN"},
  {"code": 2800, "locality": "WALEM"},
  {"code": 2801, "locality": "HEFFEN"},
  {"code": 2811, "locality": "HOMBEEK"},
  {"code": 2811, "locality": "LEEST"},
  {"code": 2812, "locality": "MUIZEN (MECHELEN)"},
  {"code": 2820, "locality": "BONHEIDEN"},
  {"code": 2820, "locality": "RIJMENAM"},
  {"code": 2830, "locality": "BLAASVELD"},
  {"code": 2830, "locality": "HEINDONK"},
  {"code": 2830, "locality": "TISSELT"},
  {"code": 2830, "locality": "WILLEBROEK"},
  {"code": 2840, "locality": "REET"},
  {"code": 2840, "locality": "RUMST"},
  {"code": 2840, "locality": "TERHAGEN"},
  {"code": 2845, "locality": "NIEL"},
  {"code": 2850, "locality": "BOOM"},
  {"code": 2860, "locality": "SINT-KATELIJNE-WAVER"},
  {"code": 2861, "locality": "ONZE-LIEVE-VROUW-WAVER"},
  {"code": 2870, "locality": "PUURS-SINT-AMANDS"},
  {"code": 2870, "locality": "PUURS-SINT-AMANDS"},
  {"code": 2870, "locality": "PUURS-SINT-AMANDS"},
  {"code": 2870, "locality": "PUURS-SINT-AMANDS"},
  {"code": 2880, "locality": "BORNEM"},
  {"code": 2880, "locality": "HINGENE"},
  {"code": 2880, "locality": "MARIEKERKE (BORNEM)"},
  {"code": 2880, "locality": "WEERT"},
  {"code": 2890, "locality": "PUURS-SINT-AMANDS"},
  {"code": 2890, "locality": "PUURS-SINT-AMANDS"},
  {"code": 2890, "locality": "PUURS-SINT-AMANDS"},
  {"code": 2900, "locality": "SCHOTEN"},
  {"code": 2910, "locality": "ESSEN"},
  {"code": 2920, "locality": "KALMTHOUT"},
  {"code": 2930, "locality": "BRASSCHAAT"},
  {"code": 2940, "locality": "HOEVENEN"},
  {"code": 2940, "locality": "STABROEK"},
  {"code": 2950, "locality": "KAPELLEN"},
  {"code": 2950, "locality": "KAPELLEN (HOOGBOOM)"},
  {"code": 2960, "locality": "BRECHT"},
  {"code": 2960, "locality": "SINT-JOB-IN-'T-GOOR"},
  {"code": 2960, "locality": "SINT-LENAARTS"},
  {"code": 2970, "locality": "'S GRAVENWEZEL"},
  {"code": 2970, "locality": "SCHILDE"},
  {"code": 2980, "locality": "HALLE (KEMPEN)"},
  {"code": 2980, "locality": "ZOERSEL"},
  {"code": 2990, "locality": "LOENHOUT"},
  {"code": 2990, "locality": "WUUSTWEZEL"},
  {"code": 3000, "locality": "LEUVEN"},
  {"code": 3001, "locality": "HEVERLEE"},
  {"code": 3010, "locality": "KESSEL-LO (LEUVEN)"},
  {"code": 3012, "locality": "WILSELE"},
  {"code": 3018, "locality": "WIJGMAAL (BT.)"},
  {"code": 3020, "locality": "HERENT"},
  {"code": 3020, "locality": "VELTEM-BEISEM"},
  {"code": 3020, "locality": "WINKSELE"},
  {"code": 3040, "locality": "HULDENBERG"},
  {"code": 3040, "locality": "LOONBEEK"},
  {"code": 3040, "locality": "NEERIJSE"},
  {"code": 3040, "locality": "OTTENBURG"},
  {"code": 3040, "locality": "SINT-AGATHA-RODE"},
  {"code": 3050, "locality": "OUD-HEVERLEE"},
  {"code": 3051, "locality": "SINT-JORIS-WEERT"},
  {"code": 3052, "locality": "BLANDEN"},
  {"code": 3053, "locality": "HAASRODE"},
  {"code": 3054, "locality": "VAALBEEK"},
  {"code": 3060, "locality": "BERTEM"},
  {"code": 3060, "locality": "KORBEEK-DIJLE"},
  {"code": 3061, "locality": "LEEFDAAL"},
  {"code": 3070, "locality": "KORTENBERG"},
  {"code": 3071, "locality": "ERPS-KWERPS"},
  {"code": 3078, "locality": "EVERBERG"},
  {"code": 3078, "locality": "MEERBEEK"},
  {"code": 3080, "locality": "DUISBURG"},
  {"code": 3080, "locality": "TERVUREN"},
  {"code": 3080, "locality": "VOSSEM"},
  {"code": 3090, "locality": "OVERIJSE"},
  {"code": 3110, "locality": "ROTSELAAR"},
  {"code": 3111, "locality": "WEZEMAAL"},
  {"code": 3118, "locality": "WERCHTER"},
  {"code": 3120, "locality": "TREMELO"},
  {"code": 3128, "locality": "BAAL"},
  {"code": 3130, "locality": "BEGIJNENDIJK"},
  {"code": 3130, "locality": "BETEKOM"},
  {"code": 3140, "locality": "KEERBERGEN"},
  {"code": 3150, "locality": "HAACHT"},
  {"code": 3150, "locality": "TILDONK"},
  {"code": 3150, "locality": "WESPELAAR"},
  {"code": 3190, "locality": "BOORTMEERBEEK"},
  {"code": 3191, "locality": "HEVER"},
  {"code": 3200, "locality": "AARSCHOT"},
  {"code": 3200, "locality": "GELRODE"},
  {"code": 3201, "locality": "LANGDORP"},
  {"code": 3202, "locality": "RILLAAR"},
  {"code": 3210, "locality": "LINDEN"},
  {"code": 3210, "locality": "LUBBEEK"},
  {"code": 3211, "locality": "BINKOM"},
  {"code": 3212, "locality": "PELLENBERG"},
  {"code": 3220, "locality": "HOLSBEEK"},
  {"code": 3220, "locality": "KORTRIJK-DUTSEL"},
  {"code": 3220, "locality": "SINT-PIETERS-RODE"},
  {"code": 3221, "locality": "NIEUWRODE"},
  {"code": 3270, "locality": "SCHERPENHEUVEL"},
  {"code": 3270, "locality": "SCHERPENHEUVEL-ZICHEM"},
  {"code": 3271, "locality": "AVERBODE"},
  {"code": 3271, "locality": "ZICHEM"},
  {"code": 3272, "locality": "MESSELBROEK"},
  {"code": 3272, "locality": "TESTELT"},
  {"code": 3290, "locality": "DEURNE (BT.)"},
  {"code": 3290, "locality": "DIEST"},
  {"code": 3290, "locality": "SCHAFFEN"},
  {"code": 3290, "locality": "WEBBEKOM"},
  {"code": 3293, "locality": "KAGGEVINNE"},
  {"code": 3294, "locality": "MOLENSTEDE"},
  {"code": 3300, "locality": "BOST"},
  {"code": 3300, "locality": "GOETSENHOVEN"},
  {"code": 3300, "locality": "HAKENDOVER"},
  {"code": 3300, "locality": "KUMTICH"},
  {"code": 3300, "locality": "OORBEEK"},
  {"code": 3300, "locality": "OPLINTER"},
  {"code": 3300, "locality": "SINT-MARGRIETE-HOUTEM (TIENEN)"},
  {"code": 3300, "locality": "TIENEN"},
  {"code": 3300, "locality": "VISSENAKEN"},
  {"code": 3320, "locality": "HOEGAARDEN"},
  {"code": 3320, "locality": "MELDERT (BT.)"},
  {"code": 3321, "locality": "OUTGAARDEN"},
  {"code": 3350, "locality": "DRIESLINTER"},
  {"code": 3350, "locality": "LINTER"},
  {"code": 3350, "locality": "MELKWEZER"},
  {"code": 3350, "locality": "NEERHESPEN"},
  {"code": 3350, "locality": "NEERLINTER"},
  {"code": 3350, "locality": "ORSMAAL-GUSSENHOVEN"},
  {"code": 3350, "locality": "OVERHESPEN"},
  {"code": 3350, "locality": "WOMMERSOM"},
  {"code": 3360, "locality": "BIERBEEK"},
  {"code": 3360, "locality": "KORBEEK-LO"},
  {"code": 3360, "locality": "LOVENJOEL"},
  {"code": 3360, "locality": "OPVELP"},
  {"code": 3370, "locality": "BOUTERSEM"},
  {"code": 3370, "locality": "KERKOM"},
  {"code": 3370, "locality": "NEERVELP"},
  {"code": 3370, "locality": "ROOSBEEK"},
  {"code": 3370, "locality": "VERTRIJK"},
  {"code": 3370, "locality": "WILLEBRINGEN"},
  {"code": 3380, "locality": "BUNSBEEK"},
  {"code": 3380, "locality": "GLABBEEK-ZUURBEMDE"},
  {"code": 3381, "locality": "KAPELLEN (BT.)"},
  {"code": 3384, "locality": "ATTENRODE"},
  {"code": 3390, "locality": "HOUWAART"},
  {"code": 3390, "locality": "SINT-JORIS-WINGE"},
  {"code": 3390, "locality": "TIELT (BT.)"},
  {"code": 3390, "locality": "TIELT-WINGE"},
  {"code": 3391, "locality": "MEENSEL-KIEZEGEM"},
  {"code": 3400, "locality": "ELIKSEM"},
  {"code": 3400, "locality": "EZEMAAL"},
  {"code": 3400, "locality": "LAAR"},
  {"code": 3400, "locality": "LANDEN"},
  {"code": 3400, "locality": "NEERWINDEN"},
  {"code": 3400, "locality": "OVERWINDEN"},
  {"code": 3400, "locality": "RUMSDORP"},
  {"code": 3400, "locality": "WANGE"},
  {"code": 3401, "locality": "WAASMONT"},
  {"code": 3401, "locality": "WALSBETS"},
  {"code": 3401, "locality": "WALSHOUTEM"},
  {"code": 3401, "locality": "WEZEREN"},
  {"code": 3404, "locality": "ATTENHOVEN"},
  {"code": 3404, "locality": "NEERLANDEN"},
  {"code": 3440, "locality": "BUDINGEN"},
  {"code": 3440, "locality": "DORMAAL"},
  {"code": 3440, "locality": "HALLE-BOOIENHOVEN"},
  {"code": 3440, "locality": "HELEN-BOS"},
  {"code": 3440, "locality": "ZOUTLEEUW"},
  {"code": 3450, "locality": "GEETBETS"},
  {"code": 3450, "locality": "GRAZEN"},
  {"code": 3454, "locality": "RUMMEN"},
  {"code": 3460, "locality": "ASSENT"},
  {"code": 3460, "locality": "BEKKEVOORT"},
  {"code": 3461, "locality": "MOLENBEEK-WERSBEEK"},
  {"code": 3470, "locality": "KORTENAKEN"},
  {"code": 3470, "locality": "RANSBERG"},
  {"code": 3471, "locality": "HOELEDEN"},
  {"code": 3472, "locality": "KERSBEEK-MISKOM"},
  {"code": 3473, "locality": "WAANRODE"},
  {"code": 3500, "locality": "HASSELT"},
  {"code": 3500, "locality": "SINT-LAMBRECHTS-HERK"},
  {"code": 3501, "locality": "WIMMERTINGEN"},
  {"code": 3510, "locality": "KERMT (HASSELT)"},
  {"code": 3510, "locality": "SPALBEEK"},
  {"code": 3511, "locality": "KURINGEN"},
  {"code": 3511, "locality": "STOKROOIE"},
  {"code": 3512, "locality": "STEVOORT"},
  {"code": 3520, "locality": "ZONHOVEN"},
  {"code": 3530, "locality": "HELCHTEREN"},
  {"code": 3530, "locality": "HOUTHALEN"},
  {"code": 3530, "locality": "HOUTHALEN-HELCHTEREN"},
  {"code": 3540, "locality": "BERBROEK"},
  {"code": 3540, "locality": "DONK"},
  {"code": 3540, "locality": "HERK-DE-STAD"},
  {"code": 3540, "locality": "SCHULEN"},
  {"code": 3545, "locality": "HALEN"},
  {"code": 3545, "locality": "LOKSBERGEN"},
  {"code": 3545, "locality": "ZELEM"},
  {"code": 3550, "locality": "HEUSDEN (LIMB.)"},
  {"code": 3550, "locality": "HEUSDEN-ZOLDER"},
  {"code": 3550, "locality": "ZOLDER"},
  {"code": 3560, "locality": "LINKHOUT"},
  {"code": 3560, "locality": "LUMMEN"},
  {"code": 3560, "locality": "MELDERT (LIMB.)"},
  {"code": 3570, "locality": "ALKEN"},
  {"code": 3580, "locality": "BERINGEN"},
  {"code": 3581, "locality": "BEVERLO"},
  {"code": 3582, "locality": "KOERSEL"},
  {"code": 3583, "locality": "PAAL"},
  {"code": 3590, "locality": "DIEPENBEEK"},
  {"code": 3600, "locality": "GENK"},
  {"code": 3620, "locality": "GELLIK"},
  {"code": 3620, "locality": "LANAKEN"},
  {"code": 3620, "locality": "NEERHAREN"},
  {"code": 3620, "locality": "VELDWEZELT"},
  {"code": 3621, "locality": "REKEM"},
  {"code": 3630, "locality": "EISDEN"},
  {"code": 3630, "locality": "LEUT"},
  {"code": 3630, "locality": "MAASMECHELEN"},
  {"code": 3630, "locality": "MECHELEN-AAN-DE-MAAS"},
  {"code": 3630, "locality": "MEESWIJK"},
  {"code": 3630, "locality": "OPGRIMBIE"},
  {"code": 3630, "locality": "VUCHT"},
  {"code": 3631, "locality": "BOORSEM"},
  {"code": 3631, "locality": "UIKHOVEN"},
  {"code": 3640, "locality": "KESSENICH"},
  {"code": 3640, "locality": "KINROOI"},
  {"code": 3640, "locality": "MOLENBEERSEL"},
  {"code": 3640, "locality": "OPHOVEN"},
  {"code": 3650, "locality": "DILSEN-STOKKEM"},
  {"code": 3650, "locality": "ELEN"},
  {"code": 3650, "locality": "LANKLAAR"},
  {"code": 3650, "locality": "ROTEM"},
  {"code": 3650, "locality": "STOKKEM"},
  {"code": 3660, "locality": "OUDSBERGEN"},
  {"code": 3665, "locality": "AS"},
  {"code": 3668, "locality": "NIEL-BIJ-AS"},
  {"code": 3670, "locality": "OUDSBERGEN"},
  {"code": 3670, "locality": "OUDSBERGEN"},
  {"code": 3670, "locality": "OUDSBERGEN"},
  {"code": 3670, "locality": "OUDSBERGEN"},
  {"code": 3670, "locality": "OUDSBERGEN"},
  {"code": 3670, "locality": "OUDSBERGEN"},
  {"code": 3680, "locality": "MAASEIK"},
  {"code": 3680, "locality": "NEEROETEREN"},
  {"code": 3680, "locality": "OPOETEREN"},
  {"code": 3690, "locality": "ZUTENDAAL"},
  {"code": 3700, "locality": "'S HERENELDEREN"},
  {"code": 3700, "locality": "BERG (LIMB.)"},
  {"code": 3700, "locality": "DIETS-HEUR"},
  {"code": 3700, "locality": "HAREN (TONGEREN)"},
  {"code": 3700, "locality": "HENIS"},
  {"code": 3700, "locality": "KOLMONT (TONGEREN)"},
  {"code": 3700, "locality": "KONINKSEM"},
  {"code": 3700, "locality": "LAUW"},
  {"code": 3700, "locality": "MAL"},
  {"code": 3700, "locality": "NEERREPEN"},
  {"code": 3700, "locality": "NEREM"},
  {"code": 3700, "locality": "OVERREPEN (KOLMONT)"},
  {"code": 3700, "locality": "PIRINGEN (HAREN)"},
  {"code": 3700, "locality": "RIKSINGEN"},
  {"code": 3700, "locality": "RUTTEN"},
  {"code": 3700, "locality": "SLUIZEN"},
  {"code": 3700, "locality": "TONGEREN"},
  {"code": 3700, "locality": "VREREN"},
  {"code": 3700, "locality": "WIDOOIE (HAREN)"},
  {"code": 3717, "locality": "HERSTAPPE"},
  {"code": 3720, "locality": "KORTESSEM"},
  {"code": 3721, "locality": "VLIERMAALROOT"},
  {"code": 3722, "locality": "WINTERSHOVEN"},
  {"code": 3723, "locality": "GUIGOVEN"},
  {"code": 3724, "locality": "VLIERMAAL"},
  {"code": 3730, "locality": "HOESELT"},
  {"code": 3730, "locality": "ROMERSHOVEN"},
  {"code": 3730, "locality": "SINT-HUIBRECHTS-HERN"},
  {"code": 3730, "locality": "WERM"},
  {"code": 3732, "locality": "SCHALKHOVEN"},
  {"code": 3740, "locality": "BEVERST"},
  {"code": 3740, "locality": "BILZEN"},
  {"code": 3740, "locality": "EIGENBILZEN"},
  {"code": 3740, "locality": "GROTE-SPOUWEN"},
  {"code": 3740, "locality": "HEES"},
  {"code": 3740, "locality": "KLEINE-SPOUWEN"},
  {"code": 3740, "locality": "MOPERTINGEN"},
  {"code": 3740, "locality": "MUNSTERBILZEN"},
  {"code": 3740, "locality": "RIJKHOVEN"},
  {"code": 3740, "locality": "ROSMEER"},
  {"code": 3740, "locality": "SPOUWEN"},
  {"code": 3740, "locality": "WALTWILDER"},
  {"code": 3742, "locality": "MARTENSLINDE"},
  {"code": 3746, "locality": "HOELBEEK"},
  {"code": 3770, "locality": "GENOELSELDEREN"},
  {"code": 3770, "locality": "HERDEREN"},
  {"code": 3770, "locality": "KANNE"},
  {"code": 3770, "locality": "MEMBRUGGEN"},
  {"code": 3770, "locality": "MILLEN"},
  {"code": 3770, "locality": "RIEMST"},
  {"code": 3770, "locality": "VAL-MEER"},
  {"code": 3770, "locality": "VLIJTINGEN"},
  {"code": 3770, "locality": "VROENHOVEN"},
  {"code": 3770, "locality": "ZICHEN-ZUSSEN-BOLDER"},
  {"code": 3790, "locality": "SINT-MARTENS-VOEREN"},
  {"code": 3790, "locality": "VOEREN"},
  {"code": 3790, "locality": "MOELINGEN"},
  {"code": 3790, "locality": "MOELINGEN"},
  {"code": 3790, "locality": "SINT-MARTENS-VOEREN"},
  {"code": 3790, "locality": "VOEREN"},
  {"code": 3791, "locality": "REMERSDAAL"},
  {"code": 3792, "locality": "FOURON-SAINT-PIERRE"},
  {"code": 3792, "locality": "SINT-PIETERS-VOEREN"},
  {"code": 3793, "locality": "TEUVEN"},
  {"code": 3798, "locality": "'S GRAVENVOEREN"},
  {"code": 3798, "locality": "'S GRAVENVOEREN"},
  {"code": 3800, "locality": "AALST (LIMB.)"},
  {"code": 3800, "locality": "BRUSTEM"},
  {"code": 3800, "locality": "ENGELMANSHOVEN"},
  {"code": 3800, "locality": "GELINDEN"},
  {"code": 3800, "locality": "GROOT-GELMEN"},
  {"code": 3800, "locality": "HALMAAL"},
  {"code": 3800, "locality": "KERKOM-BIJ-SINT-TRUIDEN"},
  {"code": 3800, "locality": "ORDINGEN"},
  {"code": 3800, "locality": "SINT-TRUIDEN"},
  {"code": 3800, "locality": "ZEPPEREN"},
  {"code": 3803, "locality": "DURAS"},
  {"code": 3803, "locality": "GORSEM"},
  {"code": 3803, "locality": "RUNKELEN"},
  {"code": 3803, "locality": "WILDEREN"},
  {"code": 3806, "locality": "VELM"},
  {"code": 3830, "locality": "BERLINGEN"},
  {"code": 3830, "locality": "WELLEN"},
  {"code": 3831, "locality": "HERTEN"},
  {"code": 3832, "locality": "ULBEEK"},
  {"code": 3840, "locality": "BOMMERSHOVEN (HAREN)"},
  {"code": 3840, "locality": "BORGLOON"},
  {"code": 3840, "locality": "BROEKOM"},
  {"code": 3840, "locality": "GORS-OPLEEUW"},
  {"code": 3840, "locality": "GOTEM"},
  {"code": 3840, "locality": "GROOT-LOON"},
  {"code": 3840, "locality": "HAREN (BORGLOON)"},
  {"code": 3840, "locality": "HENDRIEKEN"},
  {"code": 3840, "locality": "HOEPERTINGEN"},
  {"code": 3840, "locality": "JESSEREN (KOLMONT)"},
  {"code": 3840, "locality": "KERNIEL"},
  {"code": 3840, "locality": "KOLMONT (BORGLOON)"},
  {"code": 3840, "locality": "KUTTEKOVEN"},
  {"code": 3840, "locality": "RIJKEL"},
  {"code": 3840, "locality": "VOORT"},
  {"code": 3850, "locality": "BINDERVELD"},
  {"code": 3850, "locality": "KOZEN"},
  {"code": 3850, "locality": "NIEUWERKERKEN (LIMB.)"},
  {"code": 3850, "locality": "WIJER"},
  {"code": 3870, "locality": "BATSHEERS"},
  {"code": 3870, "locality": "BOVELINGEN"},
  {"code": 3870, "locality": "GUTSCHOVEN"},
  {"code": 3870, "locality": "HEERS"},
  {"code": 3870, "locality": "HEKS"},
  {"code": 3870, "locality": "HORPMAAL"},
  {"code": 3870, "locality": "KLEIN-GELMEN"},
  {"code": 3870, "locality": "MECHELEN-BOVELINGEN"},
  {"code": 3870, "locality": "METTEKOVEN"},
  {"code": 3870, "locality": "OPHEERS"},
  {"code": 3870, "locality": "RUKKELINGEN-LOON"},
  {"code": 3870, "locality": "VECHMAAL"},
  {"code": 3870, "locality": "VEULEN"},
  {"code": 3890, "locality": "BOEKHOUT"},
  {"code": 3890, "locality": "GINGELOM"},
  {"code": 3890, "locality": "JEUK"},
  {"code": 3890, "locality": "KORTIJS"},
  {"code": 3890, "locality": "MONTENAKEN"},
  {"code": 3890, "locality": "NIEL-BIJ-SINT-TRUIDEN"},
  {"code": 3890, "locality": "VORSEN"},
  {"code": 3891, "locality": "BORLO"},
  {"code": 3891, "locality": "BUVINGEN"},
  {"code": 3891, "locality": "MIELEN-BOVEN-AALST"},
  {"code": 3891, "locality": "MUIZEN (LIMB.)"},
  {"code": 3900, "locality": "PELT"},
  {"code": 3910, "locality": "PELT"},
  {"code": 3910, "locality": "PELT"},
  {"code": 3920, "locality": "LOMMEL"},
  {"code": 3930, "locality": "ACHEL"},
  {"code": 3930, "locality": "HAMONT"},
  {"code": 3930, "locality": "HAMONT-ACHEL"},
  {"code": 3940, "locality": "HECHTEL"},
  {"code": 3940, "locality": "HECHTEL-EKSEL"},
  {"code": 3941, "locality": "EKSEL"},
  {"code": 3945, "locality": "HAM"},
  {"code": 3945, "locality": "KWAADMECHELEN"},
  {"code": 3945, "locality": "OOSTHAM"},
  {"code": 3950, "locality": "BOCHOLT"},
  {"code": 3950, "locality": "KAULILLE"},
  {"code": 3950, "locality": "REPPEL"},
  {"code": 3960, "locality": "BEEK"},
  {"code": 3960, "locality": "BREE"},
  {"code": 3960, "locality": "GERDINGEN"},
  {"code": 3960, "locality": "OPITTER"},
  {"code": 3960, "locality": "TONGERLO (LIMB.)"},
  {"code": 3970, "locality": "LEOPOLDSBURG"},
  {"code": 3971, "locality": "HEPPEN"},
  {"code": 3980, "locality": "TESSENDERLO"},
  {"code": 3990, "locality": "GROTE-BROGEL"},
  {"code": 3990, "locality": "KLEINE-BROGEL"},
  {"code": 3990, "locality": "PEER"},
  {"code": 3990, "locality": "WIJCHMAAL"},
  {"code": 4000, "locality": "GLAIN"},
  {"code": 4000, "locality": "LIEGE"},
  {"code": 4000, "locality": "LIEGE (VOTTEM)"},
  {"code": 4000, "locality": "ROCOURT"},
  {"code": 4000, "locality": "SCLESSIN"},
  {"code": 4020, "locality": "BRESSOUX"},
  {"code": 4020, "locality": "JUPILLE-SUR-MEUSE"},
  {"code": 4020, "locality": "LIEGE"},
  {"code": 4020, "locality": "WANDRE"},
  {"code": 4030, "locality": "GRIVEGNEE"},
  {"code": 4030, "locality": "LIEGE"},
  {"code": 4031, "locality": "ANGLEUR"},
  {"code": 4032, "locality": "CHENEE"},
  {"code": 4040, "locality": "HERSTAL"},
  {"code": 4041, "locality": "MILMORT"},
  {"code": 4041, "locality": "VOTTEM"},
  {"code": 4042, "locality": "LIERS"},
  {"code": 4050, "locality": "CHAUDFONTAINE"},
  {"code": 4051, "locality": "VAUX-SOUS-CHEVREMONT"},
  {"code": 4052, "locality": "BEAUFAYS"},
  {"code": 4053, "locality": "EMBOURG"},
  {"code": 4100, "locality": "BONCELLES"},
  {"code": 4100, "locality": "SERAING"},
  {"code": 4101, "locality": "JEMEPPE-SUR-MEUSE"},
  {"code": 4102, "locality": "OUGREE"},
  {"code": 4120, "locality": "EHEIN"},
  {"code": 4120, "locality": "NEUPRE"},
  {"code": 4120, "locality": "ROTHEUX-RIMIERE"},
  {"code": 4121, "locality": "NEUVILLE-EN-CONDROZ"},
  {"code": 4122, "locality": "PLAINEVAUX"},
  {"code": 4130, "locality": "ESNEUX"},
  {"code": 4130, "locality": "TILFF"},
  {"code": 4140, "locality": "DOLEMBREUX"},
  {"code": 4140, "locality": "GOMZE-ANDOUMONT"},
  {"code": 4140, "locality": "ROUVREUX"},
  {"code": 4140, "locality": "SPRIMONT"},
  {"code": 4141, "locality": "LOUVEIGNE"},
  {"code": 4160, "locality": "ANTHISNES"},
  {"code": 4161, "locality": "VILLERS-AUX-TOURS"},
  {"code": 4162, "locality": "HODY"},
  {"code": 4163, "locality": "TAVIER"},
  {"code": 4170, "locality": "COMBLAIN-AU-PONT"},
  {"code": 4171, "locality": "POULSEUR"},
  {"code": 4180, "locality": "COMBLAIN-FAIRON"},
  {"code": 4180, "locality": "COMBLAIN-LA-TOUR"},
  {"code": 4180, "locality": "HAMOIR"},
  {"code": 4181, "locality": "FILOT"},
  {"code": 4190, "locality": "FERRIERES"},
  {"code": 4190, "locality": "MY"},
  {"code": 4190, "locality": "VIEUXVILLE"},
  {"code": 4190, "locality": "WERBOMONT"},
  {"code": 4190, "locality": "XHORIS"},
  {"code": 4210, "locality": "BURDINNE"},
  {"code": 4210, "locality": "HANNECHE"},
  {"code": 4210, "locality": "LAMONTZEE"},
  {"code": 4210, "locality": "MARNEFFE"},
  {"code": 4210, "locality": "OTEPPE"},
  {"code": 4217, "locality": "HERON"},
  {"code": 4217, "locality": "LAVOIR"},
  {"code": 4217, "locality": "WARET-L'EVEQUE"},
  {"code": 4218, "locality": "COUTHUIN"},
  {"code": 4219, "locality": "ACOSSE"},
  {"code": 4219, "locality": "AMBRESIN"},
  {"code": 4219, "locality": "MEEFFE"},
  {"code": 4219, "locality": "WASSEIGES"},
  {"code": 4250, "locality": "BOELHE"},
  {"code": 4250, "locality": "GEER"},
  {"code": 4250, "locality": "HOLLOGNE-SUR-GEER"},
  {"code": 4250, "locality": "LENS-SAINT-SERVAIS"},
  {"code": 4252, "locality": "OMAL"},
  {"code": 4253, "locality": "DARION"},
  {"code": 4254, "locality": "LIGNEY"},
  {"code": 4257, "locality": "BERLOZ"},
  {"code": 4257, "locality": "CORSWAREM"},
  {"code": 4257, "locality": "ROSOUX-CRENWICK"},
  {"code": 4260, "locality": "AVENNES"},
  {"code": 4260, "locality": "BRAIVES"},
  {"code": 4260, "locality": "CIPLET"},
  {"code": 4260, "locality": "FALLAIS"},
  {"code": 4260, "locality": "FUMAL"},
  {"code": 4260, "locality": "VILLE-EN-HESBAYE"},
  {"code": 4261, "locality": "LATINNE"},
  {"code": 4263, "locality": "TOURINNE (LIEGE)"},
  {"code": 4280, "locality": "ABOLENS"},
  {"code": 4280, "locality": "AVERNAS-LE-BAUDUIN"},
  {"code": 4280, "locality": "AVIN"},
  {"code": 4280, "locality": "BERTREE"},
  {"code": 4280, "locality": "BLEHEN"},
  {"code": 4280, "locality": "CRAS-AVERNAS"},
  {"code": 4280, "locality": "CREHEN"},
  {"code": 4280, "locality": "GRAND-HALLET"},
  {"code": 4280, "locality": "HANNUT"},
  {"code": 4280, "locality": "LENS-SAINT-REMY"},
  {"code": 4280, "locality": "MERDORP"},
  {"code": 4280, "locality": "MOXHE"},
  {"code": 4280, "locality": "PETIT-HALLET"},
  {"code": 4280, "locality": "POUCET"},
  {"code": 4280, "locality": "THISNES"},
  {"code": 4280, "locality": "TROGNEE"},
  {"code": 4280, "locality": "VILLERS-LE-PEUPLIER"},
  {"code": 4280, "locality": "WANSIN"},
  {"code": 4287, "locality": "LINCENT"},
  {"code": 4287, "locality": "PELLAINES"},
  {"code": 4287, "locality": "RACOUR"},
  {"code": 4300, "locality": "BETTINCOURT"},
  {"code": 4300, "locality": "BLERET"},
  {"code": 4300, "locality": "BOVENISTIER"},
  {"code": 4300, "locality": "GRAND-AXHE"},
  {"code": 4300, "locality": "LANTREMANGE"},
  {"code": 4300, "locality": "OLEYE"},
  {"code": 4300, "locality": "WAREMME"},
  {"code": 4317, "locality": "AINEFFE"},
  {"code": 4317, "locality": "BORLEZ"},
  {"code": 4317, "locality": "CELLES (LIEGE)"},
  {"code": 4317, "locality": "FAIMES"},
  {"code": 4317, "locality": "LES WALEFFES"},
  {"code": 4317, "locality": "VIEMME"},
  {"code": 4340, "locality": "AWANS"},
  {"code": 4340, "locality": "FOOZ"},
  {"code": 4340, "locality": "OTHEE"},
  {"code": 4340, "locality": "VILLERS-L'EVEQUE"},
  {"code": 4342, "locality": "HOGNOUL"},
  {"code": 4347, "locality": "FEXHE-LE-HAUT-CLOCHER"},
  {"code": 4347, "locality": "FRELOUX"},
  {"code": 4347, "locality": "NOVILLE (LIEGE)"},
  {"code": 4347, "locality": "ROLOUX"},
  {"code": 4347, "locality": "VOROUX-GOREUX"},
  {"code": 4350, "locality": "LAMINE"},
  {"code": 4350, "locality": "MOMALLE"},
  {"code": 4350, "locality": "POUSSET"},
  {"code": 4350, "locality": "REMICOURT"},
  {"code": 4351, "locality": "HODEIGE"},
  {"code": 4357, "locality": "DONCEEL"},
  {"code": 4357, "locality": "HANEFFE"},
  {"code": 4357, "locality": "JENEFFE (LIEGE)"},
  {"code": 4357, "locality": "LIMONT"},
  {"code": 4360, "locality": "BERGILERS"},
  {"code": 4360, "locality": "GRANDVILLE"},
  {"code": 4360, "locality": "LENS-SUR-GEER"},
  {"code": 4360, "locality": "OREYE"},
  {"code": 4360, "locality": "OTRANGE"},
  {"code": 4367, "locality": "CRISNEE"},
  {"code": 4367, "locality": "FIZE-LE-MARSAL"},
  {"code": 4367, "locality": "KEMEXHE"},
  {"code": 4367, "locality": "ODEUR"},
  {"code": 4367, "locality": "THYS"},
  {"code": 4400, "locality": "AWIRS"},
  {"code": 4400, "locality": "CHOKIER"},
  {"code": 4400, "locality": "FLEMALLE"},
  {"code": 4400, "locality": "FLEMALLE-GRANDE"},
  {"code": 4400, "locality": "FLEMALLE-HAUTE"},
  {"code": 4400, "locality": "GLEIXHE"},
  {"code": 4400, "locality": "IVOZ-RAMET"},
  {"code": 4400, "locality": "MONS-LEZ-LIEGE"},
  {"code": 4420, "locality": "MONTEGNEE"},
  {"code": 4420, "locality": "SAINT-NICOLAS (LIEGE)"},
  {"code": 4420, "locality": "TILLEUR"},
  {"code": 4430, "locality": "ANS"},
  {"code": 4431, "locality": "LONCIN"},
  {"code": 4432, "locality": "ALLEUR"},
  {"code": 4432, "locality": "XHENDREMAEL"},
  {"code": 4450, "locality": "JUPRELLE"},
  {"code": 4450, "locality": "LANTIN"},
  {"code": 4450, "locality": "SLINS"},
  {"code": 4451, "locality": "VOROUX-LEZ-LIERS"},
  {"code": 4452, "locality": "PAIFVE"},
  {"code": 4452, "locality": "WIHOGNE"},
  {"code": 4453, "locality": "VILLERS-SAINT-SIMEON"},
  {"code": 4458, "locality": "FEXHE-SLINS"},
  {"code": 4460, "locality": "BIERSET"},
  {"code": 4460, "locality": "GRACE-BERLEUR"},
  {"code": 4460, "locality": "GRACE-HOLLOGNE"},
  {"code": 4460, "locality": "HOLLOGNE-AUX-PIERRES"},
  {"code": 4460, "locality": "HORION-HOZEMONT"},
  {"code": 4460, "locality": "VELROUX"},
  {"code": 4470, "locality": "SAINT-GEORGES-SUR-MEUSE"},
  {"code": 4480, "locality": "CLERMONT-SOUS-HUY"},
  {"code": 4480, "locality": "ENGIS"},
  {"code": 4480, "locality": "HERMALLE-SOUS-HUY"},
  {"code": 4500, "locality": "BEN-AHIN"},
  {"code": 4500, "locality": "HUY"},
  {"code": 4500, "locality": "TIHANGE"},
  {"code": 4520, "locality": "ANTHEIT"},
  {"code": 4520, "locality": "BAS-OHA"},
  {"code": 4520, "locality": "HUCCORGNE"},
  {"code": 4520, "locality": "MOHA"},
  {"code": 4520, "locality": "VINALMONT"},
  {"code": 4520, "locality": "WANZE"},
  {"code": 4530, "locality": "FIZE-FONTAINE"},
  {"code": 4530, "locality": "VAUX-ET-BORSET"},
  {"code": 4530, "locality": "VIEUX-WALEFFE"},
  {"code": 4530, "locality": "VILLERS-LE-BOUILLET"},
  {"code": 4530, "locality": "WARNANT-DREYE"},
  {"code": 4537, "locality": "CHAPON-SERAING"},
  {"code": 4537, "locality": "SERAING-LE-CHATEAU"},
  {"code": 4537, "locality": "VERLAINE"},
  {"code": 4540, "locality": "AMAY"},
  {"code": 4540, "locality": "AMPSIN"},
  {"code": 4540, "locality": "FLONE"},
  {"code": 4540, "locality": "JEHAY"},
  {"code": 4540, "locality": "OMBRET-RAWSA"},
  {"code": 4550, "locality": "NANDRIN"},
  {"code": 4550, "locality": "SAINT-SEVERIN"},
  {"code": 4550, "locality": "VILLERS-LE-TEMPLE"},
  {"code": 4550, "locality": "YERNEE-FRAINEUX"},
  {"code": 4557, "locality": "ABEE"},
  {"code": 4557, "locality": "FRAITURE"},
  {"code": 4557, "locality": "RAMELOT"},
  {"code": 4557, "locality": "SENY"},
  {"code": 4557, "locality": "SOHEIT-TINLOT"},
  {"code": 4557, "locality": "TINLOT"},
  {"code": 4560, "locality": "BOIS-ET-BORSU"},
  {"code": 4560, "locality": "CLAVIER"},
  {"code": 4560, "locality": "LES AVINS"},
  {"code": 4560, "locality": "OCQUIER"},
  {"code": 4560, "locality": "PAILHE"},
  {"code": 4560, "locality": "TERWAGNE"},
  {"code": 4570, "locality": "MARCHIN"},
  {"code": 4570, "locality": "VYLE-ET-THAROUL"},
  {"code": 4577, "locality": "MODAVE"},
  {"code": 4577, "locality": "OUTRELOUXHE"},
  {"code": 4577, "locality": "STREE-LEZ-HUY"},
  {"code": 4577, "locality": "VIERSET-BARSE"},
  {"code": 4590, "locality": "ELLEMELLE"},
  {"code": 4590, "locality": "OUFFET"},
  {"code": 4590, "locality": "WARZEE"},
  {"code": 4600, "locality": "LANAYE"},
  {"code": 4600, "locality": "LIXHE"},
  {"code": 4600, "locality": "RICHELLE"},
  {"code": 4600, "locality": "VISE"},
  {"code": 4601, "locality": "ARGENTEAU"},
  {"code": 4602, "locality": "CHERATTE"},
  {"code": 4606, "locality": "SAINT-ANDRE"},
  {"code": 4607, "locality": "BERNEAU"},
  {"code": 4607, "locality": "BOMBAYE"},
  {"code": 4607, "locality": "DALHEM"},
  {"code": 4607, "locality": "FENEUR"},
  {"code": 4607, "locality": "MORTROUX"},
  {"code": 4608, "locality": "NEUFCHATEAU (LIEGE)"},
  {"code": 4608, "locality": "WARSAGE"},
  {"code": 4610, "locality": "BELLAIRE"},
  {"code": 4610, "locality": "BEYNE-HEUSAY"},
  {"code": 4610, "locality": "QUEUE-DU-BOIS"},
  {"code": 4620, "locality": "FLERON"},
  {"code": 4621, "locality": "RETINNE"},
  {"code": 4623, "locality": "MAGNEE"},
  {"code": 4624, "locality": "ROMSEE"},
  {"code": 4630, "locality": "AYENEUX"},
  {"code": 4630, "locality": "MICHEROUX"},
  {"code": 4630, "locality": "SOUMAGNE"},
  {"code": 4630, "locality": "TIGNEE"},
  {"code": 4631, "locality": "EVEGNEE"},
  {"code": 4632, "locality": "CEREXHE-HEUSEUX"},
  {"code": 4633, "locality": "MELEN"},
  {"code": 4650, "locality": "CHAINEUX"},
  {"code": 4650, "locality": "GRAND-RECHAIN"},
  {"code": 4650, "locality": "HERVE"},
  {"code": 4650, "locality": "JULEMONT"},
  {"code": 4651, "locality": "BATTICE"},
  {"code": 4652, "locality": "XHENDELESSE"},
  {"code": 4653, "locality": "BOLLAND"},
  {"code": 4654, "locality": "CHARNEUX"},
  {"code": 4670, "locality": "BLEGNY"},
  {"code": 4670, "locality": "MORTIER"},
  {"code": 4670, "locality": "TREMBLEUR"},
  {"code": 4671, "locality": "BARCHON"},
  {"code": 4671, "locality": "HOUSSE"},
  {"code": 4671, "locality": "SAIVE"},
  {"code": 4672, "locality": "SAINT-REMY (LIEGE)"},
  {"code": 4680, "locality": "HERMEE"},
  {"code": 4680, "locality": "OUPEYE"},
  {"code": 4681, "locality": "HERMALLE-SOUS-ARGENTEAU"},
  {"code": 4682, "locality": "HEURE-LE-ROMAIN"},
  {"code": 4682, "locality": "HOUTAIN-SAINT-SIMEON"},
  {"code": 4683, "locality": "VIVEGNIS"},
  {"code": 4684, "locality": "HACCOURT"},
  {"code": 4690, "locality": "BASSENGE"},
  {"code": 4690, "locality": "BOIRS"},
  {"code": 4690, "locality": "EBEN-EMAEL"},
  {"code": 4690, "locality": "GLONS"},
  {"code": 4690, "locality": "ROCLENGE-SUR-GEER"},
  {"code": 4690, "locality": "WONCK"},
  {"code": 4700, "locality": "EUPEN"},
  {"code": 4701, "locality": "KETTENIS"},
  {"code": 4710, "locality": "LONTZEN"},
  {"code": 4711, "locality": "WALHORN"},
  {"code": 4720, "locality": "LA CALAMINE"},
  {"code": 4720, "locality": "LA CALAMINE"},
  {"code": 4721, "locality": "NEU-MORESNET"},
  {"code": 4728, "locality": "HERGENRATH"},
  {"code": 4730, "locality": "HAUSET"},
  {"code": 4730, "locality": "RAEREN"},
  {"code": 4731, "locality": "EYNATTEN"},
  {"code": 4750, "locality": "BUTGENBACH"},
  {"code": 4750, "locality": "ELSENBORN"},
  {"code": 4760, "locality": "BULLANGE"},
  {"code": 4760, "locality": "BULLANGE"},
  {"code": 4760, "locality": "MANDERFELD"},
  {"code": 4761, "locality": "ROCHERATH"},
  {"code": 4770, "locality": "AMBLEVE"},
  {"code": 4770, "locality": "AMBLEVE"},
  {"code": 4770, "locality": "MEYERODE"},
  {"code": 4771, "locality": "HEPPENBACH"},
  {"code": 4780, "locality": "RECHT"},
  {"code": 4780, "locality": "SAINT-VITH"},
  {"code": 4780, "locality": "SAINT-VITH"},
  {"code": 4782, "locality": "SCHOENBERG"},
  {"code": 4782, "locality": "SCHOENBERG"},
  {"code": 4783, "locality": "LOMMERSWEILER"},
  {"code": 4784, "locality": "CROMBACH"},
  {"code": 4790, "locality": "BURG-REULAND"},
  {"code": 4790, "locality": "REULAND"},
  {"code": 4791, "locality": "THOMMEN"},
  {"code": 4800, "locality": "ENSIVAL"},
  {"code": 4800, "locality": "LAMBERMONT"},
  {"code": 4800, "locality": "PETIT-RECHAIN"},
  {"code": 4800, "locality": "VERVIERS"},
  {"code": 4801, "locality": "STEMBERT"},
  {"code": 4802, "locality": "HEUSY"},
  {"code": 4820, "locality": "DISON"},
  {"code": 4821, "locality": "ANDRIMONT"},
  {"code": 4830, "locality": "LIMBOURG"},
  {"code": 4831, "locality": "BILSTAIN"},
  {"code": 4834, "locality": "GOE"},
  {"code": 4837, "locality": "BAELEN (LIEGE)"},
  {"code": 4837, "locality": "MEMBACH"},
  {"code": 4840, "locality": "WELKENRAEDT"},
  {"code": 4841, "locality": "HENRI-CHAPELLE"},
  {"code": 4845, "locality": "JALHAY"},
  {"code": 4845, "locality": "SART-LEZ-SPA"},
  {"code": 4850, "locality": "MONTZEN"},
  {"code": 4850, "locality": "MORESNET"},
  {"code": 4850, "locality": "PLOMBIERES"},
  {"code": 4851, "locality": "GEMMENICH"},
  {"code": 4851, "locality": "SIPPENAEKEN"},
  {"code": 4852, "locality": "HOMBOURG"},
  {"code": 4860, "locality": "CORNESSE"},
  {"code": 4860, "locality": "PEPINSTER"},
  {"code": 4860, "locality": "WEGNEZ"},
  {"code": 4861, "locality": "SOIRON"},
  {"code": 4870, "locality": "FORET"},
  {"code": 4870, "locality": "FRAIPONT"},
  {"code": 4870, "locality": "NESSONVAUX"},
  {"code": 4870, "locality": "TROOZ"},
  {"code": 4877, "locality": "OLNE"},
  {"code": 4880, "locality": "AUBEL"},
  {"code": 4890, "locality": "CLERMONT (LIEGE)"},
  {"code": 4890, "locality": "THIMISTER"},
  {"code": 4890, "locality": "THIMISTER-CLERMONT"},
  {"code": 4900, "locality": "SPA"},
  {"code": 4910, "locality": "LA REID"},
  {"code": 4910, "locality": "POLLEUR"},
  {"code": 4910, "locality": "THEUX"},
  {"code": 4920, "locality": "AYWAILLE"},
  {"code": 4920, "locality": "ERNONHEID"},
  {"code": 4920, "locality": "HARZE"},
  {"code": 4920, "locality": "SOUGNE-REMOUCHAMPS"},
  {"code": 4950, "locality": "FAYMONVILLE"},
  {"code": 4950, "locality": "ROBERTVILLE"},
  {"code": 4950, "locality": "SOURBRODT"},
  {"code": 4950, "locality": "WAIMES"},
  {"code": 4950, "locality": "WEISMES"},
  {"code": 4960, "locality": "BELLEVAUX-LIGNEUVILLE"},
  {"code": 4960, "locality": "BEVERCE"},
  {"code": 4960, "locality": "MALMEDY"},
  {"code": 4970, "locality": "FRANCORCHAMPS"},
  {"code": 4970, "locality": "STAVELOT"},
  {"code": 4980, "locality": "FOSSE (LIEGE)"},
  {"code": 4980, "locality": "TROIS-PONTS"},
  {"code": 4980, "locality": "WANNE"},
  {"code": 4983, "locality": "BASSE-BODEUX"},
  {"code": 4987, "locality": "CHEVRON"},
  {"code": 4987, "locality": "LA GLEIZE"},
  {"code": 4987, "locality": "LORCE"},
  {"code": 4987, "locality": "RAHIER"},
  {"code": 4987, "locality": "STOUMONT"},
  {"code": 4990, "locality": "ARBREFONTAINE"},
  {"code": 4990, "locality": "BRA"},
  {"code": 4990, "locality": "LIERNEUX"},
  {"code": 5000, "locality": "BEEZ"},
  {"code": 5000, "locality": "NAMUR"},
  {"code": 5000, "locality": "NAMUR"},
  {"code": 5001, "locality": "BELGRADE"},
  {"code": 5002, "locality": "SAINT-SERVAIS"},
  {"code": 5003, "locality": "SAINT-MARC"},
  {"code": 5004, "locality": "BOUGE"},
  {"code": 5020, "locality": "CHAMPION"},
  {"code": 5020, "locality": "DAUSSOULX"},
  {"code": 5020, "locality": "FLAWINNE"},
  {"code": 5020, "locality": "MALONNE"},
  {"code": 5020, "locality": "SUARLEE"},
  {"code": 5020, "locality": "TEMPLOUX"},
  {"code": 5020, "locality": "VEDRIN"},
  {"code": 5021, "locality": "BONINNE"},
  {"code": 5022, "locality": "COGNELEE"},
  {"code": 5024, "locality": "GELBRESSEE"},
  {"code": 5024, "locality": "MARCHE-LES-DAMES"},
  {"code": 5030, "locality": "BEUZET"},
  {"code": 5030, "locality": "ERNAGE"},
  {"code": 5030, "locality": "GEMBLOUX"},
  {"code": 5030, "locality": "GRAND-MANIL"},
  {"code": 5030, "locality": "LONZEE"},
  {"code": 5030, "locality": "SAUVENIERE"},
  {"code": 5031, "locality": "GRAND-LEEZ"},
  {"code": 5032, "locality": "BOSSIERE"},
  {"code": 5032, "locality": "BOTHEY"},
  {"code": 5032, "locality": "CORROY-LE-CHATEAU"},
  {"code": 5032, "locality": "ISNES"},
  {"code": 5032, "locality": "MAZY"},
  {"code": 5060, "locality": "ARSIMONT"},
  {"code": 5060, "locality": "AUVELAIS"},
  {"code": 5060, "locality": "FALISOLLE"},
  {"code": 5060, "locality": "KEUMIEE"},
  {"code": 5060, "locality": "MOIGNELEE"},
  {"code": 5060, "locality": "SAMBREVILLE"},
  {"code": 5060, "locality": "TAMINES"},
  {"code": 5060, "locality": "VELAINE-SUR-SAMBRE"},
  {"code": 5070, "locality": "AISEMONT"},
  {"code": 5070, "locality": "FOSSES-LA-VILLE"},
  {"code": 5070, "locality": "LE ROUX"},
  {"code": 5070, "locality": "SART-EUSTACHE"},
  {"code": 5070, "locality": "SART-SAINT-LAURENT"},
  {"code": 5070, "locality": "VITRIVAL"},
  {"code": 5080, "locality": "EMINES"},
  {"code": 5080, "locality": "LA BRUYERE"},
  {"code": 5080, "locality": "RHISNES"},
  {"code": 5080, "locality": "VILLERS-LEZ-HEEST"},
  {"code": 5080, "locality": "WARISOULX"},
  {"code": 5081, "locality": "BOVESSE"},
  {"code": 5081, "locality": "MEUX"},
  {"code": 5081, "locality": "SAINT-DENIS-BOVESSE"},
  {"code": 5100, "locality": "DAVE"},
  {"code": 5100, "locality": "JAMBES"},
  {"code": 5100, "locality": "NANINNE"},
  {"code": 5100, "locality": "WEPION"},
  {"code": 5100, "locality": "WIERDE"},
  {"code": 5101, "locality": "ERPENT"},
  {"code": 5101, "locality": "LIVES-SUR-MEUSE"},
  {"code": 5101, "locality": "LOYERS"},
  {"code": 5140, "locality": "BOIGNEE"},
  {"code": 5140, "locality": "LIGNY"},
  {"code": 5140, "locality": "SOMBREFFE"},
  {"code": 5140, "locality": "TONGRINNE"},
  {"code": 5150, "locality": "FLOREFFE"},
  {"code": 5150, "locality": "FLORIFFOUX"},
  {"code": 5150, "locality": "FRANIERE"},
  {"code": 5150, "locality": "SOYE (NAMUR)"},
  {"code": 5170, "locality": "ARBRE (NAMUR)"},
  {"code": 5170, "locality": "BOIS-DE-VILLERS"},
  {"code": 5170, "locality": "LESVE"},
  {"code": 5170, "locality": "LUSTIN"},
  {"code": 5170, "locality": "PROFONDEVILLE"},
  {"code": 5170, "locality": "RIVIERE"},
  {"code": 5190, "locality": "BALATRE"},
  {"code": 5190, "locality": "HAM-SUR-SAMBRE"},
  {"code": 5190, "locality": "JEMEPPE-SUR-SAMBRE"},
  {"code": 5190, "locality": "MORNIMONT"},
  {"code": 5190, "locality": "MOUSTIER-SUR-SAMBRE"},
  {"code": 5190, "locality": "ONOZ"},
  {"code": 5190, "locality": "SAINT-MARTIN"},
  {"code": 5190, "locality": "SPY"},
  {"code": 5300, "locality": "ANDENNE"},
  {"code": 5300, "locality": "BONNEVILLE"},
  {"code": 5300, "locality": "COUTISSE"},
  {"code": 5300, "locality": "LANDENNE"},
  {"code": 5300, "locality": "MAIZERET"},
  {"code": 5300, "locality": "NAMECHE"},
  {"code": 5300, "locality": "SCLAYN"},
  {"code": 5300, "locality": "SEILLES"},
  {"code": 5300, "locality": "THON"},
  {"code": 5300, "locality": "VEZIN"},
  {"code": 5310, "locality": "AISCHE-EN-REFAIL"},
  {"code": 5310, "locality": "BOLINNE"},
  {"code": 5310, "locality": "BONEFFE"},
  {"code": 5310, "locality": "BRANCHON"},
  {"code": 5310, "locality": "DHUY"},
  {"code": 5310, "locality": "EGHEZEE"},
  {"code": 5310, "locality": "HANRET"},
  {"code": 5310, "locality": "LEUZE (NAMUR)"},
  {"code": 5310, "locality": "LIERNU"},
  {"code": 5310, "locality": "LONGCHAMPS (NAMUR)"},
  {"code": 5310, "locality": "MEHAIGNE"},
  {"code": 5310, "locality": "NOVILLE-SUR-MEHAIGNE"},
  {"code": 5310, "locality": "SAINT-GERMAIN"},
  {"code": 5310, "locality": "TAVIERS (NAMUR)"},
  {"code": 5310, "locality": "UPIGNY"},
  {"code": 5310, "locality": "WARET-LA-CHAUSSEE"},
  {"code": 5330, "locality": "ASSESSE"},
  {"code": 5330, "locality": "MAILLEN"},
  {"code": 5330, "locality": "SART-BERNARD"},
  {"code": 5332, "locality": "CRUPET"},
  {"code": 5333, "locality": "SORINNE-LA-LONGUE"},
  {"code": 5334, "locality": "FLOREE"},
  {"code": 5336, "locality": "COURRIERE"},
  {"code": 5340, "locality": "FAULX-LES-TOMBES"},
  {"code": 5340, "locality": "GESVES"},
  {"code": 5340, "locality": "HALTINNE"},
  {"code": 5340, "locality": "MOZET"},
  {"code": 5340, "locality": "SOREE"},
  {"code": 5350, "locality": "EVELETTE"},
  {"code": 5350, "locality": "OHEY"},
  {"code": 5351, "locality": "HAILLOT"},
  {"code": 5352, "locality": "PERWEZ-HAILLOT"},
  {"code": 5353, "locality": "GOESNES"},
  {"code": 5354, "locality": "JALLET"},
  {"code": 5360, "locality": "HAMOIS"},
  {"code": 5360, "locality": "NATOYE"},
  {"code": 5361, "locality": "MOHIVILLE"},
  {"code": 5361, "locality": "SCY"},
  {"code": 5362, "locality": "ACHET"},
  {"code": 5363, "locality": "EMPTINNE"},
  {"code": 5364, "locality": "SCHALTIN"},
  {"code": 5370, "locality": "BARVAUX-CONDROZ"},
  {"code": 5370, "locality": "FLOSTOY"},
  {"code": 5370, "locality": "HAVELANGE"},
  {"code": 5370, "locality": "JENEFFE (NAMUR)"},
  {"code": 5370, "locality": "PORCHERESSE (NAMUR)"},
  {"code": 5370, "locality": "VERLEE"},
  {"code": 5372, "locality": "MEAN"},
  {"code": 5374, "locality": "MAFFE"},
  {"code": 5376, "locality": "MIECRET"},
  {"code": 5377, "locality": "BAILLONVILLE"},
  {"code": 5377, "locality": "BONSIN"},
  {"code": 5377, "locality": "HEURE (NAMUR)"},
  {"code": 5377, "locality": "HOGNE"},
  {"code": 5377, "locality": "NETTINNE"},
  {"code": 5377, "locality": "NOISEUX"},
  {"code": 5377, "locality": "SINSIN"},
  {"code": 5377, "locality": "SOMME-LEUZE"},
  {"code": 5377, "locality": "WAILLET"},
  {"code": 5380, "locality": "BIERWART"},
  {"code": 5380, "locality": "CORTIL-WODON"},
  {"code": 5380, "locality": "FERNELMONT"},
  {"code": 5380, "locality": "FORVILLE"},
  {"code": 5380, "locality": "FRANC-WARET"},
  {"code": 5380, "locality": "HEMPTINNE (FERNELMONT)"},
  {"code": 5380, "locality": "HINGEON"},
  {"code": 5380, "locality": "MARCHOVELETTE"},
  {"code": 5380, "locality": "NOVILLE-LES-BOIS"},
  {"code": 5380, "locality": "PONTILLAS"},
  {"code": 5380, "locality": "TILLIER"},
  {"code": 5500, "locality": "ANSEREMME"},
  {"code": 5500, "locality": "BOUVIGNES-SUR-MEUSE"},
  {"code": 5500, "locality": "DINANT"},
  {"code": 5500, "locality": "DREHANCE"},
  {"code": 5500, "locality": "FALMAGNE"},
  {"code": 5500, "locality": "FALMIGNOUL"},
  {"code": 5500, "locality": "FURFOOZ"},
  {"code": 5501, "locality": "LISOGNE"},
  {"code": 5502, "locality": "THYNES"},
  {"code": 5503, "locality": "SORINNES"},
  {"code": 5504, "locality": "FOY-NOTRE-DAME"},
  {"code": 5520, "locality": "ANTHEE"},
  {"code": 5520, "locality": "ONHAYE"},
  {"code": 5521, "locality": "SERVILLE"},
  {"code": 5522, "locality": "FALAEN"},
  {"code": 5523, "locality": "SOMMIERE"},
  {"code": 5523, "locality": "WEILLEN"},
  {"code": 5524, "locality": "GERIN"},
  {"code": 5530, "locality": "DORINNE"},
  {"code": 5530, "locality": "DURNAL"},
  {"code": 5530, "locality": "EVREHAILLES"},
  {"code": 5530, "locality": "GODINNE"},
  {"code": 5530, "locality": "HOUX"},
  {"code": 5530, "locality": "MONT (NAMUR)"},
  {"code": 5530, "locality": "PURNODE"},
  {"code": 5530, "locality": "SPONTIN"},
  {"code": 5530, "locality": "YVOIR"},
  {"code": 5537, "locality": "ANHEE"},
  {"code": 5537, "locality": "ANNEVOIE-ROUILLON"},
  {"code": 5537, "locality": "BIOUL"},
  {"code": 5537, "locality": "DENEE"},
  {"code": 5537, "locality": "HAUT-LE-WASTIA"},
  {"code": 5537, "locality": "SOSOYE"},
  {"code": 5537, "locality": "WARNANT"},
  {"code": 5540, "locality": "HASTIERE"},
  {"code": 5540, "locality": "HASTIERE-LAVAUX"},
  {"code": 5540, "locality": "HERMETON-SUR-MEUSE"},
  {"code": 5540, "locality": "WAULSORT"},
  {"code": 5541, "locality": "HASTIERE-PAR-DELA"},
  {"code": 5542, "locality": "BLAIMONT"},
  {"code": 5543, "locality": "HEER"},
  {"code": 5544, "locality": "AGIMONT"},
  {"code": 5550, "locality": "ALLE"},
  {"code": 5550, "locality": "BAGIMONT"},
  {"code": 5550, "locality": "BOHAN"},
  {"code": 5550, "locality": "CHAIRIERE"},
  {"code": 5550, "locality": "LAFORET"},
  {"code": 5550, "locality": "MEMBRE"},
  {"code": 5550, "locality": "MOUZAIVE"},
  {"code": 5550, "locality": "NAFRAITURE"},
  {"code": 5550, "locality": "ORCHIMONT"},
  {"code": 5550, "locality": "PUSSEMANGE"},
  {"code": 5550, "locality": "SUGNY"},
  {"code": 5550, "locality": "VRESSE-SUR-SEMOIS"},
  {"code": 5555, "locality": "BAILLAMONT"},
  {"code": 5555, "locality": "BELLEFONTAINE (NAMUR)"},
  {"code": 5555, "locality": "BIEVRE"},
  {"code": 5555, "locality": "CORNIMONT"},
  {"code": 5555, "locality": "GRAIDE"},
  {"code": 5555, "locality": "GROS-FAYS"},
  {"code": 5555, "locality": "MONCEAU-EN-ARDENNE"},
  {"code": 5555, "locality": "NAOME"},
  {"code": 5555, "locality": "OIZY"},
  {"code": 5555, "locality": "PETIT-FAYS"},
  {"code": 5560, "locality": "CIERGNON"},
  {"code": 5560, "locality": "FINNEVAUX"},
  {"code": 5560, "locality": "HOUYET"},
  {"code": 5560, "locality": "HULSONNIAUX"},
  {"code": 5560, "locality": "MESNIL-EGLISE"},
  {"code": 5560, "locality": "MESNIL-SAINT-BLAISE"},
  {"code": 5561, "locality": "CELLES (NAMUR)"},
  {"code": 5562, "locality": "CUSTINNE"},
  {"code": 5563, "locality": "HOUR"},
  {"code": 5564, "locality": "WANLIN"},
  {"code": 5570, "locality": "BARONVILLE"},
  {"code": 5570, "locality": "BEAURAING"},
  {"code": 5570, "locality": "DION"},
  {"code": 5570, "locality": "FELENNE"},
  {"code": 5570, "locality": "FESCHAUX"},
  {"code": 5570, "locality": "HONNAY"},
  {"code": 5570, "locality": "JAVINGUE"},
  {"code": 5570, "locality": "BEAURAING"},
  {"code": 5570, "locality": "VONECHE"},
  {"code": 5570, "locality": "WANCENNES"},
  {"code": 5570, "locality": "WINENNE"},
  {"code": 5571, "locality": "WIESME"},
  {"code": 5572, "locality": "FOCANT"},
  {"code": 5573, "locality": "MARTOUZIN-NEUVILLE"},
  {"code": 5574, "locality": "PONDROME"},
  {"code": 5575, "locality": "BOURSEIGNE-NEUVE"},
  {"code": 5575, "locality": "BOURSEIGNE-VIEILLE"},
  {"code": 5575, "locality": "GEDINNE"},
  {"code": 5575, "locality": "HOUDREMONT"},
  {"code": 5575, "locality": "LOUETTE-SAINT-DENIS"},
  {"code": 5575, "locality": "LOUETTE-SAINT-PIERRE"},
  {"code": 5575, "locality": "MALVOISIN"},
  {"code": 5575, "locality": "PATIGNIES"},
  {"code": 5575, "locality": "RIENNE"},
  {"code": 5575, "locality": "SART-CUSTINNE"},
  {"code": 5575, "locality": "VENCIMONT"},
  {"code": 5575, "locality": "WILLERZIE"},
  {"code": 5576, "locality": "FROIDFONTAINE"},
  {"code": 5580, "locality": "AVE-ET-AUFFE"},
  {"code": 5580, "locality": "BUISSONVILLE"},
  {"code": 5580, "locality": "EPRAVE"},
  {"code": 5580, "locality": "ROCHEFORT"},
  {"code": 5580, "locality": "HAN-SUR-LESSE"},
  {"code": 5580, "locality": "JEMELLE"},
  {"code": 5580, "locality": "ROCHEFORT"},
  {"code": 5580, "locality": "LAVAUX-SAINTE-ANNE"},
  {"code": 5580, "locality": "LESSIVE"},
  {"code": 5580, "locality": "MONT-GAUTHIER"},
  {"code": 5580, "locality": "ROCHEFORT"},
  {"code": 5580, "locality": "VILLERS-SUR-LESSE"},
  {"code": 5580, "locality": "WAVREILLE"},
  {"code": 5590, "locality": "ACHENE"},
  {"code": 5590, "locality": "CINEY"},
  {"code": 5590, "locality": "BRAIBANT"},
  {"code": 5590, "locality": "CINEY"},
  {"code": 5590, "locality": "CHEVETOGNE"},
  {"code": 5590, "locality": "CINEY"},
  {"code": 5590, "locality": "CONNEUX"},
  {"code": 5590, "locality": "HAVERSIN"},
  {"code": 5590, "locality": "HAVERSIN"},
  {"code": 5590, "locality": "LEIGNON"},
  {"code": 5590, "locality": "PESSOUX"},
  {"code": 5590, "locality": "SERINCHAMPS"},
  {"code": 5590, "locality": "SOVET"},
  {"code": 5600, "locality": "FAGNOLLE"},
  {"code": 5600, "locality": "FRANCHIMONT"},
  {"code": 5600, "locality": "JAMAGNE"},
  {"code": 5600, "locality": "JAMIOLLE"},
  {"code": 5600, "locality": "SURICE"},
  {"code": 5600, "locality": "MERLEMONT"},
  {"code": 5600, "locality": "NEUVILLE (PHILIPPEVILLE)"},
  {"code": 5600, "locality": "OMEZEE"},
  {"code": 5600, "locality": "PHILIPPEVILLE"},
  {"code": 5600, "locality": "ROLY"},
  {"code": 5600, "locality": "ROMEDENNE"},
  {"code": 5600, "locality": "SAMART"},
  {"code": 5600, "locality": "SART-EN-FAGNE"},
  {"code": 5600, "locality": "SAUTOUR"},
  {"code": 5600, "locality": "SURICE"},
  {"code": 5600, "locality": "VILLERS-EN-FAGNE"},
  {"code": 5600, "locality": "VILLERS-LE-GAMBON"},
  {"code": 5600, "locality": "VODECEE"},
  {"code": 5620, "locality": "CORENNE"},
  {"code": 5620, "locality": "FLAVION"},
  {"code": 5620, "locality": "FLORENNES"},
  {"code": 5620, "locality": "HEMPTINNE-LEZ-FLORENNES"},
  {"code": 5620, "locality": "MORVILLE"},
  {"code": 5620, "locality": "ROSEE"},
  {"code": 5620, "locality": "SAINT-AUBIN"},
  {"code": 5621, "locality": "HANZINELLE"},
  {"code": 5621, "locality": "HANZINNE"},
  {"code": 5621, "locality": "MORIALME"},
  {"code": 5621, "locality": "THY-LE-BAUDUIN"},
  {"code": 5630, "locality": "CERFONTAINE"},
  {"code": 5630, "locality": "DAUSSOIS"},
  {"code": 5630, "locality": "SENZEILLE"},
  {"code": 5630, "locality": "SILENRIEUX"},
  {"code": 5630, "locality": "SOUMOY"},
  {"code": 5630, "locality": "VILLERS-DEUX-EGLISES"},
  {"code": 5640, "locality": "BIESME"},
  {"code": 5640, "locality": "BIESMEREE"},
  {"code": 5640, "locality": "GRAUX"},
  {"code": 5640, "locality": "METTET"},
  {"code": 5640, "locality": "ORET"},
  {"code": 5640, "locality": "METTET"},
  {"code": 5640, "locality": "SAINT-GERARD"},
  {"code": 5640, "locality": "METTET"},
  {"code": 5641, "locality": "FURNAUX"},
  {"code": 5644, "locality": "ERMETON-SUR-BIERT"},
  {"code": 5646, "locality": "STAVE"},
  {"code": 5650, "locality": "CASTILLON"},
  {"code": 5650, "locality": "CHASTRES"},
  {"code": 5650, "locality": "CLERMONT (NAMUR)"},
  {"code": 5650, "locality": "FONTENELLE"},
  {"code": 5650, "locality": "FRAIRE"},
  {"code": 5650, "locality": "PRY"},
  {"code": 5650, "locality": "VOGENEE"},
  {"code": 5650, "locality": "WALCOURT"},
  {"code": 5650, "locality": "YVES-GOMEZEE"},
  {"code": 5651, "locality": "BERZEE"},
  {"code": 5651, "locality": "GOURDINNE"},
  {"code": 5651, "locality": "LANEFFE"},
  {"code": 5651, "locality": "ROGNEE"},
  {"code": 5651, "locality": "SOMZEE"},
  {"code": 5651, "locality": "TARCIENNE"},
  {"code": 5651, "locality": "THY-LE-CHATEAU"},
  {"code": 5660, "locality": "AUBLAIN"},
  {"code": 5660, "locality": "BOUSSU-EN-FAGNE"},
  {"code": 5660, "locality": "BRULY"},
  {"code": 5660, "locality": "BRULY-DE-PESCHE"},
  {"code": 5660, "locality": "COUVIN"},
  {"code": 5660, "locality": "CUL-DES-SARTS"},
  {"code": 5660, "locality": "DAILLY"},
  {"code": 5660, "locality": "FRASNES-LEZ-COUVIN"},
  {"code": 5660, "locality": "GONRIEUX"},
  {"code": 5660, "locality": "MARIEMBOURG"},
  {"code": 5660, "locality": "PESCHE"},
  {"code": 5660, "locality": "PETIGNY"},
  {"code": 5660, "locality": "PETITE-CHAPELLE"},
  {"code": 5660, "locality": "PRESGAUX"},
  {"code": 5670, "locality": "DOURBES"},
  {"code": 5670, "locality": "LE MESNIL"},
  {"code": 5670, "locality": "MAZEE"},
  {"code": 5670, "locality": "NISMES"},
  {"code": 5670, "locality": "OIGNIES-EN-THIERACHE"},
  {"code": 5670, "locality": "OLLOY-SUR-VIROIN"},
  {"code": 5670, "locality": "TREIGNES"},
  {"code": 5670, "locality": "VIERVES-SUR-VIROIN"},
  {"code": 5670, "locality": "VIROINVAL"},
  {"code": 5680, "locality": "DOISCHE"},
  {"code": 5680, "locality": "GIMNEE"},
  {"code": 5680, "locality": "GOCHENEE"},
  {"code": 5680, "locality": "MATAGNE-LA-GRANDE"},
  {"code": 5680, "locality": "MATAGNE-LA-PETITE"},
  {"code": 5680, "locality": "NIVERLEE"},
  {"code": 5680, "locality": "ROMEREE"},
  {"code": 5680, "locality": "SOULME"},
  {"code": 5680, "locality": "VAUCELLES"},
  {"code": 5680, "locality": "VODELEE"},
  {"code": 6000, "locality": "CHARLEROI"},
  {"code": 6001, "locality": "MARCINELLE"},
  {"code": 6010, "locality": "COUILLET"},
  {"code": 6020, "locality": "DAMPREMY"},
  {"code": 6030, "locality": "GOUTROUX"},
  {"code": 6030, "locality": "MARCHIENNE-AU-PONT"},
  {"code": 6031, "locality": "MONCEAU-SUR-SAMBRE"},
  {"code": 6032, "locality": "MONT-SUR-MARCHIENNE"},
  {"code": 6040, "locality": "JUMET (CHARLEROI)"},
  {"code": 6041, "locality": "GOSSELIES"},
  {"code": 6042, "locality": "LODELINSART"},
  {"code": 6043, "locality": "RANSART"},
  {"code": 6044, "locality": "ROUX"},
  {"code": 6060, "locality": "GILLY (CHARLEROI)"},
  {"code": 6061, "locality": "MONTIGNIES-SUR-SAMBRE"},
  {"code": 6110, "locality": "MONTIGNY-LE-TILLEUL"},
  {"code": 6111, "locality": "LANDELIES"},
  {"code": 6120, "locality": "COUR-SUR-HEURE"},
  {"code": 6120, "locality": "HAM-SUR-HEURE"},
  {"code": 6120, "locality": "HAM-SUR-HEURE-NALINNES"},
  {"code": 6120, "locality": "JAMIOULX"},
  {"code": 6120, "locality": "MARBAIX (HAINAUT)"},
  {"code": 6120, "locality": "NALINNES"},
  {"code": 6140, "locality": "FONTAINE-L'EVEQUE"},
  {"code": 6141, "locality": "FORCHIES-LA-MARCHE"},
  {"code": 6142, "locality": "LEERNES"},
  {"code": 6150, "locality": "ANDERLUES"},
  {"code": 6180, "locality": "COURCELLES"},
  {"code": 6181, "locality": "GOUY-LEZ-PIETON"},
  {"code": 6182, "locality": "SOUVRET"},
  {"code": 6183, "locality": "TRAZEGNIES"},
  {"code": 6200, "locality": "BOUFFIOULX"},
  {"code": 6200, "locality": "CHATELET"},
  {"code": 6200, "locality": "CHATELINEAU"},
  {"code": 6210, "locality": "FRASNES-LEZ-GOSSELIES"},
  {"code": 6210, "locality": "LES BONS VILLERS"},
  {"code": 6210, "locality": "REVES"},
  {"code": 6210, "locality": "VILLERS-PERWIN"},
  {"code": 6210, "locality": "WAYAUX"},
  {"code": 6211, "locality": "MELLET"},
  {"code": 6220, "locality": "FLEURUS"},
  {"code": 6220, "locality": "HEPPIGNIES"},
  {"code": 6220, "locality": "LAMBUSART"},
  {"code": 6220, "locality": "WANGENIES"},
  {"code": 6221, "locality": "SAINT-AMAND"},
  {"code": 6222, "locality": "BRYE"},
  {"code": 6223, "locality": "WAGNELEE"},
  {"code": 6224, "locality": "WANFERCEE-BAULET"},
  {"code": 6230, "locality": "BUZET"},
  {"code": 6230, "locality": "OBAIX"},
  {"code": 6230, "locality": "PONT-A-CELLES"},
  {"code": 6230, "locality": "THIMEON"},
  {"code": 6230, "locality": "VIESVILLE"},
  {"code": 6238, "locality": "LIBERCHIES"},
  {"code": 6238, "locality": "LUTTRE"},
  {"code": 6240, "locality": "FARCIENNES"},
  {"code": 6240, "locality": "PIRONCHAMPS"},
  {"code": 6250, "locality": "AISEAU"},
  {"code": 6250, "locality": "AISEAU-PRESLES"},
  {"code": 6250, "locality": "PONT-DE-LOUP"},
  {"code": 6250, "locality": "PRESLES"},
  {"code": 6250, "locality": "ROSELIES"},
  {"code": 6280, "locality": "ACOZ"},
  {"code": 6280, "locality": "GERPINNES"},
  {"code": 6280, "locality": "GOUGNIES"},
  {"code": 6280, "locality": "JONCRET"},
  {"code": 6280, "locality": "ACOZ"},
  {"code": 6280, "locality": "LOVERVAL"},
  {"code": 6280, "locality": "VILLERS-POTERIE"},
  {"code": 6440, "locality": "BOUSSU-LEZ-WALCOURT"},
  {"code": 6440, "locality": "FOURBECHIES"},
  {"code": 6440, "locality": "FROIDCHAPELLE"},
  {"code": 6440, "locality": "VERGNIES"},
  {"code": 6441, "locality": "ERPION"},
  {"code": 6460, "locality": "BAILIEVRE"},
  {"code": 6460, "locality": "CHIMAY"},
  {"code": 6460, "locality": "ROBECHIES"},
  {"code": 6460, "locality": "SAINT-REMY (HAINAUT)"},
  {"code": 6460, "locality": "SALLES"},
  {"code": 6460, "locality": "VILLERS-LA-TOUR"},
  {"code": 6461, "locality": "VIRELLES"},
  {"code": 6462, "locality": "VAULX-LEZ-CHIMAY"},
  {"code": 6463, "locality": "LOMPRET"},
  {"code": 6464, "locality": "BAILEUX"},
  {"code": 6464, "locality": "BOURLERS"},
  {"code": 6464, "locality": "FORGES"},
  {"code": 6464, "locality": "L'ESCAILLERE"},
  {"code": 6464, "locality": "RIEZES"},
  {"code": 6470, "locality": "GRANDRIEU"},
  {"code": 6470, "locality": "MONTBLIART"},
  {"code": 6470, "locality": "RANCE"},
  {"code": 6470, "locality": "SAUTIN"},
  {"code": 6470, "locality": "SIVRY"},
  {"code": 6470, "locality": "SIVRY-RANCE"},
  {"code": 6500, "locality": "BARBENCON"},
  {"code": 6500, "locality": "BEAUMONT"},
  {"code": 6500, "locality": "LEUGNIES"},
  {"code": 6500, "locality": "LEVAL-CHAUDEVILLE"},
  {"code": 6500, "locality": "RENLIES"},
  {"code": 6500, "locality": "SOLRE-SAINT-GERY"},
  {"code": 6500, "locality": "THIRIMONT"},
  {"code": 6511, "locality": "STREE (HAINAUT)"},
  {"code": 6530, "locality": "LEERS-ET-FOSTEAU"},
  {"code": 6530, "locality": "THUIN"},
  {"code": 6531, "locality": "BIESME-SOUS-THUIN"},
  {"code": 6532, "locality": "RAGNIES"},
  {"code": 6533, "locality": "BIERCEE"},
  {"code": 6534, "locality": "GOZEE"},
  {"code": 6536, "locality": "DONSTIENNES"},
  {"code": 6536, "locality": "THUILLIES"},
  {"code": 6540, "locality": "LOBBES"},
  {"code": 6540, "locality": "MONT-SAINTE-GENEVIEVE"},
  {"code": 6542, "locality": "SARS-LA-BUISSIERE"},
  {"code": 6543, "locality": "BIENNE-LEZ-HAPPART"},
  {"code": 6560, "locality": "BERSILLIES-L'ABBAYE"},
  {"code": 6560, "locality": "ERQUELINNES"},
  {"code": 6560, "locality": "GRAND-RENG"},
  {"code": 6560, "locality": "HANTES-WIHERIES"},
  {"code": 6560, "locality": "MONTIGNIES-SAINT-CHRISTOPHE"},
  {"code": 6560, "locality": "SOLRE-SUR-SAMBRE"},
  {"code": 6567, "locality": "FONTAINE-VALMONT"},
  {"code": 6567, "locality": "LABUISSIERE"},
  {"code": 6567, "locality": "MERBES-LE-CHATEAU"},
  {"code": 6567, "locality": "MERBES-SAINTE-MARIE"},
  {"code": 6590, "locality": "MOMIGNIES"},
  {"code": 6591, "locality": "MACON"},
  {"code": 6592, "locality": "MONCEAU-IMBRECHIES"},
  {"code": 6593, "locality": "MACQUENOISE"},
  {"code": 6594, "locality": "BEAUWELZ"},
  {"code": 6596, "locality": "FORGE-PHILIPPE"},
  {"code": 6596, "locality": "SELOIGNES"},
  {"code": 6600, "locality": "BASTOGNE"},
  {"code": 6600, "locality": "LONGVILLY"},
  {"code": 6600, "locality": "NOVILLE (LUXEMBOURG)"},
  {"code": 6600, "locality": "VILLERS-LA-BONNE-EAU"},
  {"code": 6600, "locality": "WARDIN"},
  {"code": 6630, "locality": "MARTELANGE"},
  {"code": 6637, "locality": "FAUVILLERS"},
  {"code": 6637, "locality": "HOLLANGE"},
  {"code": 6637, "locality": "TINTANGE"},
  {"code": 6640, "locality": "HOMPRE"},
  {"code": 6640, "locality": "MORHET"},
  {"code": 6640, "locality": "NIVES"},
  {"code": 6640, "locality": "SIBRET"},
  {"code": 6640, "locality": "VAUX-LEZ-ROSIERES"},
  {"code": 6640, "locality": "VAUX-SUR-SURE"},
  {"code": 6642, "locality": "JUSERET"},
  {"code": 6660, "locality": "HOUFFALIZE"},
  {"code": 6660, "locality": "NADRIN"},
  {"code": 6661, "locality": "MONT (LUXEMBOURG)"},
  {"code": 6661, "locality": "TAILLES"},
  {"code": 6662, "locality": "TAVIGNY"},
  {"code": 6663, "locality": "MABOMPRE"},
  {"code": 6666, "locality": "WIBRIN"},
  {"code": 6670, "locality": "GOUVY"},
  {"code": 6670, "locality": "LIMERLE"},
  {"code": 6671, "locality": "BOVIGNY"},
  {"code": 6672, "locality": "BEHO"},
  {"code": 6673, "locality": "CHERAIN"},
  {"code": 6674, "locality": "MONTLEBAN"},
  {"code": 6680, "locality": "AMBERLOUP"},
  {"code": 6680, "locality": "SAINTE-ODE"},
  {"code": 6680, "locality": "TILLET"},
  {"code": 6681, "locality": "LAVACHERIE"},
  {"code": 6686, "locality": "FLAMIERGE"},
  {"code": 6687, "locality": "BERTOGNE"},
  {"code": 6688, "locality": "LONGCHAMPS (LUXEMBOURG)"},
  {"code": 6690, "locality": "BIHAIN"},
  {"code": 6690, "locality": "VIELSALM"},
  {"code": 6692, "locality": "PETIT-THIER"},
  {"code": 6698, "locality": "GRAND-HALLEUX"},
  {"code": 6700, "locality": "ARLON"},
  {"code": 6700, "locality": "BONNERT"},
  {"code": 6700, "locality": "HEINSCH"},
  {"code": 6700, "locality": "TOERNICH"},
  {"code": 6704, "locality": "GUIRSCH"},
  {"code": 6706, "locality": "AUTELBAS"},
  {"code": 6717, "locality": "ATTERT"},
  {"code": 6717, "locality": "NOBRESSART"},
  {"code": 6717, "locality": "NOTHOMB"},
  {"code": 6717, "locality": "THIAUMONT"},
  {"code": 6717, "locality": "TONTELANGE"},
  {"code": 6720, "locality": "HABAY"},
  {"code": 6720, "locality": "HABAY-LA-NEUVE"},
  {"code": 6720, "locality": "HACHY"},
  {"code": 6721, "locality": "ANLIER"},
  {"code": 6723, "locality": "HABAY-LA-VIEILLE"},
  {"code": 6724, "locality": "HOUDEMONT"},
  {"code": 6724, "locality": "MARBEHAN"},
  {"code": 6724, "locality": "RULLES"},
  {"code": 6730, "locality": "BELLEFONTAINE (LUXEMBOURG)"},
  {"code": 6730, "locality": "ROSSIGNOL"},
  {"code": 6730, "locality": "SAINT-VINCENT"},
  {"code": 6730, "locality": "TINTIGNY"},
  {"code": 6740, "locality": "ETALLE"},
  {"code": 6740, "locality": "SAINTE-MARIE-SUR-SEMOIS"},
  {"code": 6740, "locality": "VILLERS-SUR-SEMOIS"},
  {"code": 6741, "locality": "VANCE"},
  {"code": 6742, "locality": "CHANTEMELLE"},
  {"code": 6743, "locality": "BUZENOL"},
  {"code": 6747, "locality": "CHATILLON"},
  {"code": 6747, "locality": "MEIX-LE-TIGE"},
  {"code": 6747, "locality": "SAINT-LEGER (LUXEMBOURG)"},
  {"code": 6750, "locality": "MUSSON"},
  {"code": 6750, "locality": "MUSSY-LA-VILLE"},
  {"code": 6750, "locality": "SIGNEULX"},
  {"code": 6760, "locality": "BLEID"},
  {"code": 6760, "locality": "ETHE"},
  {"code": 6760, "locality": "RUETTE"},
  {"code": 6760, "locality": "VIRTON"},
  {"code": 6761, "locality": "LATOUR"},
  {"code": 6762, "locality": "SAINT-MARD"},
  {"code": 6767, "locality": "DAMPICOURT"},
  {"code": 6767, "locality": "HARNONCOURT"},
  {"code": 6767, "locality": "LAMORTEAU"},
  {"code": 6767, "locality": "ROUVROY"},
  {"code": 6767, "locality": "TORGNY"},
  {"code": 6769, "locality": "GEROUVILLE"},
  {"code": 6769, "locality": "MEIX-DEVANT-VIRTON"},
  {"code": 6769, "locality": "ROBELMONT"},
  {"code": 6769, "locality": "SOMMETHONNE"},
  {"code": 6769, "locality": "VILLERS-LA-LOUE"},
  {"code": 6780, "locality": "HONDELANGE"},
  {"code": 6780, "locality": "MESSANCY"},
  {"code": 6780, "locality": "WOLKRANGE"},
  {"code": 6781, "locality": "SELANGE"},
  {"code": 6782, "locality": "HABERGY"},
  {"code": 6790, "locality": "AUBANGE"},
  {"code": 6791, "locality": "ATHUS"},
  {"code": 6792, "locality": "HALANZY"},
  {"code": 6792, "locality": "RACHECOURT"},
  {"code": 6800, "locality": "BRAS"},
  {"code": 6800, "locality": "FREUX"},
  {"code": 6800, "locality": "LIBRAMONT-CHEVIGNY"},
  {"code": 6800, "locality": "MOIRCY"},
  {"code": 6800, "locality": "RECOGNE"},
  {"code": 6800, "locality": "REMAGNE"},
  {"code": 6800, "locality": "SAINT-PIERRE"},
  {"code": 6800, "locality": "SAINTE-MARIE-CHEVIGNY"},
  {"code": 6810, "locality": "CHINY"},
  {"code": 6810, "locality": "IZEL"},
  {"code": 6810, "locality": "JAMOIGNE"},
  {"code": 6811, "locality": "LES BULLES"},
  {"code": 6812, "locality": "SUXY"},
  {"code": 6813, "locality": "TERMES"},
  {"code": 6820, "locality": "FLORENVILLE"},
  {"code": 6820, "locality": "FONTENOILLE"},
  {"code": 6820, "locality": "MUNO"},
  {"code": 6820, "locality": "SAINTE-CECILE"},
  {"code": 6821, "locality": "LACUISINE"},
  {"code": 6823, "locality": "VILLERS-DEVANT-ORVAL"},
  {"code": 6824, "locality": "CHASSEPIERRE"},
  {"code": 6830, "locality": "BOUILLON"},
  {"code": 6830, "locality": "LES HAYONS"},
  {"code": 6830, "locality": "POUPEHAN"},
  {"code": 6830, "locality": "ROCHEHAUT"},
  {"code": 6831, "locality": "NOIREFONTAINE"},
  {"code": 6832, "locality": "SENSENRUTH"},
  {"code": 6833, "locality": "UCIMONT"},
  {"code": 6833, "locality": "VIVY"},
  {"code": 6834, "locality": "BELLEVAUX"},
  {"code": 6836, "locality": "DOHAN"},
  {"code": 6838, "locality": "CORBION"},
  {"code": 6840, "locality": "GRANDVOIR"},
  {"code": 6840, "locality": "GRAPFONTAINE"},
  {"code": 6840, "locality": "HAMIPRE"},
  {"code": 6840, "locality": "LONGLIER"},
  {"code": 6840, "locality": "NEUFCHATEAU"},
  {"code": 6840, "locality": "TOURNAY"},
  {"code": 6850, "locality": "CARLSBOURG"},
  {"code": 6850, "locality": "OFFAGNE"},
  {"code": 6850, "locality": "PALISEUL"},
  {"code": 6851, "locality": "NOLLEVAUX"},
  {"code": 6852, "locality": "MAISSIN"},
  {"code": 6852, "locality": "OPONT"},
  {"code": 6853, "locality": "FRAMONT"},
  {"code": 6856, "locality": "FAYS-LES-VENEURS"},
  {"code": 6860, "locality": "ASSENOIS"},
  {"code": 6860, "locality": "EBLY"},
  {"code": 6860, "locality": "LEGLISE"},
  {"code": 6860, "locality": "MELLIER"},
  {"code": 6860, "locality": "WITRY"},
  {"code": 6870, "locality": "ARVILLE"},
  {"code": 6870, "locality": "AWENNE"},
  {"code": 6870, "locality": "HATRIVAL"},
  {"code": 6870, "locality": "MIRWART"},
  {"code": 6870, "locality": "SAINT-HUBERT"},
  {"code": 6870, "locality": "VESQUEVILLE"},
  {"code": 6880, "locality": "AUBY-SUR-SEMOIS"},
  {"code": 6880, "locality": "BERTRIX"},
  {"code": 6880, "locality": "CUGNON"},
  {"code": 6880, "locality": "JEHONVILLE"},
  {"code": 6880, "locality": "ORGEO"},
  {"code": 6887, "locality": "HERBEUMONT"},
  {"code": 6887, "locality": "SAINT-MEDARD"},
  {"code": 6887, "locality": "STRAIMONT"},
  {"code": 6890, "locality": "ANLOY"},
  {"code": 6890, "locality": "LIBIN"},
  {"code": 6890, "locality": "OCHAMPS"},
  {"code": 6890, "locality": "REDU"},
  {"code": 6890, "locality": "SMUID"},
  {"code": 6890, "locality": "TRANSINNE"},
  {"code": 6890, "locality": "VILLANCE"},
  {"code": 6900, "locality": "AYE"},
  {"code": 6900, "locality": "HARGIMONT"},
  {"code": 6900, "locality": "HUMAIN"},
  {"code": 6900, "locality": "MARCHE-EN-FAMENNE"},
  {"code": 6900, "locality": "MARCHE-EN-FAMENNE"},
  {"code": 6900, "locality": "MARCHE-EN-FAMENNE"},
  {"code": 6900, "locality": "ON"},
  {"code": 6900, "locality": "ROY"},
  {"code": 6900, "locality": "WAHA"},
  {"code": 6920, "locality": "SOHIER"},
  {"code": 6920, "locality": "WELLIN"},
  {"code": 6921, "locality": "CHANLY"},
  {"code": 6922, "locality": "HALMA"},
  {"code": 6924, "locality": "LOMPREZ"},
  {"code": 6927, "locality": "BURE"},
  {"code": 6927, "locality": "GRUPONT"},
  {"code": 6927, "locality": "RESTEIGNE"},
  {"code": 6927, "locality": "TELLIN"},
  {"code": 6929, "locality": "DAVERDISSE"},
  {"code": 6929, "locality": "GEMBES"},
  {"code": 6929, "locality": "HAUT-FAYS"},
  {"code": 6929, "locality": "PORCHERESSE (LUXEMBOURG)"},
  {"code": 6940, "locality": "BARVAUX-SUR-OURTHE"},
  {"code": 6940, "locality": "DURBUY"},
  {"code": 6940, "locality": "GRANDHAN"},
  {"code": 6940, "locality": "SEPTON"},
  {"code": 6940, "locality": "WERIS"},
  {"code": 6941, "locality": "BENDE"},
  {"code": 6941, "locality": "BOMAL-SUR-OURTHE"},
  {"code": 6941, "locality": "BORLON"},
  {"code": 6941, "locality": "HEYD"},
  {"code": 6941, "locality": "IZIER"},
  {"code": 6941, "locality": "TOHOGNE"},
  {"code": 6941, "locality": "VILLERS-SAINTE-GERTRUDE"},
  {"code": 6950, "locality": "HARSIN"},
  {"code": 6950, "locality": "NASSOGNE"},
  {"code": 6951, "locality": "BANDE"},
  {"code": 6952, "locality": "GRUNE"},
  {"code": 6953, "locality": "AMBLY"},
  {"code": 6953, "locality": "FORRIERES"},
  {"code": 6953, "locality": "LESTERNY"},
  {"code": 6953, "locality": "MASBOURG"},
  {"code": 6960, "locality": "DOCHAMPS"},
  {"code": 6960, "locality": "GRANDMENIL"},
  {"code": 6960, "locality": "HARRE"},
  {"code": 6960, "locality": "MALEMPRE"},
  {"code": 6960, "locality": "MANHAY"},
  {"code": 6960, "locality": "ODEIGNE"},
  {"code": 6960, "locality": "VAUX-CHAVANNE"},
  {"code": 6970, "locality": "TENNEVILLE"},
  {"code": 6971, "locality": "CHAMPLON"},
  {"code": 6972, "locality": "ERNEUVILLE"},
  {"code": 6980, "locality": "BEAUSAINT"},
  {"code": 6980, "locality": "LA ROCHE-EN-ARDENNE"},
  {"code": 6982, "locality": "SAMREE"},
  {"code": 6983, "locality": "ORTHO"},
  {"code": 6984, "locality": "HIVES"},
  {"code": 6986, "locality": "HALLEUX"},
  {"code": 6987, "locality": "BEFFE"},
  {"code": 6987, "locality": "HODISTER"},
  {"code": 6987, "locality": "MARCOURT"},
  {"code": 6987, "locality": "RENDEUX"},
  {"code": 6990, "locality": "FRONVILLE"},
  {"code": 6990, "locality": "HAMPTEAU"},
  {"code": 6990, "locality": "HOTTON"},
  {"code": 6990, "locality": "MARENNE"},
  {"code": 6997, "locality": "AMONINES"},
  {"code": 6997, "locality": "EREZEE"},
  {"code": 6997, "locality": "MORMONT"},
  {"code": 6997, "locality": "SOY"},
  {"code": 7000, "locality": "MONS"},
  {"code": 7011, "locality": "GHLIN"},
  {"code": 7012, "locality": "FLENU"},
  {"code": 7012, "locality": "JEMAPPES"},
  {"code": 7020, "locality": "MAISIERES"},
  {"code": 7020, "locality": "MAISIERES"},
  {"code": 7020, "locality": "NIMY"},
  {"code": 7021, "locality": "HAVRE"},
  {"code": 7022, "locality": "HARMIGNIES"},
  {"code": 7022, "locality": "HARVENG"},
  {"code": 7022, "locality": "HYON"},
  {"code": 7022, "locality": "MESVIN"},
  {"code": 7022, "locality": "NOUVELLES"},
  {"code": 7024, "locality": "CIPLY"},
  {"code": 7030, "locality": "SAINT-SYMPHORIEN"},
  {"code": 7031, "locality": "VILLERS-SAINT-GHISLAIN"},
  {"code": 7032, "locality": "SPIENNES"},
  {"code": 7033, "locality": "CUESMES"},
  {"code": 7034, "locality": "OBOURG"},
  {"code": 7034, "locality": "SAINT-DENIS (HAINAUT)"},
  {"code": 7040, "locality": "ASQUILLIES"},
  {"code": 7040, "locality": "AULNOIS"},
  {"code": 7040, "locality": "BLAREGNIES"},
  {"code": 7040, "locality": "BOUGNIES"},
  {"code": 7040, "locality": "GENLY"},
  {"code": 7040, "locality": "GOEGNIES-CHAUSSEE"},
  {"code": 7040, "locality": "QUEVY"},
  {"code": 7040, "locality": "QUEVY-LE-GRAND"},
  {"code": 7040, "locality": "QUEVY-LE-PETIT"},
  {"code": 7041, "locality": "GIVRY"},
  {"code": 7041, "locality": "HAVAY"},
  {"code": 7050, "locality": "ERBAUT"},
  {"code": 7050, "locality": "ERBISOEUL"},
  {"code": 7050, "locality": "HERCHIES"},
  {"code": 7050, "locality": "JURBISE"},
  {"code": 7050, "locality": "MASNUY-SAINT-PIERRE"},
  {"code": 7050, "locality": "MASNUY-SAINT-JEAN (JURBISE)"},
  {"code": 7060, "locality": "HORRUES"},
  {"code": 7060, "locality": "SOIGNIES"},
  {"code": 7061, "locality": "CASTEAU (SOIGNIES)"},
  {"code": 7061, "locality": "THIEUSIES"},
  {"code": 7062, "locality": "NAAST"},
  {"code": 7063, "locality": "CHAUSSEE-NOTRE-DAME-LOUVIGNIES"},
  {"code": 7063, "locality": "NEUFVILLES"},
  {"code": 7070, "locality": "GOTTIGNIES"},
  {"code": 7070, "locality": "LE ROEULX"},
  {"code": 7070, "locality": "MIGNAULT"},
  {"code": 7070, "locality": "THIEU"},
  {"code": 7070, "locality": "VILLE-SUR-HAINE (LE ROEULX)"},
  {"code": 7080, "locality": "EUGIES (FRAMERIES)"},
  {"code": 7080, "locality": "FRAMERIES"},
  {"code": 7080, "locality": "LA BOUVERIE"},
  {"code": 7080, "locality": "NOIRCHAIN"},
  {"code": 7080, "locality": "SARS-LA-BRUYERE"},
  {"code": 7090, "locality": "BRAINE-LE-COMTE"},
  {"code": 7090, "locality": "HENNUYERES"},
  {"code": 7090, "locality": "HENRIPONT"},
  {"code": 7090, "locality": "PETIT-ROEULX-LEZ-BRAINE"},
  {"code": 7090, "locality": "RONQUIERES"},
  {"code": 7090, "locality": "STEENKERQUE (HAINAUT)"},
  {"code": 7100, "locality": "HAINE-SAINT-PAUL"},
  {"code": 7100, "locality": "HAINE-SAINT-PIERRE"},
  {"code": 7100, "locality": "LA LOUVIERE"},
  {"code": 7100, "locality": "SAINT-VAAST"},
  {"code": 7100, "locality": "TRIVIERES"},
  {"code": 7110, "locality": "BOUSSOIT"},
  {"code": 7110, "locality": "HOUDENG-AIMERIES"},
  {"code": 7110, "locality": "HOUDENG-GOEGNIES (LA LOUVIERE)"},
  {"code": 7110, "locality": "MAURAGE"},
  {"code": 7110, "locality": "STREPY-BRACQUEGNIES"},
  {"code": 7120, "locality": "CROIX-LEZ-ROUVEROY"},
  {"code": 7120, "locality": "ESTINNES"},
  {"code": 7120, "locality": "ESTINNES-AU-MONT"},
  {"code": 7120, "locality": "ESTINNES-AU-VAL"},
  {"code": 7120, "locality": "FAUROEULX"},
  {"code": 7120, "locality": "HAULCHIN"},
  {"code": 7120, "locality": "PEISSANT"},
  {"code": 7120, "locality": "ROUVEROY (HAINAUT)"},
  {"code": 7120, "locality": "VELLEREILLE-LE-SEC"},
  {"code": 7120, "locality": "VELLEREILLE-LES-BRAYEUX"},
  {"code": 7130, "locality": "BATTIGNIES"},
  {"code": 7130, "locality": "BINCHE"},
  {"code": 7130, "locality": "BRAY"},
  {"code": 7131, "locality": "WAUDREZ"},
  {"code": 7133, "locality": "BUVRINNES"},
  {"code": 7134, "locality": "EPINOIS"},
  {"code": 7134, "locality": "LEVAL-TRAHEGNIES"},
  {"code": 7134, "locality": "PERONNES-LEZ-BINCHE"},
  {"code": 7134, "locality": "RESSAIX"},
  {"code": 7140, "locality": "MORLANWELZ"},
  {"code": 7140, "locality": "MORLANWELZ-MARIEMONT"},
  {"code": 7141, "locality": "CARNIERES"},
  {"code": 7141, "locality": "MONT-SAINTE-ALDEGONDE"},
  {"code": 7160, "locality": "CHAPELLE-LEZ-HERLAIMONT"},
  {"code": 7160, "locality": "GODARVILLE"},
  {"code": 7160, "locality": "PIETON"},
  {"code": 7170, "locality": "BELLECOURT"},
  {"code": 7170, "locality": "BOIS-D'HAINE"},
  {"code": 7170, "locality": "FAYT-LEZ-MANAGE"},
  {"code": 7170, "locality": "LA HESTRE"},
  {"code": 7170, "locality": "MANAGE"},
  {"code": 7180, "locality": "SENEFFE"},
  {"code": 7181, "locality": "ARQUENNES"},
  {"code": 7181, "locality": "FAMILLEUREUX"},
  {"code": 7181, "locality": "FELUY"},
  {"code": 7181, "locality": "PETIT-ROEULX-LEZ-NIVELLES"},
  {"code": 7190, "locality": "ECAUSSINNES"},
  {"code": 7190, "locality": "ECAUSSINNES-D'ENGHIEN"},
  {"code": 7190, "locality": "MARCHE-LEZ-ECAUSSINNES"},
  {"code": 7191, "locality": "ECAUSSINNES-LALAING"},
  {"code": 7300, "locality": "BOUSSU"},
  {"code": 7301, "locality": "HORNU"},
  {"code": 7320, "locality": "BERNISSART"},
  {"code": 7321, "locality": "BLATON"},
  {"code": 7321, "locality": "HARCHIES"},
  {"code": 7322, "locality": "POMMEROEUL"},
  {"code": 7322, "locality": "VILLE-POMMEROEUL"},
  {"code": 7330, "locality": "SAINT-GHISLAIN"},
  {"code": 7331, "locality": "BAUDOUR"},
  {"code": 7332, "locality": "NEUFMAISON"},
  {"code": 7332, "locality": "SIRAULT"},
  {"code": 7333, "locality": "TERTRE"},
  {"code": 7334, "locality": "HAUTRAGE"},
  {"code": 7334, "locality": "VILLEROT"},
  {"code": 7340, "locality": "COLFONTAINE"},
  {"code": 7340, "locality": "PATURAGES"},
  {"code": 7340, "locality": "WARQUIGNIES"},
  {"code": 7340, "locality": "WASMES"},
  {"code": 7350, "locality": "HAININ"},
  {"code": 7350, "locality": "HENSIES"},
  {"code": 7350, "locality": "MONTROEUL-SUR-HAINE"},
  {"code": 7350, "locality": "THULIN"},
  {"code": 7370, "locality": "BLAUGIES"},
  {"code": 7370, "locality": "DOUR"},
  {"code": 7370, "locality": "ELOUGES"},
  {"code": 7370, "locality": "WIHERIES"},
  {"code": 7380, "locality": "BAISIEUX"},
  {"code": 7380, "locality": "QUIEVRAIN"},
  {"code": 7382, "locality": "AUDREGNIES"},
  {"code": 7387, "locality": "ANGRE"},
  {"code": 7387, "locality": "ANGREAU"},
  {"code": 7387, "locality": "ATHIS"},
  {"code": 7387, "locality": "AUTREPPE"},
  {"code": 7387, "locality": "ERQUENNES"},
  {"code": 7387, "locality": "FAYT-LE-FRANC"},
  {"code": 7387, "locality": "HONNELLES"},
  {"code": 7387, "locality": "MARCHIPONT"},
  {"code": 7387, "locality": "MONTIGNIES-SUR-ROC"},
  {"code": 7387, "locality": "ONNEZIES"},
  {"code": 7387, "locality": "ROISIN"},
  {"code": 7390, "locality": "QUAREGNON"},
  {"code": 7390, "locality": "WASMUEL"},
  {"code": 7500, "locality": "ERE"},
  {"code": 7500, "locality": "SAINT-MAUR"},
  {"code": 7500, "locality": "TOURNAI"},
  {"code": 7501, "locality": "ORCQ"},
  {"code": 7502, "locality": "ESPLECHIN"},
  {"code": 7503, "locality": "FROYENNES"},
  {"code": 7504, "locality": "FROIDMONT"},
  {"code": 7506, "locality": "WILLEMEAU"},
  {"code": 7520, "locality": "RAMEGNIES-CHIN"},
  {"code": 7520, "locality": "TEMPLEUVE"},
  {"code": 7521, "locality": "CHERCQ"},
  {"code": 7522, "locality": "BLANDAIN"},
  {"code": 7522, "locality": "HERTAIN"},
  {"code": 7522, "locality": "LAMAIN"},
  {"code": 7522, "locality": "MARQUAIN"},
  {"code": 7530, "locality": "GAURAIN-RAMECROIX (TOURNAI)"},
  {"code": 7531, "locality": "HAVINNES"},
  {"code": 7532, "locality": "BECLERS"},
  {"code": 7533, "locality": "THIMOUGIES"},
  {"code": 7534, "locality": "BARRY"},
  {"code": 7534, "locality": "MAULDE"},
  {"code": 7536, "locality": "VAULX (TOURNAI)"},
  {"code": 7538, "locality": "VEZON"},
  {"code": 7540, "locality": "KAIN"},
  {"code": 7540, "locality": "MELLES"},
  {"code": 7540, "locality": "QUARTES"},
  {"code": 7540, "locality": "RUMILLIES"},
  {"code": 7542, "locality": "MONT-SAINT-AUBERT"},
  {"code": 7543, "locality": "MOURCOURT"},
  {"code": 7548, "locality": "WARCHIN"},
  {"code": 7600, "locality": "PERUWELZ"},
  {"code": 7601, "locality": "ROUCOURT"},
  {"code": 7602, "locality": "BURY"},
  {"code": 7603, "locality": "BON-SECOURS"},
  {"code": 7604, "locality": "BAUGNIES"},
  {"code": 7604, "locality": "BRAFFE"},
  {"code": 7604, "locality": "BRASMENIL"},
  {"code": 7604, "locality": "CALLENELLE"},
  {"code": 7604, "locality": "WASMES-AUDEMEZ-BRIFFOEIL"},
  {"code": 7608, "locality": "WIERS"},
  {"code": 7610, "locality": "RUMES"},
  {"code": 7611, "locality": "LA GLANERIE"},
  {"code": 7618, "locality": "TAINTIGNIES"},
  {"code": 7620, "locality": "BLEHARIES"},
  {"code": 7620, "locality": "BRUNEHAUT"},
  {"code": 7620, "locality": "GUIGNIES"},
  {"code": 7620, "locality": "HOLLAIN"},
  {"code": 7620, "locality": "JOLLAIN-MERLIN"},
  {"code": 7620, "locality": "WEZ-VELVAIN"},
  {"code": 7621, "locality": "LESDAIN"},
  {"code": 7622, "locality": "LAPLAIGNE"},
  {"code": 7623, "locality": "RONGY"},
  {"code": 7624, "locality": "HOWARDRIES"},
  {"code": 7640, "locality": "ANTOING"},
  {"code": 7640, "locality": "MAUBRAY"},
  {"code": 7640, "locality": "PERONNES-LEZ-ANTOING"},
  {"code": 7641, "locality": "BRUYELLE"},
  {"code": 7642, "locality": "CALONNE"},
  {"code": 7643, "locality": "FONTENOY"},
  {"code": 7700, "locality": "LUINGNE"},
  {"code": 7700, "locality": "MOUSCRON"},
  {"code": 7700, "locality": "MOUSCRON"},
  {"code": 7711, "locality": "DOTTIGNIES"},
  {"code": 7711, "locality": "DOTTIGNIES"},
  {"code": 7712, "locality": "HERSEAUX"},
  {"code": 7730, "locality": "BAILLEUL"},
  {"code": 7730, "locality": "ESTAIMBOURG"},
  {"code": 7730, "locality": "ESTAIMPUIS"},
  {"code": 7730, "locality": "EVREGNIES"},
  {"code": 7730, "locality": "LEERS-NORD"},
  {"code": 7730, "locality": "NECHIN"},
  {"code": 7730, "locality": "SAINT-LEGER (HAINAUT)"},
  {"code": 7740, "locality": "PECQ"},
  {"code": 7740, "locality": "WARCOING"},
  {"code": 7742, "locality": "HERINNES-LEZ-PECQ"},
  {"code": 7743, "locality": "ESQUELMES"},
  {"code": 7743, "locality": "OBIGIES"},
  {"code": 7750, "locality": "AMOUGIES"},
  {"code": 7750, "locality": "ANSEROEUL"},
  {"code": 7750, "locality": "MONT-DE-L'ENCLUS"},
  {"code": 7750, "locality": "ORROIR"},
  {"code": 7750, "locality": "RUSSEIGNIES"},
  {"code": 7760, "locality": "CELLES (HAINAUT)"},
  {"code": 7760, "locality": "ESCANAFFLES"},
  {"code": 7760, "locality": "MOLENBAIX"},
  {"code": 7760, "locality": "POPUELLES"},
  {"code": 7760, "locality": "POTTES"},
  {"code": 7760, "locality": "VELAINES"},
  {"code": 7780, "locality": "COMINES"},
  {"code": 7780, "locality": "COMINES-WARNETON"},
  {"code": 7780, "locality": "COMINES"},
  {"code": 7780, "locality": "COMINES-WARNETON"},
  {"code": 7781, "locality": "HOUTHEM (COMINES)"},
  {"code": 7782, "locality": "PLOEGSTEERT"},
  {"code": 7783, "locality": "BIZET"},
  {"code": 7784, "locality": "BAS-WARNETON"},
  {"code": 7784, "locality": "BAS-WARNETON"},
  {"code": 7784, "locality": "WARNETON"},
  {"code": 7784, "locality": "WARNETON"},
  {"code": 7800, "locality": "ATH"},
  {"code": 7800, "locality": "LANQUESAINT"},
  {"code": 7801, "locality": "IRCHONWELZ"},
  {"code": 7802, "locality": "ORMEIGNIES"},
  {"code": 7803, "locality": "BOUVIGNIES"},
  {"code": 7804, "locality": "OSTICHES"},
  {"code": 7804, "locality": "REBAIX"},
  {"code": 7810, "locality": "MAFFLE"},
  {"code": 7811, "locality": "ARBRE (HAINAUT)"},
  {"code": 7812, "locality": "HOUTAING"},
  {"code": 7812, "locality": "LIGNE"},
  {"code": 7812, "locality": "MAINVAULT"},
  {"code": 7812, "locality": "MOULBAIX"},
  {"code": 7812, "locality": "VILLERS-NOTRE-DAME"},
  {"code": 7812, "locality": "VILLERS-SAINT-AMAND"},
  {"code": 7822, "locality": "GHISLENGHIEN"},
  {"code": 7822, "locality": "ISIERES"},
  {"code": 7822, "locality": "MESLIN-L'EVEQUE"},
  {"code": 7823, "locality": "GIBECQ"},
  {"code": 7830, "locality": "BASSILLY"},
  {"code": 7830, "locality": "FOULENG"},
  {"code": 7830, "locality": "GONDREGNIES"},
  {"code": 7830, "locality": "GRATY"},
  {"code": 7830, "locality": "HELLEBECQ"},
  {"code": 7830, "locality": "HOVES (HAINAUT)"},
  {"code": 7830, "locality": "SILLY"},
  {"code": 7830, "locality": "THORICOURT"},
  {"code": 7850, "locality": "ENGHIEN"},
  {"code": 7850, "locality": "ENGHIEN"},
  {"code": 7850, "locality": "PETIT-ENGHIEN"},
  {"code": 7850, "locality": "MARCQ"},
  {"code": 7850, "locality": "MARCQ"},
  {"code": 7850, "locality": "PETIT-ENGHIEN"},
  {"code": 7860, "locality": "LESSINES"},
  {"code": 7861, "locality": "PAPIGNIES"},
  {"code": 7861, "locality": "WANNEBECQ"},
  {"code": 7862, "locality": "OGY"},
  {"code": 7863, "locality": "GHOY"},
  {"code": 7864, "locality": "DEUX-ACREN"},
  {"code": 7866, "locality": "BOIS-DE-LESSINES"},
  {"code": 7866, "locality": "OLLIGNIES"},
  {"code": 7870, "locality": "BAUFFE"},
  {"code": 7870, "locality": "CAMBRON-SAINT-VINCENT"},
  {"code": 7870, "locality": "LENS"},
  {"code": 7870, "locality": "LOMBISE"},
  {"code": 7870, "locality": "MONTIGNIES-LEZ-LENS"},
  {"code": 7880, "locality": "FLOBECQ"},
  {"code": 7880, "locality": "FLOBECQ"},
  {"code": 7890, "locality": "ELLEZELLES"},
  {"code": 7890, "locality": "LAHAMAIDE"},
  {"code": 7890, "locality": "WODECQ"},
  {"code": 7900, "locality": "GRANDMETZ"},
  {"code": 7900, "locality": "LEUZE-EN-HAINAUT"},
  {"code": 7901, "locality": "THIEULAIN"},
  {"code": 7903, "locality": "BLICQUY"},
  {"code": 7903, "locality": "CHAPELLE-A-OIE"},
  {"code": 7903, "locality": "CHAPELLE-A-WATTINES"},
  {"code": 7904, "locality": "PIPAIX"},
  {"code": 7904, "locality": "TOURPES"},
  {"code": 7904, "locality": "WILLAUPUIS"},
  {"code": 7906, "locality": "GALLAIX"},
  {"code": 7910, "locality": "ANVAING"},
  {"code": 7910, "locality": "AINIERES"},
  {"code": 7910, "locality": "WATTRIPONT"},
  {"code": 7910, "locality": "CORDES"},
  {"code": 7910, "locality": "ELLIGNIES-LEZ-FRASNES"},
  {"code": 7910, "locality": "FOREST (HAINAUT)"},
  {"code": 7910, "locality": "FRASNES-LEZ-ANVAING"},
  {"code": 7910, "locality": "WATTRIPONT"},
  {"code": 7911, "locality": "BUISSENAL"},
  {"code": 7911, "locality": "FRASNES-LEZ-BUISSENAL"},
  {"code": 7911, "locality": "HACQUEGNIES"},
  {"code": 7911, "locality": "HERQUEGIES"},
  {"code": 7911, "locality": "MONTROEUL-AU-BOIS"},
  {"code": 7911, "locality": "MOUSTIER (HAINAUT)"},
  {"code": 7911, "locality": "OEUDEGHIEN"},
  {"code": 7912, "locality": "DERGNEAU"},
  {"code": 7912, "locality": "SAINT-SAUVEUR"},
  {"code": 7940, "locality": "BRUGELETTE"},
  {"code": 7940, "locality": "CAMBRON-CASTEAU"},
  {"code": 7941, "locality": "ATTRE"},
  {"code": 7942, "locality": "MEVERGNIES-LEZ-LENS"},
  {"code": 7943, "locality": "GAGES"},
  {"code": 7950, "locality": "CHIEVRES"},
  {"code": 7950, "locality": "GROSAGE"},
  {"code": 7950, "locality": "HUISSIGNIES"},
  {"code": 7950, "locality": "LADEUZE"},
  {"code": 7950, "locality": "TONGRE-SAINT-MARTIN"},
  {"code": 7951, "locality": "TONGRE-NOTRE-DAME"},
  {"code": 7970, "locality": "BELOEIL"},
  {"code": 7971, "locality": "BASECLES"},
  {"code": 7971, "locality": "RAMEGNIES"},
  {"code": 7971, "locality": "THUMAIDE"},
  {"code": 7971, "locality": "WADELINCOURT"},
  {"code": 7972, "locality": "AUBECHIES"},
  {"code": 7972, "locality": "ELLIGNIES-SAINTE-ANNE"},
  {"code": 7972, "locality": "QUEVAUCAMPS"},
  {"code": 7973, "locality": "GRANDGLISE"},
  {"code": 7973, "locality": "STAMBRUGES"},
  {"code": 8000, "locality": "BRUGGE"},
  {"code": 8000, "locality": "KOOLKERKE"},
  {"code": 8020, "locality": "HERTSBERGE"},
  {"code": 8020, "locality": "OOSTKAMP"},
  {"code": 8020, "locality": "RUDDERVOORDE"},
  {"code": 8020, "locality": "WAARDAMME"},
  {"code": 8200, "locality": "SINT-ANDRIES"},
  {"code": 8200, "locality": "SINT-MICHIELS"},
  {"code": 8210, "locality": "LOPPEM"},
  {"code": 8210, "locality": "VELDEGEM"},
  {"code": 8210, "locality": "ZEDELGEM"},
  {"code": 8211, "locality": "AARTRIJKE"},
  {"code": 8300, "locality": "KNOKKE"},
  {"code": 8300, "locality": "KNOKKE-HEIST"},
  {"code": 8300, "locality": "WESTKAPELLE"},
  {"code": 8301, "locality": "HEIST-AAN-ZEE"},
  {"code": 8301, "locality": "RAMSKAPELLE (KNOKKE-HEIST)"},
  {"code": 8310, "locality": "ASSEBROEK"},
  {"code": 8310, "locality": "SINT-KRUIS (BRUGGE)"},
  {"code": 8340, "locality": "DAMME"},
  {"code": 8340, "locality": "HOEKE"},
  {"code": 8340, "locality": "LAPSCHEURE"},
  {"code": 8340, "locality": "MOERKERKE"},
  {"code": 8340, "locality": "OOSTKERKE (DAMME)"},
  {"code": 8340, "locality": "SIJSELE"},
  {"code": 8370, "locality": "BLANKENBERGE"},
  {"code": 8370, "locality": "UITKERKE"},
  {"code": 8377, "locality": "HOUTAVE"},
  {"code": 8377, "locality": "MEETKERKE"},
  {"code": 8377, "locality": "NIEUWMUNSTER"},
  {"code": 8377, "locality": "ZUIENKERKE"},
  {"code": 8380, "locality": "DUDZELE"},
  {"code": 8380, "locality": "LISSEWEGE"},
  {"code": 8380, "locality": "ZEEBRUGGE (BRUGGE)"},
  {"code": 8400, "locality": "OOSTENDE"},
  {"code": 8400, "locality": "STENE"},
  {"code": 8400, "locality": "ZANDVOORDE (OOSTENDE)"},
  {"code": 8420, "locality": "DE HAAN"},
  {"code": 8420, "locality": "KLEMSKERKE"},
  {"code": 8420, "locality": "WENDUINE"},
  {"code": 8421, "locality": "VLISSEGEM"},
  {"code": 8430, "locality": "MIDDELKERKE"},
  {"code": 8431, "locality": "WILSKERKE"},
  {"code": 8432, "locality": "LEFFINGE"},
  {"code": 8433, "locality": "MANNEKENSVERE"},
  {"code": 8433, "locality": "SCHORE"},
  {"code": 8433, "locality": "SLIJPE"},
  {"code": 8433, "locality": "SPERMALIE"},
  {"code": 8433, "locality": "SINT-PIETERS-KAPELLE (W.-VL.)"},
  {"code": 8434, "locality": "LOMBARDSIJDE"},
  {"code": 8434, "locality": "WESTENDE"},
  {"code": 8450, "locality": "BREDENE"},
  {"code": 8460, "locality": "ETTELGEM"},
  {"code": 8460, "locality": "OUDENBURG"},
  {"code": 8460, "locality": "ROKSEM"},
  {"code": 8460, "locality": "WESTKERKE"},
  {"code": 8470, "locality": "GISTEL"},
  {"code": 8470, "locality": "MOERE"},
  {"code": 8470, "locality": "SNAASKERKE"},
  {"code": 8470, "locality": "ZEVEKOTE"},
  {"code": 8480, "locality": "BEKEGEM"},
  {"code": 8480, "locality": "EERNEGEM"},
  {"code": 8480, "locality": "ICHTEGEM"},
  {"code": 8490, "locality": "JABBEKE"},
  {"code": 8490, "locality": "SNELLEGEM"},
  {"code": 8490, "locality": "STALHILLE"},
  {"code": 8490, "locality": "VARSENARE"},
  {"code": 8490, "locality": "ZERKEGEM"},
  {"code": 8500, "locality": "KORTRIJK"},
  {"code": 8501, "locality": "BISSEGEM"},
  {"code": 8501, "locality": "HEULE"},
  {"code": 8510, "locality": "BELLEGEM"},
  {"code": 8510, "locality": "KOOIGEM"},
  {"code": 8510, "locality": "MARKE (KORTRIJK)"},
  {"code": 8510, "locality": "ROLLEGEM"},
  {"code": 8511, "locality": "AALBEKE"},
  {"code": 8520, "locality": "KUURNE"},
  {"code": 8530, "locality": "HARELBEKE"},
  {"code": 8531, "locality": "BAVIKHOVE"},
  {"code": 8531, "locality": "HULSTE"},
  {"code": 8540, "locality": "DEERLIJK"},
  {"code": 8550, "locality": "ZWEVEGEM"},
  {"code": 8551, "locality": "HEESTERT"},
  {"code": 8552, "locality": "MOEN"},
  {"code": 8553, "locality": "OTEGEM"},
  {"code": 8554, "locality": "SINT-DENIJS"},
  {"code": 8560, "locality": "GULLEGEM"},
  {"code": 8560, "locality": "MOORSELE"},
  {"code": 8560, "locality": "WEVELGEM"},
  {"code": 8570, "locality": "ANZEGEM"},
  {"code": 8570, "locality": "GIJZELBRECHTEGEM"},
  {"code": 8570, "locality": "INGOOIGEM"},
  {"code": 8570, "locality": "VICHTE"},
  {"code": 8572, "locality": "KASTER"},
  {"code": 8573, "locality": "TIEGEM"},
  {"code": 8580, "locality": "AVELGEM"},
  {"code": 8581, "locality": "KERKHOVE"},
  {"code": 8581, "locality": "WAARMAARDE"},
  {"code": 8582, "locality": "OUTRIJVE"},
  {"code": 8583, "locality": "BOSSUIT"},
  {"code": 8587, "locality": "SPIERE"},
  {"code": 8587, "locality": "SPIERE-HELKIJN"},
  {"code": 8587, "locality": "HELKIJN"},
  {"code": 8587, "locality": "HELKIJN"},
  {"code": 8587, "locality": "SPIERE"},
  {"code": 8587, "locality": "SPIERE-HELKIJN"},
  {"code": 8600, "locality": "BEERST"},
  {"code": 8600, "locality": "DIKSMUIDE"},
  {"code": 8600, "locality": "DRIEKAPELLEN"},
  {"code": 8600, "locality": "ESEN"},
  {"code": 8600, "locality": "KAASKERKE"},
  {"code": 8600, "locality": "KEIEM"},
  {"code": 8600, "locality": "LAMPERNISSE"},
  {"code": 8600, "locality": "LEKE"},
  {"code": 8600, "locality": "NIEUWKAPELLE"},
  {"code": 8600, "locality": "OOSTKERKE (DIKSMUIDE)"},
  {"code": 8600, "locality": "OUDEKAPELLE"},
  {"code": 8600, "locality": "PERVIJZE"},
  {"code": 8600, "locality": "SINT-JACOBS-KAPELLE"},
  {"code": 8600, "locality": "STUIVEKENSKERKE"},
  {"code": 8600, "locality": "VLADSLO"},
  {"code": 8600, "locality": "WOUMEN"},
  {"code": 8610, "locality": "HANDZAME"},
  {"code": 8610, "locality": "KORTEMARK"},
  {"code": 8610, "locality": "WERKEN"},
  {"code": 8610, "locality": "ZARREN"},
  {"code": 8620, "locality": "NIEUWPOORT"},
  {"code": 8620, "locality": "RAMSKAPELLE (NIEUWPOORT)"},
  {"code": 8620, "locality": "SINT-JORIS (NIEUWPOORT)"},
  {"code": 8630, "locality": "AVEKAPELLE"},
  {"code": 8630, "locality": "BEAUVOORDE"},
  {"code": 8630, "locality": "BOOITSHOEKE"},
  {"code": 8630, "locality": "BULSKAMP"},
  {"code": 8630, "locality": "DE MOEREN"},
  {"code": 8630, "locality": "EGGEWAARTSKAPELLE"},
  {"code": 8630, "locality": "HOUTEM (W.-VL.)"},
  {"code": 8630, "locality": "STEENKERKE (W.-VL.)"},
  {"code": 8630, "locality": "VEURNE"},
  {"code": 8630, "locality": "VINKEM"},
  {"code": 8630, "locality": "WULVERINGEM"},
  {"code": 8630, "locality": "ZOUTENAAIE"},
  {"code": 8640, "locality": "OOSTVLETEREN"},
  {"code": 8640, "locality": "VLETEREN"},
  {"code": 8640, "locality": "WESTVLETEREN"},
  {"code": 8640, "locality": "WOESTEN"},
  {"code": 8647, "locality": "LO"},
  {"code": 8647, "locality": "LO-RENINGE"},
  {"code": 8647, "locality": "NOORDSCHOTE"},
  {"code": 8647, "locality": "POLLINKHOVE"},
  {"code": 8647, "locality": "RENINGE"},
  {"code": 8650, "locality": "HOUTHULST"},
  {"code": 8650, "locality": "KLERKEN"},
  {"code": 8650, "locality": "MERKEM"},
  {"code": 8660, "locality": "ADINKERKE"},
  {"code": 8660, "locality": "DE PANNE"},
  {"code": 8670, "locality": "KOKSIJDE"},
  {"code": 8670, "locality": "OOSTDUINKERKE"},
  {"code": 8670, "locality": "WULPEN"},
  {"code": 8680, "locality": "BOVEKERKE"},
  {"code": 8680, "locality": "KOEKELARE"},
  {"code": 8680, "locality": "ZANDE"},
  {"code": 8690, "locality": "ALVERINGEM"},
  {"code": 8690, "locality": "HOOGSTADE"},
  {"code": 8690, "locality": "OEREN"},
  {"code": 8690, "locality": "SINT-RIJKERS"},
  {"code": 8691, "locality": "BEVEREN-AAN-DEN-IJZER"},
  {"code": 8691, "locality": "GIJVERINKHOVE"},
  {"code": 8691, "locality": "IZENBERGE"},
  {"code": 8691, "locality": "LEISELE"},
  {"code": 8691, "locality": "STAVELE"},
  {"code": 8700, "locality": "AARSELE"},
  {"code": 8700, "locality": "KANEGEM"},
  {"code": 8700, "locality": "SCHUIFERSKAPELLE"},
  {"code": 8700, "locality": "TIELT"},
  {"code": 8710, "locality": "OOIGEM"},
  {"code": 8710, "locality": "SINT-BAAFS-VIJVE"},
  {"code": 8710, "locality": "WIELSBEKE"},
  {"code": 8720, "locality": "DENTERGEM"},
  {"code": 8720, "locality": "MARKEGEM"},
  {"code": 8720, "locality": "OESELGEM"},
  {"code": 8720, "locality": "WAKKEN"},
  {"code": 8730, "locality": "BEERNEM"},
  {"code": 8730, "locality": "OEDELEM"},
  {"code": 8730, "locality": "SINT-JORIS (BEERNEM)"},
  {"code": 8740, "locality": "EGEM"},
  {"code": 8740, "locality": "PITTEM"},
  {"code": 8750, "locality": "WINGENE"},
  {"code": 8750, "locality": "ZWEVEZELE"},
  {"code": 8755, "locality": "RUISELEDE"},
  {"code": 8760, "locality": "MEULEBEKE"},
  {"code": 8770, "locality": "INGELMUNSTER"},
  {"code": 8780, "locality": "OOSTROZEBEKE"},
  {"code": 8790, "locality": "WAREGEM"},
  {"code": 8791, "locality": "BEVEREN (LEIE)"},
  {"code": 8792, "locality": "DESSELGEM"},
  {"code": 8793, "locality": "SINT-ELOOIS-VIJVE"},
  {"code": 8800, "locality": "BEVEREN (ROESELARE)"},
  {"code": 8800, "locality": "OEKENE"},
  {"code": 8800, "locality": "ROESELARE"},
  {"code": 8800, "locality": "RUMBEKE"},
  {"code": 8810, "locality": "LICHTERVELDE"},
  {"code": 8820, "locality": "TORHOUT"},
  {"code": 8830, "locality": "GITS"},
  {"code": 8830, "locality": "HOOGLEDE"},
  {"code": 8840, "locality": "OOSTNIEUWKERKE"},
  {"code": 8840, "locality": "STADEN"},
  {"code": 8840, "locality": "WESTROZEBEKE"},
  {"code": 8850, "locality": "ARDOOIE"},
  {"code": 8851, "locality": "KOOLSKAMP"},
  {"code": 8860, "locality": "LENDELEDE"},
  {"code": 8870, "locality": "EMELGEM"},
  {"code": 8870, "locality": "IZEGEM"},
  {"code": 8870, "locality": "KACHTEM"},
  {"code": 8880, "locality": "LEDEGEM"},
  {"code": 8880, "locality": "ROLLEGEM-KAPELLE"},
  {"code": 8880, "locality": "SINT-ELOOIS-WINKEL"},
  {"code": 8890, "locality": "DADIZELE"},
  {"code": 8890, "locality": "MOORSLEDE"},
  {"code": 8900, "locality": "BRIELEN"},
  {"code": 8900, "locality": "DIKKEBUS"},
  {"code": 8900, "locality": "IEPER"},
  {"code": 8900, "locality": "SINT-JAN"},
  {"code": 8902, "locality": "HOLLEBEKE"},
  {"code": 8902, "locality": "VOORMEZELE"},
  {"code": 8902, "locality": "ZILLEBEKE"},
  {"code": 8904, "locality": "BOEZINGE"},
  {"code": 8904, "locality": "ZUIDSCHOTE"},
  {"code": 8906, "locality": "ELVERDINGE"},
  {"code": 8908, "locality": "VLAMERTINGE"},
  {"code": 8920, "locality": "BIKSCHOTE"},
  {"code": 8920, "locality": "LANGEMARK"},
  {"code": 8920, "locality": "LANGEMARK-POELKAPELLE"},
  {"code": 8920, "locality": "POELKAPELLE"},
  {"code": 8930, "locality": "LAUWE"},
  {"code": 8930, "locality": "MENEN"},
  {"code": 8930, "locality": "REKKEM"},
  {"code": 8940, "locality": "GELUWE"},
  {"code": 8940, "locality": "WERVIK"},
  {"code": 8950, "locality": "HEUVELLAND"},
  {"code": 8950, "locality": "NIEUWKERKE"},
  {"code": 8951, "locality": "DRANOUTER"},
  {"code": 8952, "locality": "WULVERGEM"},
  {"code": 8953, "locality": "WIJTSCHATE"},
  {"code": 8954, "locality": "WESTOUTER"},
  {"code": 8956, "locality": "KEMMEL"},
  {"code": 8957, "locality": "MESEN"},
  {"code": 8957, "locality": "MESEN"},
  {"code": 8958, "locality": "LOKER"},
  {"code": 8970, "locality": "POPERINGE"},
  {"code": 8970, "locality": "RENINGELST"},
  {"code": 8972, "locality": "KROMBEKE"},
  {"code": 8972, "locality": "PROVEN"},
  {"code": 8972, "locality": "ROESBRUGGE-HARINGE"},
  {"code": 8978, "locality": "WATOU"},
  {"code": 8980, "locality": "BESELARE"},
  {"code": 8980, "locality": "GELUVELD"},
  {"code": 8980, "locality": "PASSENDALE"},
  {"code": 8980, "locality": "ZANDVOORDE (ZONNEBEKE)"},
  {"code": 8980, "locality": "ZONNEBEKE"},
  {"code": 9000, "locality": "GENT"},
  {"code": 9030, "locality": "MARIAKERKE (GENT)"},
  {"code": 9031, "locality": "DRONGEN"},
  {"code": 9032, "locality": "WONDELGEM"},
  {"code": 9040, "locality": "SINT-AMANDSBERG (GENT)"},
  {"code": 9041, "locality": "OOSTAKKER"},
  {"code": 9042, "locality": "DESTELDONK"},
  {"code": 9042, "locality": "MENDONK"},
  {"code": 9042, "locality": "SINT-KRUIS-WINKEL"},
  {"code": 9050, "locality": "GENTBRUGGE"},
  {"code": 9050, "locality": "LEDEBERG (GENT)"},
  {"code": 9051, "locality": "AFSNEE"},
  {"code": 9051, "locality": "SINT-DENIJS-WESTREM"},
  {"code": 9052, "locality": "ZWIJNAARDE"},
  {"code": 9060, "locality": "ZELZATE"},
  {"code": 9070, "locality": "DESTELBERGEN"},
  {"code": 9070, "locality": "HEUSDEN (O.-VL.)"},
  {"code": 9080, "locality": "BEERVELDE"},
  {"code": 9080, "locality": "LOCHRISTI"},
  {"code": 9080, "locality": "ZAFFELARE"},
  {"code": 9080, "locality": "ZEVENEKEN"},
  {"code": 9090, "locality": "GONTRODE"},
  {"code": 9090, "locality": "MELLE"},
  {"code": 9100, "locality": "NIEUWKERKEN-WAAS"},
  {"code": 9100, "locality": "SINT-NIKLAAS"},
  {"code": 9111, "locality": "BELSELE (SINT-NIKLAAS)"},
  {"code": 9112, "locality": "SINAAI-WAAS"},
  {"code": 9120, "locality": "BEVEREN-WAAS"},
  {"code": 9120, "locality": "HAASDONK"},
  {"code": 9120, "locality": "KALLO (BEVEREN-WAAS)"},
  {"code": 9120, "locality": "MELSELE"},
  {"code": 9120, "locality": "VRASENE"},
  {"code": 9130, "locality": "DOEL"},
  {"code": 9130, "locality": "KALLO (KIELDRECHT)"},
  {"code": 9130, "locality": "KIELDRECHT (BEVEREN)"},
  {"code": 9130, "locality": "VERREBROEK"},
  {"code": 9140, "locality": "ELVERSELE"},
  {"code": 9140, "locality": "STEENDORP"},
  {"code": 9140, "locality": "TEMSE"},
  {"code": 9140, "locality": "TIELRODE"},
  {"code": 9150, "locality": "BAZEL"},
  {"code": 9150, "locality": "KRUIBEKE"},
  {"code": 9150, "locality": "RUPELMONDE"},
  {"code": 9160, "locality": "DAKNAM"},
  {"code": 9160, "locality": "EKSAARDE"},
  {"code": 9160, "locality": "LOKEREN"},
  {"code": 9170, "locality": "DE KLINGE"},
  {"code": 9170, "locality": "MEERDONK"},
  {"code": 9170, "locality": "SINT-GILLIS-WAAS"},
  {"code": 9170, "locality": "SINT-PAUWELS"},
  {"code": 9180, "locality": "MOERBEKE-WAAS"},
  {"code": 9185, "locality": "WACHTEBEKE"},
  {"code": 9190, "locality": "KEMZEKE"},
  {"code": 9190, "locality": "STEKENE"},
  {"code": 9200, "locality": "APPELS"},
  {"code": 9200, "locality": "BAASRODE"},
  {"code": 9200, "locality": "DENDERMONDE"},
  {"code": 9200, "locality": "GREMBERGEN"},
  {"code": 9200, "locality": "MESPELARE"},
  {"code": 9200, "locality": "OUDEGEM"},
  {"code": 9200, "locality": "SCHOONAARDE"},
  {"code": 9200, "locality": "SINT-GILLIS-BIJ-DENDERMONDE"},
  {"code": 9220, "locality": "HAMME (O.-VL.)"},
  {"code": 9220, "locality": "MOERZEKE"},
  {"code": 9230, "locality": "MASSEMEN"},
  {"code": 9230, "locality": "WESTREM"},
  {"code": 9230, "locality": "WETTEREN"},
  {"code": 9240, "locality": "ZELE"},
  {"code": 9250, "locality": "WAASMUNSTER"},
  {"code": 9255, "locality": "BUGGENHOUT"},
  {"code": 9255, "locality": "OPDORP"},
  {"code": 9260, "locality": "SCHELLEBELLE"},
  {"code": 9260, "locality": "SERSKAMP"},
  {"code": 9260, "locality": "WICHELEN"},
  {"code": 9270, "locality": "KALKEN"},
  {"code": 9270, "locality": "LAARNE"},
  {"code": 9280, "locality": "DENDERBELLE"},
  {"code": 9280, "locality": "LEBBEKE"},
  {"code": 9280, "locality": "WIEZE"},
  {"code": 9290, "locality": "BERLARE"},
  {"code": 9290, "locality": "OVERMERE"},
  {"code": 9290, "locality": "UITBERGEN"},
  {"code": 9300, "locality": "AALST"},
  {"code": 9308, "locality": "GIJZEGEM"},
  {"code": 9308, "locality": "HOFSTADE (O.-VL.)"},
  {"code": 9310, "locality": "BAARDEGEM"},
  {"code": 9310, "locality": "HERDERSEM"},
  {"code": 9310, "locality": "MELDERT (O.-VL.)"},
  {"code": 9310, "locality": "MOORSEL"},
  {"code": 9320, "locality": "EREMBODEGEM (AALST)"},
  {"code": 9320, "locality": "NIEUWERKERKEN (AALST)"},
  {"code": 9340, "locality": "IMPE"},
  {"code": 9340, "locality": "LEDE"},
  {"code": 9340, "locality": "OORDEGEM"},
  {"code": 9340, "locality": "SMETLEDE"},
  {"code": 9340, "locality": "WANZELE"},
  {"code": 9400, "locality": "APPELTERRE-EICHEM"},
  {"code": 9400, "locality": "DENDERWINDEKE"},
  {"code": 9400, "locality": "LIEFERINGE"},
  {"code": 9400, "locality": "NEDERHASSELT"},
  {"code": 9400, "locality": "NINOVE"},
  {"code": 9400, "locality": "OKEGEM"},
  {"code": 9400, "locality": "VOORDE"},
  {"code": 9401, "locality": "POLLARE"},
  {"code": 9402, "locality": "MEERBEKE"},
  {"code": 9403, "locality": "NEIGEM"},
  {"code": 9404, "locality": "ASPELARE"},
  {"code": 9406, "locality": "OUTER"},
  {"code": 9420, "locality": "AAIGEM"},
  {"code": 9420, "locality": "BAMBRUGGE"},
  {"code": 9420, "locality": "BURST"},
  {"code": 9420, "locality": "ERONDEGEM"},
  {"code": 9420, "locality": "ERPE"},
  {"code": 9420, "locality": "ERPE-MERE"},
  {"code": 9420, "locality": "MERE"},
  {"code": 9420, "locality": "OTTERGEM"},
  {"code": 9420, "locality": "VLEKKEM"},
  {"code": 9450, "locality": "DENDERHOUTEM"},
  {"code": 9450, "locality": "HAALTERT"},
  {"code": 9450, "locality": "HELDERGEM"},
  {"code": 9451, "locality": "KERKSKEN"},
  {"code": 9470, "locality": "DENDERLEEUW"},
  {"code": 9472, "locality": "IDDERGEM"},
  {"code": 9473, "locality": "WELLE"},
  {"code": 9500, "locality": "GERAARDSBERGEN"},
  {"code": 9500, "locality": "GOEFERDINGE"},
  {"code": 9500, "locality": "MOERBEKE"},
  {"code": 9500, "locality": "NEDERBOELARE"},
  {"code": 9500, "locality": "ONKERZELE"},
  {"code": 9500, "locality": "OPHASSELT"},
  {"code": 9500, "locality": "OVERBOELARE"},
  {"code": 9500, "locality": "VIANE"},
  {"code": 9500, "locality": "ZARLARDINGE"},
  {"code": 9506, "locality": "GRIMMINGE"},
  {"code": 9506, "locality": "IDEGEM"},
  {"code": 9506, "locality": "NIEUWENHOVE"},
  {"code": 9506, "locality": "SCHENDELBEKE"},
  {"code": 9506, "locality": "SMEEREBBE-VLOERZEGEM"},
  {"code": 9506, "locality": "WAARBEKE"},
  {"code": 9506, "locality": "ZANDBERGEN"},
  {"code": 9520, "locality": "BAVEGEM"},
  {"code": 9520, "locality": "SINT-LIEVENS-HOUTEM"},
  {"code": 9520, "locality": "VLIERZELE"},
  {"code": 9520, "locality": "ZONNEGEM"},
  {"code": 9521, "locality": "LETTERHOUTEM"},
  {"code": 9550, "locality": "HERZELE"},
  {"code": 9550, "locality": "HILLEGEM"},
  {"code": 9550, "locality": "SINT-ANTELINKS"},
  {"code": 9550, "locality": "SINT-LIEVENS-ESSE"},
  {"code": 9550, "locality": "STEENHUIZE-WIJNHUIZE"},
  {"code": 9550, "locality": "WOUBRECHTEGEM"},
  {"code": 9551, "locality": "RESSEGEM"},
  {"code": 9552, "locality": "BORSBEKE"},
  {"code": 9570, "locality": "DEFTINGE"},
  {"code": 9570, "locality": "LIERDE"},
  {"code": 9570, "locality": "SINT-MARIA-LIERDE"},
  {"code": 9571, "locality": "HEMELVEERDEGEM"},
  {"code": 9572, "locality": "SINT-MARTENS-LIERDE"},
  {"code": 9600, "locality": "RONSE"},
  {"code": 9600, "locality": "RONSE"},
  {"code": 9620, "locality": "ELENE"},
  {"code": 9620, "locality": "ERWETEGEM"},
  {"code": 9620, "locality": "GODVEERDEGEM"},
  {"code": 9620, "locality": "GROTENBERGE"},
  {"code": 9620, "locality": "LEEUWERGEM"},
  {"code": 9620, "locality": "OOMBERGEN (ZOTTEGEM)"},
  {"code": 9620, "locality": "SINT-GORIKS-OUDENHOVE"},
  {"code": 9620, "locality": "SINT-MARIA-OUDENHOVE (ZOTTEGEM)"},
  {"code": 9620, "locality": "STRIJPEN"},
  {"code": 9620, "locality": "VELZEKE-RUDDERSHOVE"},
  {"code": 9620, "locality": "ZOTTEGEM"},
  {"code": 9630, "locality": "BEERLEGEM"},
  {"code": 9630, "locality": "DIKKELE"},
  {"code": 9630, "locality": "HUNDELGEM"},
  {"code": 9630, "locality": "MEILEGEM"},
  {"code": 9630, "locality": "MUNKZWALM"},
  {"code": 9630, "locality": "PAULATEM"},
  {"code": 9630, "locality": "ROBORST"},
  {"code": 9630, "locality": "ROZEBEKE"},
  {"code": 9630, "locality": "SINT-BLASIUS-BOEKEL"},
  {"code": 9630, "locality": "SINT-DENIJS-BOEKEL"},
  {"code": 9630, "locality": "SINT-MARIA-LATEM"},
  {"code": 9630, "locality": "ZWALM"},
  {"code": 9636, "locality": "NEDERZWALM-HERMELGEM"},
  {"code": 9660, "locality": "BRAKEL"},
  {"code": 9660, "locality": "ELST"},
  {"code": 9660, "locality": "EVERBEEK"},
  {"code": 9660, "locality": "MICHELBEKE"},
  {"code": 9660, "locality": "NEDERBRAKEL"},
  {"code": 9660, "locality": "OPBRAKEL"},
  {"code": 9660, "locality": "ZEGELSEM"},
  {"code": 9661, "locality": "PARIKE"},
  {"code": 9667, "locality": "HOREBEKE"},
  {"code": 9667, "locality": "SINT-KORNELIS-HOREBEKE"},
  {"code": 9667, "locality": "SINT-MARIA-HOREBEKE"},
  {"code": 9680, "locality": "ETIKHOVE"},
  {"code": 9680, "locality": "MAARKE-KERKEM"},
  {"code": 9680, "locality": "MAARKEDAL"},
  {"code": 9681, "locality": "NUKERKE"},
  {"code": 9688, "locality": "SCHORISSE"},
  {"code": 9690, "locality": "BERCHEM (O.-VL.)"},
  {"code": 9690, "locality": "KLUISBERGEN"},
  {"code": 9690, "locality": "KWAREMONT"},
  {"code": 9690, "locality": "RUIEN"},
  {"code": 9690, "locality": "ZULZEKE"},
  {"code": 9700, "locality": "BEVERE"},
  {"code": 9700, "locality": "EDELARE"},
  {"code": 9700, "locality": "EINE"},
  {"code": 9700, "locality": "ENAME"},
  {"code": 9700, "locality": "HEURNE"},
  {"code": 9700, "locality": "LEUPEGEM"},
  {"code": 9700, "locality": "MATER"},
  {"code": 9700, "locality": "MELDEN"},
  {"code": 9700, "locality": "MULLEM"},
  {"code": 9700, "locality": "NEDERENAME"},
  {"code": 9700, "locality": "OUDENAARDE"},
  {"code": 9700, "locality": "VOLKEGEM"},
  {"code": 9700, "locality": "WELDEN"},
  {"code": 9750, "locality": "KRUISEM"},
  {"code": 9750, "locality": "KRUISEM"},
  {"code": 9750, "locality": "KRUISEM"},
  {"code": 9770, "locality": "KRUISEM"},
  {"code": 9771, "locality": "NOKERE"},
  {"code": 9772, "locality": "WANNEGEM-LEDE"},
  {"code": 9790, "locality": "ELSEGEM"},
  {"code": 9790, "locality": "MOREGEM"},
  {"code": 9790, "locality": "OOIKE (WORTEGEM-PETEGEM)"},
  {"code": 9790, "locality": "PETEGEM-AAN-DE-SCHELDE"},
  {"code": 9790, "locality": "WORTEGEM"},
  {"code": 9790, "locality": "WORTEGEM-PETEGEM"},
  {"code": 9800, "locality": "DEINZE"},
  {"code": 9800, "locality": "DEINZE"},
  {"code": 9800, "locality": "DEINZE"},
  {"code": 9800, "locality": "DEINZE"},
  {"code": 9800, "locality": "DEINZE"},
  {"code": 9800, "locality": "DEINZE"},
  {"code": 9800, "locality": "DEINZE"},
  {"code": 9800, "locality": "DEINZE"},
  {"code": 9800, "locality": "DEINZE"},
  {"code": 9800, "locality": "DEINZE"},
  {"code": 9800, "locality": "DEINZE"},
  {"code": 9810, "locality": "EKE"},
  {"code": 9810, "locality": "NAZARETH"},
  {"code": 9820, "locality": "BOTTELARE"},
  {"code": 9820, "locality": "LEMBERGE"},
  {"code": 9820, "locality": "MELSEN"},
  {"code": 9820, "locality": "MERELBEKE"},
  {"code": 9820, "locality": "MUNTE"},
  {"code": 9820, "locality": "SCHELDERODE"},
  {"code": 9830, "locality": "SINT-MARTENS-LATEM"},
  {"code": 9831, "locality": "DEURLE"},
  {"code": 9840, "locality": "DE PINTE"},
  {"code": 9840, "locality": "ZEVERGEM"},
  {"code": 9850, "locality": "DEINZE"},
  {"code": 9850, "locality": "DEINZE"},
  {"code": 9850, "locality": "DEINZE"},
  {"code": 9850, "locality": "DEINZE"},
  {"code": 9850, "locality": "DEINZE"},
  {"code": 9850, "locality": "DEINZE"},
  {"code": 9860, "locality": "BALEGEM"},
  {"code": 9860, "locality": "GIJZENZELE"},
  {"code": 9860, "locality": "LANDSKOUTER"},
  {"code": 9860, "locality": "MOORTSELE"},
  {"code": 9860, "locality": "OOSTERZELE"},
  {"code": 9860, "locality": "SCHELDEWINDEKE"},
  {"code": 9870, "locality": "MACHELEN (O.-VL.)"},
  {"code": 9870, "locality": "OLSENE"},
  {"code": 9870, "locality": "ZULTE"},
  {"code": 9880, "locality": "AALTER"},
  {"code": 9880, "locality": "AALTER"},
  {"code": 9880, "locality": "AALTER"},
  {"code": 9881, "locality": "BELLEM"},
  {"code": 9890, "locality": "ASPER"},
  {"code": 9890, "locality": "BAAIGEM"},
  {"code": 9890, "locality": "DIKKELVENNE"},
  {"code": 9890, "locality": "GAVERE"},
  {"code": 9890, "locality": "SEMMERZAKE"},
  {"code": 9890, "locality": "VURSTE"},
  {"code": 9900, "locality": "EEKLO"},
  {"code": 9910, "locality": "AALTER"},
  {"code": 9910, "locality": "AALTER"},
  {"code": 9920, "locality": "LIEVEGEM"},
  {"code": 9921, "locality": "VINDERHOUTE"},
  {"code": 9930, "locality": "LIEVEGEM"},
  {"code": 9931, "locality": "LIEVEGEM"},
  {"code": 9932, "locality": "RONSELE"},
  {"code": 9940, "locality": "ERTVELDE"},
  {"code": 9940, "locality": "EVERGEM"},
  {"code": 9940, "locality": "KLUIZEN"},
  {"code": 9940, "locality": "SLEIDINGE"},
  {"code": 9950, "locality": "LIEVEGEM"},
  {"code": 9960, "locality": "ASSENEDE"},
  {"code": 9961, "locality": "BOEKHOUTE"},
  {"code": 9968, "locality": "BASSEVELDE"},
  {"code": 9968, "locality": "OOSTEEKLO"},
  {"code": 9970, "locality": "KAPRIJKE"},
  {"code": 9970, "locality": "KRUISHOUTEM"},
  {"code": 9971, "locality": "LEMBEKE"},
  {"code": 9980, "locality": "SINT-LAUREINS"},
  {"code": 9981, "locality": "SINT-MARGRIETE"},
  {"code": 9982, "locality": "SINT-JAN-IN-EREMO"},
  {"code": 9988, "locality": "WATERLAND-OUDEMAN"},
  {"code": 9988, "locality": "WATERVLIET"},
  {"code": 9990, "locality": "MALDEGEM"},
  {"code": 9991, "locality": "ADEGEM"},
  {"code": 9992, "locality": "MIDDELBURG"}
];

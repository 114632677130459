import { BUTTON, LINK, INPUT, CHECKBOX } from "../../utils/constants";
import { IQuestion } from "./Question";

export interface ISurvey {
    flowID: any;
    userAnswers: IQuestion[];
    surveyFlow: any[];
    questionsPile: any[];
    currentQuestion: any;
    cursor: number;
    userDetails: any;
}

export const emptySurvey: ISurvey = {
    flowID: "",
    userAnswers: [
        {
            "questionID": "Q1",
            "questionLabel": "Quel est votre profil ?",
            "questionOptions": [
                {
                    "answerID": "AN2",
                    "answerLabel": "Particulier",
                    "answerType": "",
                    "nextQuestionID": "Q2",
                    "answerValue": "part",
                    "answerValueType": ""
                },
                {
                    "answerID": "AN1",
                    "answerLabel": "Professionnel",
                    "answerType": "",
                    "nextQuestionID": "Q2",
                    "answerValue": "pro",
                    "answerValueType": ""
                }
            ],
            "order": 1,
            "selectedAnswer": [
                {
                    "answerID": "AN2",
                    "answerLabel": "Particulier",
                    "answerType": "",
                    "nextQuestionID": "Q2",
                    "answerValue": "part",
                    "answerValueType": ""
                }
            ],
            "previousQuestionID": "",
            "hasMultipleOptions": true,
            "hasUniqueAnswer": true,
            "questionType": "radio"
        },
        {
            "questionID": "Q2",
            "questionLabel": "Quel prix voulez-vous calculer ?",
            "questionOptions": [
                {
                    "answerID": "AN3",
                    "answerLabel": "Électricité",
                    "answerType": "",
                    "nextQuestionID": "Q3",
                    "answerValue": "",
                    "answerValueType": ""
                },
                {
                    "answerID": "AN5",
                    "answerLabel": "Électricité & gaz",
                    "answerType": "",
                    "nextQuestionID": "Q3",
                    "answerValue": "",
                    "answerValueType": ""
                },
                {
                    "answerID": "AN4",
                    "answerLabel": "Gaz naturel",
                    "answerType": "",
                    "nextQuestionID": "Q3",
                    "answerValue": "",
                    "answerValueType": ""
                }
            ],
            "order": 1,
            "selectedAnswer": [
                {
                    "answerID": "AN5",
                    "answerLabel": "Électricité & gaz",
                    "answerType": "",
                    "nextQuestionID": "Q3",
                    "answerValue": "",
                    "answerValueType": ""
                }
            ],
            "previousQuestionID": "Q1",
            "hasMultipleOptions": true,
            "hasUniqueAnswer": true,
            "questionType": "radio"
        },
        {
            "questionID": "Q3",
            "questionLabel": "Quel est votre code postal ?",
            "questionOptions": [
                {
                    "answerID": "AN6",
                    "answerLabel": "code Postal",
                    "answerType": "input",
                    "nextQuestionID": "Q4",
                    "answerValue": "",
                    "answerValueType": ""
                }
            ],
            "order": 1,
            "selectedAnswer": [
                {
                    "answerID": "AN6",
                    "answerLabel": "code Postal",
                    "answerType": "input",
                    "nextQuestionID": "Q4",
                    "answerValue": {
                        "code": 1000,
                        "locality": "BRUSSEL"
                    },
                    "answerValueType": ""
                }
            ],
            "previousQuestionID": "Q2",
            "hasMultipleOptions": true,
            "hasUniqueAnswer": false,
            "questionType": "custom",
            "questionBloc": "postal"
        },
        {
            "questionID": "Q4",
            "questionLabel": "Disposez-vous d'un compteur digital ?",
            "questionSubLabel": "",
            "questionOptions": [
                {
                    "answerID": "AN7",
                    "answerLabel": "Oui",
                    "answerType": "",
                    "nextQuestionID": "Q5",
                    "answerValue": "yes",
                    "answerValueType": ""
                },
                {
                    "answerID": "AN8",
                    "answerLabel": "Non",
                    "answerType": "",
                    "nextQuestionID": "Q5",
                    "answerValue": "no",
                    "answerValueType": ""
                }
            ],
            "order": 1,
            "selectedAnswer": [
                {
                    "answerID": "AN7",
                    "answerLabel": "Oui",
                    "answerType": "",
                    "nextQuestionID": "Q5",
                    "answerValue": "yes",
                    "answerValueType": ""
                }
            ],
            "previousQuestionID": "Q3",
            "hasMultipleOptions": true,
            "hasUniqueAnswer": true,
            "questionType": "radio"
        },
        {
            "questionID": "Q5",
            "questionLabel": "De quel type de compteur votre habitation dispose-t-elle ?",
            "questionOptions": [
                {
                    "answerID": "AN9",
                    "answerLabel": "Monohoraire",
                    "answerType": "radio",
                    "nextQuestionID": "Q7",
                    "answerValue": "",
                    "answerValueType": "",
                    "icon": "monohoraire.png"
                },
                {
                    "answerID": "AN10",
                    "answerLabel": "Bihoraire",
                    "answerType": "radio",
                    "nextQuestionID": "Q7",
                    "answerValue": "",
                    "answerValueType": "",
                    "icon": "bihoraire.png"
                },
                {
                    "answerID": "AN11",
                    "answerLabel": "je dispose également d'un compteur exclusif nuit",
                    "answerType": "select",
                    "nextQuestionID": "Q7",
                    "answerValue": "",
                    "answerValueType": ""
                }
            ],
            "order": 1,
            "selectedAnswer": [
                {
                    "answerID": "AN9",
                    "answerLabel": "Monohoraire",
                    "answerType": "radio",
                    "nextQuestionID": "Q7",
                    "answerValue": "",
                    "answerValueType": "",
                    "icon": "monohoraire.png"
                }
            ],
            "previousQuestionID": "Q4",
            "hasMultipleOptions": true,
            "hasUniqueAnswer": false,
            "questionType": "mixte"
        },
        {
            "questionID": "Q7",
            "questionLabel": "Disposez-vous de panneaux solaires ?",
            "questionSubLabel": "Ou d'une autre installation de production d'électricité.",
            "questionOptions": [
                {
                    "answerID": "AN12",
                    "answerLabel": "Oui",
                    "answerType": "",
                    "nextQuestionID": "Q8",
                    "answerValue": "yes",
                    "answerValueType": ""
                },
                {
                    "answerID": "AN13",
                    "answerLabel": "Non",
                    "answerType": "",
                    "nextQuestionID": "Q9",
                    "answerValue": "no",
                    "answerValueType": ""
                }
            ],
            "order": 1,
            "selectedAnswer": [
                {
                    "answerID": "AN13",
                    "answerLabel": "Non",
                    "answerType": "",
                    "nextQuestionID": "Q9",
                    "answerValue": "no",
                    "answerValueType": ""
                }
            ],
            "previousQuestionID": "Q5",
            "hasMultipleOptions": true,
            "hasUniqueAnswer": true,
            "questionType": "radio"
        },
        {
            "questionID": "Q9",
            "questionLabel": "Connaissez-vous votre consommation annuelle ?",
            "questionOptions": [
                {
                    "answerID": "AN16",
                    "answerLabel": "Oui",
                    "answerType": "select",
                    "nextQuestionID": "Q10",
                    "answerValue": "",
                    "answerValueType": ""
                },
                {
                    "answerID": "AN17",
                    "answerLabel": "Non",
                    "answerType": "select",
                    "nextQuestionID": "Q17",
                    "answerValue": "",
                    "answerValueType": "",
                    "isProfessional": true
                },
                {
                    "answerID": "AN18",
                    "answerLabel": "Non",
                    "answerType": "select",
                    "nextQuestionID": "Q13",
                    "answerValue": "",
                    "answerValueType": "",
                    "isProfessional": false
                }
            ],
            "order": 1,
            "selectedAnswer": [
                {
                    "answerID": "AN16",
                    "answerLabel": "Oui",
                    "answerType": "select",
                    "nextQuestionID": "Q10",
                    "answerValue": "",
                    "answerValueType": ""
                }
            ],
            "previousQuestionID": "Q7",
            "hasMultipleOptions": true,
            "hasUniqueAnswer": true,
            "questionType": "radio"
        },
        {
            "questionID": "Q10",
            "questionLabel": "Quelle est votre consommation annuelle ?",
            "questionOptions": [
                {
                    "answerID": "AN19",
                    "answerLabel": "Jour",
                    "answerType": "input",
                    "nextQuestionID": "Q12",
                    "answerValue": "",
                    "answerValueType": ""
                },
                {
                    "answerID": "AN38",
                    "answerLabel": "nuit",
                    "answerType": "input",
                    "nextQuestionID": "Q12",
                    "answerValue": "",
                    "answerValueType": ""
                },
                {
                    "answerID": "AN39",
                    "answerLabel": "exclusif nuit",
                    "answerType": "input",
                    "nextQuestionID": "Q12",
                    "answerValue": "",
                    "answerValueType": ""
                }
            ],
            "order": 1,
            "selectedAnswer": [
                {
                    "answerID": "AN19",
                    "answerLabel": "Jour",
                    "answerType": "input",
                    "nextQuestionID": "Q12",
                    "answerValue": "1000",
                    "answerValueType": "",
                    "answerChecked": false
                },
                {
                    "answerID": "AN38",
                    "answerLabel": "nuit",
                    "answerType": "input",
                    "nextQuestionID": "Q12",
                    "answerValue": "200",
                    "answerValueType": "",
                    "answerChecked": false
                },
                {
                    "answerID": "AN39",
                    "answerLabel": "exclusif nuit",
                    "answerType": "input",
                    "nextQuestionID": "Q12",
                    "answerValue": "300",
                    "answerValueType": "",
                    "answerChecked": false
                },
                {
                    "answerID": "AN40",
                    "answerLabel": "Consommation",
                    "answerType": "input",
                    "nextQuestionID": "Q12",
                    "answerValue": "4000",
                    "answerValueType": "",
                    "answerChecked": false
                }
            ],
            "previousQuestionID": "Q9",
            "hasMultipleOptions": true,
            "hasUniqueAnswer": false,
            "questionType": "custom",
            "questionBloc": "conso",
            "consumptionKnown": true
        },
        {
            "questionID": "Q12",
            "questionLabel": "Votre prélèvement et injection annuels",
            "questionSubLabel": "Sur la base de votre consommation d'énergie, nous avons calculé votre prélèvement et votre injection.",
            "questionOptions": [],
            "order": 1,
            "selectedAnswer": [
                {
                    "answerID": "AN42",
                    "answerLabel": "Prélèvement jour",
                    "answerType": "input",
                    "nextQuestionID": "",
                    "answerValue": "5",
                    "answerValueType": ""
                },
                {
                    "answerID": "AN43",
                    "answerLabel": "Prélèvement Nuit",
                    "answerType": "input",
                    "nextQuestionID": "",
                    "answerValue": "6",
                    "answerValueType": ""
                },
                {
                    "answerID": "AN44",
                    "answerLabel": "Injection Jour",
                    "answerType": "input",
                    "nextQuestionID": "",
                    "answerValue": "7",
                    "answerValueType": ""
                },
                {
                    "answerID": "AN45",
                    "answerLabel": "Injection Nuit",
                    "answerType": "input",
                    "nextQuestionID": "",
                    "answerValue": "9",
                    "answerValueType": ""
                }
            ],
            "previousQuestionID": "Q10",
            "hasMultipleOptions": true,
            "hasUniqueAnswer": false,
            "questionType": "custom",
            "questionBloc": "injection",
            "consumptionKnown": true
        }
    ],
    surveyFlow: [],
    questionsPile: [],
    currentQuestion: {
        questionId: "Q1",
        [BUTTON]: null,
        [LINK]: null,
        [INPUT]: null,
        [CHECKBOX]: null,
        values: [],
        flags: {}
    },
    cursor: 1,
    userDetails: {}
}

export const leadActionTypes = {
	SET_DYNAMIC_PARAMS: "SET_DYNAMIC_PARAMS",
	SET_SAVED_SIMU_PARAMS: "SET_SAVED_SIMU_PARAMS",
	SET_PROMO_CODE: "SET_PROMO_CODE",
	SET_REF_ID: "SET_REF_ID",
	SET_USER_DATA: "SET_USER_DATA",
	SET_FINANCIAL_DATA: "SET_FINANCIAL_DATA",
	UPDATE_PERSONNAL_DATA: "UPDATE_PERSONNAL_DATA",
	UPDATE_ELEC_DATA: "UPDATE_ELEC_DATA",
	UPDATE_GAS_DATA: "UPDATE_GAS_DATA",
	UPDATE_BILLING_ADDR: "UPDATE_BILLING_ADDR",
	UPDATE_BILLING_DATA: "UPDATE_BILLING_DATA",
	VALIDATE_SIGN_UP: "VALIDATE_SIGN_UP",
	INIT_LEAD: "INIT_LEAD"
};

import getConfig from "next/config";
import lowerCase from "lodash/lowerCase";
import get from "lodash/get";
import startsWith from "lodash/startsWith";
import trim from "lodash/trim";
import forEach from "lodash/forEach";
import find from "lodash/find";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import filter from "lodash/filter";
import map from "lodash/map";
import first from "lodash/first";
import compact from "lodash/compact";
import toLower from "lodash/toLower";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNull from "lodash/isNull";
import last from "lodash/last";
import orderBy from "lodash/orderBy";
import replace from "lodash/replace";
import moment from "moment";

import { ISurvey } from "../src/Models/Survey";
import { IQuestion } from "../src/Models/Question";
import { IProduct } from "../src/Models/Product";
import { ISelectedProduct } from "../src/Models/Simulation";
import { isBiHoraireCounter } from "./checkIntegrity";
import { IBspContract } from "../src/Models/BspLead";
import { postalCodeList } from "./PostCodeList";
import { emptyPostCode } from "../src/Models/PostCode";
import { emptyConfirmation } from "../src/Models/Confirmation";
import { brusselsPostCodeCheck } from "./validators";
import {
	CONSUMPTION_DETAILS_QUESTION_ID,
	GREY_PRODUCT,
	INJECTION_CONSUMPTION_DETAILS_QUESTION_ID,
	POSTAL_QUESTION_ID,
	BRUSSELS,
	WALLONIA,
	FLANDERS,
	IS_ELEC,
	PROFESSIONAL, INDIVIDUAL
} from "./constants";

const envConfig = getConfig();

export const formatLang = (value: string) => {
	switch (lowerCase(value)) {
		case "f":
			return "F";
		case "fr":
			return "F";
		case "fr be":
			return "F";
		case "n":
			return "N";
		case "nl":
			return "N";
		case "nl be":
			return "N";
		default:
			return "N";
	}
};

export const formatFlags = (value: string | boolean) => {
	switch (value) {
		case '0':
			return false;
		case 'False':
			return false;
		case 'false':
			return false;
		case false:
			return false;
		case '1':
			return true;
		case 'True':
			return true;
		case 'true':
			return true;
		case true:
			return true;
		default:
			return false;
	}
};

export const getAdditionalArguments = (questionId: string, userDetails: any) => {
	if (questionId == "Q8") {
		return userDetails.userType == PROFESSIONAL;
	}

	return null;
};

export const getCompanyFormValue = (
	reqValue: string,
	dataSource: any[],
	lang: string
) => {
	const returnedResult = find(dataSource, (item) => item.value === reqValue);

	if (returnedResult !== undefined) {
		return lang === "fr" ? returnedResult.descFr : returnedResult.descNl;
	}

	return "";
};

export const getTextValue = (reqValue: string, dataSource: any[]) => {
	const returnedResult = find(dataSource, (item) => item.value === reqValue);

	if (returnedResult !== undefined) {
		return returnedResult.i18nKey;
	}

	return "";
};

export const getIbanFormat = (plainValue: string) => {
	const digits = plainValue.split("");
	const twoDigits = `${digits[0]}${digits[1]}`;
	if (includes(["BE"], twoDigits)) {
		return "BE## #### #### ####";
	}

	return "BE## #### #### ####";
};

export const getPhoneBlock = (plainValue: string) => {
	const digits = plainValue.split("");
	const twoDigits = `${digits[0]}${digits[1]}`;
	if (includes(["01", "05", "06", "07", "08"], twoDigits)) {
		return getPhoneMask(plainValue, [3, 2, 2, 2]);
	}
	if (
		includes(["045", "046", "047", "048", "049"], `${twoDigits}${digits[2]}`)
	) {
		return getPhoneMask(plainValue, [4, 2, 2, 2]);
	}
	if (includes(["02", "03", "04", "09"], twoDigits)) {
		return getPhoneMask(plainValue, [2, 3, 2, 2]);
	}
	return plainValue;
};

export const getIBANOrigin = (plainValue: string) => {
	if (startsWith(plainValue, "BE")) {
		return "BE";
	}
	if (startsWith(plainValue, "NL")) {
		return "NL";
	}
	if (startsWith(plainValue, "FR")) {
		return "FR";
	}
	if (startsWith(plainValue, "GB")) {
		return "GB";
	}
	if (startsWith(plainValue, "DE")) {
		return "DE";
	}

	return "BE";
};

const getPhoneMask = (value: any, blocks: any) => {
	let mask = "";
	const delimiters = ["/", ".", "."];
	mask += value.substring(0, blocks[0]);
	if (value.substring(blocks[0], blocks[0] + blocks[1]) !== "") {
		mask =
			mask + delimiters[0] + value.substring(blocks[0], blocks[0] + blocks[1]);
	}
	if (
		value.substring(
			blocks[0] + blocks[1],
			blocks[0] + blocks[1] + blocks[2]
		) !== ""
	) {
		mask =
			mask +
			delimiters[1] +
			value.substring(blocks[0] + blocks[1], blocks[0] + blocks[1] + blocks[2]);
	}
	if (
		value.substring(
			blocks[0] + blocks[1] + blocks[2],
			blocks[0] + blocks[1] + blocks[2] + blocks[3]
		) !== ""
	) {
		mask =
			mask +
			delimiters[2] +
			value.substring(
				blocks[0] + blocks[1] + blocks[2],
				blocks[0] + blocks[1] + blocks[2] + blocks[3]
			);
	}

	return mask;
};

export const flattenCollection = (collection: any) => {
	let flatCollection: any = {};

	forEach(collection, (item, key: string) => {
		if (
			typeof item === "object" &&
			key !== "postalCode" &&
			key !== "billPostalCode"
		) {
			flatCollection = {
				...flatCollection,
				...flattenCollection(item)
			};
		} else {
			flatCollection = {
				...flatCollection,
				[key]: item
			};
		}
	});

	return flatCollection;
};

export const formatInfoMandate = (mandate: any, energy: string) => {
	let mappedData: any;

	mappedData = {
		mandateNumber: get(mandate, "MandateNumber", ""),
		energy,
		billingCompanyName: get(mandate, "BillingCompanyName", ""),
		billingCompanyStreet: get(mandate, "BillingCompanyStreet", ""),
		billingCompanyTown: get(mandate, "BillingCompanyTown", ""),
		billingCompanyContract: get(mandate, "BillingCompanyContract", "")
	};

	return mappedData;
};

export const formatCompanyTypes = (dataList: any) => {
	const mappedData: any[] = [];

	forEach(dataList, (item) => {
		mappedData.push({
			value: item.Code,
			descFr: item.DescriptionFr,
			descNl: item.DescriptionNl
		});
	});

	return mappedData;
};

export const negativeValuesRemoval = (value: number) => {
	if (value < 0) {
		return 0;
	}

	return value;
};

export const formatNumericalValues = (value: number | string) => {
	let currentVal = String(value);

	const returnValue = parseFloat(currentVal).toFixed(2);

	if (returnValue === "NaN") {
		return "";
	}

	return returnValue.replace(/\./g, ",");
};

export const formatNumericalValueTh = (value: number | string) => {
	let currentVal = String(value);

	let returnValue = parseFloat(currentVal).toFixed(2);

	if (returnValue === "NaN") {
		return "";
	}

	let returnValueNumber = Number(returnValue);

	return returnValueNumber.toLocaleString("nl-BE");
};

export const retrieveAnswers = (surveyState: ISurvey, dataList: any) => {
	const mappedData: any[] = [];
	const questionsList: IQuestion[] = surveyState.userAnswers;

	forEach(dataList, (item) => {
		const returnedQst = find(
			questionsList,
			(question) => question.questionID === item.questionId
		);

		if (returnedQst) {
			if (!isArray(returnedQst.selectedAnswer)) {
				mappedData.push({
					questionId: item.questionId,
					answerId: returnedQst.selectedAnswer.answerID,
					keyPath: returnedQst.selectedAnswer.keyPath,
					value:
						returnedQst.selectedAnswer.answerValue !== ""
							? returnedQst.selectedAnswer.answerValue
							: returnedQst.selectedAnswer.answerChecked
				});
			} else {
				forEach(returnedQst.selectedAnswer, (answerItem) => {
					mappedData.push({
						questionId: item.questionId,
						answerId: answerItem.answerID,
						keyPath: answerItem.keyPath,
						value:
							answerItem.answerValue !== ""
								? answerItem.answerValue
								: answerItem.answerChecked
					});
				});
			}
		}
	});

	return mappedData;
};

export const retrieveParamsValues = (simuParams: any) => {
	let mappedData: any;
	const zipCode: string = get(simuParams, "Postal", "");
	const postalCode: any = find(
		postalCodeList,
		(item) => String(item.code) === zipCode
	);

	mappedData = {
		isPro: (get(simuParams, "ProfessionalProfil", 0) === 0 || get(simuParams, "ProfessionalProfil", 0) === "0") ? 0 : 1,
		postalCode: postalCode || emptyPostCode,
		hasDigitalCounter: get(simuParams, "ElecDigitalMeter", 0) === 1,
		elecCounterType: get(simuParams, "TypeOfCounter", 1),
		hasExlNightEanCode: get(simuParams, "ElecOnlyNightRequired", "false") === "true",
		hasPanels: get(simuParams, "ElecPV", 0) === 1,
		autoDeterminedInj: get(simuParams, "HousingType", 0) !== 0
	};

	//console.log("retrieveParamsValues: ", mappedData);
	return mappedData;
};

export const formatStepOneData = (data: any) => {
	let mappedData: any;

	mappedData = {
		personalData: {
			companyName: get(data, "companyName"),
			companyForm: get(data, "companyForm"),
			tvaNumber: get(data, "tvaNumber"),
			tvaNumberInProgress: get(data, "tvaNumberInProgress"),
			civility: get(data, "civility"),
			firstName: get(data, "firstName"),
			lastName: get(data, "lastName"),
			gsm: get(data, "gsm"),
			email: get(data, "email"),
			dateOfBirth: get(data, "dateOfBirth")
		},
		delivery: {
			address: {
				street: get(data, "street"),
				streetNumber: get(data, "streetNumber"),
				mailBox: get(data, "mailBox"),
				postalCode: {
					code: get(data, "postalCode.code"),
					locality: get(data, "postalCode.locality")
				}
			},
			elec: {
				elecEanCode: get(data, "elecEanCode"),
				elecCounterStatus: get(data, "elecCounterStatus")
			},
			gas: {
				gasEanCode: get(data, "gasEanCode"),
				gasCounterStatus: get(data, "gasCounterStatus")
			},
			startDate: get(data, "startDate"),
			currentSituation: get(data, "currentSituation")
		},
		billing: {
			address: {
				billStreet: get(data, "sameAsInvoicing", true)
					? get(data, "street", "")
					: get(data, "billStreet", ""),
				billStreetNumber: get(data, "sameAsInvoicing", true)
					? get(data, "streetNumber", "")
					: get(data, "billStreetNumber", ""),
				billMailBox: get(data, "sameAsInvoicing", true)
					? get(data, "mailBox", "")
					: get(data, "billMailBox", ""),
				billPostalCode: get(data, "sameAsInvoicing", true)
					? get(data, "postalCode", "")
					: get(data, "billPostalCode", "")
			},
			method: 2,// Default value: Transfer due to New prices.
			frequency: 1,// Default value: Monthly due to New prices.
			invoiceMethod: 1,// Default value: E-mail due to New prices.
			comment: get(data, "comment", "")
		}
	};

	return mappedData;
};

export const formatWebContractEG = (
	leadData: any,
	language: string,
	ipAddress: string,
	subscriptionId: string,
	complData: any,
	simulation: any
) => {
	let elecAutoDetermined = false;
	let gasAutoDetermined = false;
	const partner = complData.partner;
	const isWallonia = getRegionByPostCode(get(leadData, "delivery.address.postalCode.code", "")) == WALLONIA;
	elecAutoDetermined =
		get(leadData, "flags.hasElec", false) ||
		(get(leadData, "flags.hasElec", false) &&
			get(leadData, "flags.hasGas", false));
	gasAutoDetermined =
		get(leadData, "flags.hasGas", false) ||
		(get(leadData, "flags.hasElec", false) &&
			get(leadData, "flags.hasGas", false));

	let contract: any = {
		MembreAgent: get(partner, "agentMember", ""), // TODO Retrieve from partner config.
		Individual: get(leadData, "personalData.isPro", "") === 1 ? 0 : 1,
		CodeTitre: get(leadData, "personalData.civility", ""),
		Nom: get(leadData, "personalData.lastName", ""),
		Prenom: get(leadData, "personalData.firstName", ""),
		Rue: get(leadData, "billing.address.billStreet", ""),
		Maison: get(leadData, "billing.address.billStreetNumber", ""),
		Boite: get(leadData, "billing.address.billMailBox", ""),
		Postal: get(leadData, "billing.address.billPostalCode.code", ""),
		Localite: get(leadData, "billing.address.billPostalCode.locality", ""),
		Pays: "BE",
		LivRue: get(leadData, "delivery.address.street", ""),
		LivMaison: get(leadData, "delivery.address.streetNumber", ""),
		LivBoite: get(leadData, "delivery.address.mailBox", ""),
		LivPostal: get(leadData, "delivery.address.postalCode.code", ""),
		LivLocalite: get(leadData, "delivery.address.postalCode.locality", ""),
		PostLocTitulaire: "",
		RueNoTitulaire: "",
		LivPays: "BE",
		Langue: formatLang(language),
		TelP: "",
		Gsm: get(leadData, "personalData.gsm", ""),
		Email: get(leadData, "personalData.email", ""),
		// NoRegistreNational: "", // TODO Still needed ?; 404 Excel file
		NoTva:
			get(leadData, "personalData.tvaNumberInProgress", false) === true
				? "A"
				: get(leadData, "personalData.tvaNumber", ""),
		NomSociete: get(leadData, "personalData.companyName", ""),
		RaisonSociale: get(leadData, "personalData.companyForm", ""),
		AssujettiTva:
			get(leadData, "personalData.isPro", "") === 1
				? get(leadData, "personalData.tvaNumberAvailability", 1)
				: 0,
		Demenagement:
			get(leadData, "delivery.currentSituation", "") === 2 ? 1 : 0,
		MaisonVide: get(leadData, "delivery.currentSituation", "") === 3 ? 1 : 0,
		// DemandeInfoMazout: false, // 404 Excel file.
		// DemandeInfoCC: false, // 404 Excel file.
		DateSouscription: moment().format("DD/MM/YYYY"),
		HeureSouscription: moment().format("HH:mm:ss"),
		IPSouscription: ipAddress,
		PrivacyDate: get(leadData, "confirmation.privacyDate", ""),
		PrivacyHeure: get(leadData, "confirmation.privacyHour", ""),
		CGVDate: get(leadData, "confirmation.generalCDate", ""),
		CGVHeure: get(leadData, "confirmation.generalCHour", ""),
		NoSouscription: subscriptionId,
		Agent:
			leadData.personalData.promoCode !== ""
				? get(leadData, "personalData.promoCode", "")
				: get(partner, "agent", ""),
		ModePaiement: get(leadData, "billing.method", ""),
		PeriodAcompte: get(leadData, "billing.frequency", ""),
		FactPapier: false, // get(leadData, "billing.invoiceMethod") === 2; Default value "false" due to new prices;
		FactMail: true, // get(leadData, "billing.invoiceMethod") === 1; Default value "true" due to new prices;
		FactAcompte: (get(leadData, "billing.allInvoices") === true) ? 1 : 0,
		Option1:
			get(leadData, "confirmation.telSupport", false) === true ? 1 : "",
		Option2: "",
		AppAffaireRef: get(partner, "salesGenerator", ""), // TODO Retrieve from partner config.
		CompteBanque: get(leadData, "billing.accountNumber", ""),
		CodeBIC: get(leadData, "billing.bicCode", ""),
		NoCompteRemb: "", // TODO Is needed ?
		NoCompteRembBIC: "", // TODO Is needed ?
		NomTitulaire: "",
		CusId: "",
		ParrainCusId: get(leadData, "personalData.refCustomerId", ""),
		DateMandat: get(leadData, "billing.mandateDate", ""),
		HeureMandat: get(leadData, "billing.mandateHour", ""),
		DejaClient: "0",
		MandatId: get(leadData, "billing.method", "") === 1
			? get(leadData, "billing.mandateId", "")
			: "",
		Marketing: get(leadData, "confirmation.withMarketing", "") ? 1 : 0,
		NewsLetter: get(leadData, "confirmation.withNewsletter", "") ? 1 : 0,
		// ElecNomProduitPartenaire: "", // TODO Add ProductName from Survey step. ; 404 Excel file.
		// GazNomProduitPartenaire: "", // TODO Add ProductName from Survey step. ; 404 Excel file.
		RemarqueClient: get(leadData, "confirmation.remark", ""),
		DateNaiss: get(leadData, "personalData.dateOfBirth", ""),
		ProfessionalProfil: get(simulation, "params.ProfessionalProfil", ""),
		GazConsoInjAutoDeterminees: gasAutoDetermined,
		ElecTakeOver: 0,
		ElecContestation: 0,
		GazTakeOver: 0,
		GazContestation: 0,
		NoSimulation: get(simulation, "params.SimulationNumber", ""),
		TypeChauffageCentral: get(simulation, "params.HeatingEnergy", ""),
		CompositionMenage: get(simulation, "params.FamilyComposition", ""),
		TypeHabitat: get(simulation, "params.HousingType", 0),
		ElecConsoInjAutoDeterminees: elecAutoDetermined,
		ElecConsoJour: get(simulation, "params.ConsumptionElecDay", ""),
		ElecConsoNuit: get(simulation, "params.ConsumptionElecBiNight", ""),
		ElecConsoExclNuit: get(simulation, "params.ConsumptionElecOnlyNight", ""),
		ElecInjJour: get(simulation, "params.InjectionElecDay", ""),
		ElecInjNuit: get(simulation, "params.InjectionElecBiNight", ""),
		GazConso: get(simulation, "params.ConsumptionGas", ""),
		Panneaux: get(simulation, "params.ElecPV", 0), // Y(1)/N(0)
		Injection: get(leadData, "delivery.elec.addInjection", 0) === 2 ? 1 : 0, // TODO To verify. Y(1)/N(0)
		RefClient: get(leadData, "billing.comment", ""),
		ElecPeakPower: trim(replace(get(simulation, "params.ElecPeakPower", ""), ",", ".")),
		ElecCompensationIsAuth: !!isWallonia && get(simulation, "params.ElecPV", 0) == 1 && get(simulation, "params.ElecCompensationIsAuth", "") == "false" ? false : true,
		ElecMeteringRegime: get(simulation, "params.ElecMeteringRegime", ""),
		SwTest: get(envConfig.publicRuntimeConfig, "wbTest", 0)
	};

	if (leadData.flags.hasElec) {
		contract = {
			...contract,
			ElecMatrixCode: get(simulation, "params.ElecMatrixCode", ""),
			ElecIndexJourInj: "", // TODO Add condition on Inj flag.
			ElecIndexNuitInj: "", // TODO Add condition on Inj flag.
			ElecDureeContrat: get(simulation, "selectedProduct.elec.Duration", ""),
			ElecCodeProduit: get(simulation, "selectedProduct.elec.Product", ""),
			MntAcompteE: parseFloat(replace(get(simulation, "selectedProduct.elec.monthlyAmount", ""), ',', '.')),
			ElecTypeCompteur: get(simulation, "params.TypeOfCounter", ""),
			ElecNoCompteur: get(leadData, "delivery.elec.elecCounterNumber", ""),
			ElecCodeEan: get(leadData, "delivery.elec.elecEanCode", ""),
			ElecIndexDate: get(leadData, "delivery.elec.elecIndexDate", ""),
			ElecIndexJour: trim(get(leadData, "delivery.elec.elecDayIndex", "")),
			ElecIndexNuit: trim(get(leadData, "delivery.elec.elecNightIndex", "")),
			ElecTypeRaccordement: get(
				leadData,
				"delivery.elec.elecCounterStatus",
				""
			),
			ElecDateDebSouhaite: get(leadData, "delivery.startDate", ""),
			ElecFournisseurActuel: get(leadData, "delivery.lastSupplier", ""),
			ElecCodeEanExclNuit: get(
				leadData,
				"delivery.elec.elecExlNightEanCode",
				""
			), // TODO Value ?
			ElecIndexExclNuit: trim(get(
				leadData,
				"delivery.elec.elecExclNightIndex",
				""
			)),
			ElecNoCompteurExclNuit: "", // TODO Value ?
			ElecSmartMeter: isWallonia && get(simulation, "params.ElecCompensationIsAuth", "") == "false" ? 1 : get(simulation, "params.ElecDigitalMeter", 0), // Y(1)/N(0)
			ElecDrive: get(simulation, "params.EleceDrive", 0), // Y(1)/N(0)
			PanneauxKVA: get(simulation, "params.SolarPanels", ""),
			ElecNomProduitPartenaire: get(
				simulation,
				"selectedProduct.elec.ProductNameFr",
				""
			),
			ElecInjSupConso: 0
		};
	}
	if (leadData.flags.hasGas) {
		contract = {
			...contract,
			GazMatrixCode: get(simulation, "params.GazMatrixCode", ""),
			GazDureeContrat: get(simulation, "selectedProduct.gas.Duration", ""),
			GazCodeProduit: get(simulation, "selectedProduct.gas.Product", ""),
			MntAcompteG: parseFloat(replace(get(simulation, "selectedProduct.gas.monthlyAmount", ""), ',', '.')),
			GazNoCompteur: get(leadData, "delivery.gas.gasCounterNumber", ""),
			GazCodeEan: get(leadData, "delivery.gas.gasEanCode", ""),
			GazIndexDate: get(leadData, "delivery.gas.gasIndexDate", ""),
			GazIndex: trim(get(leadData, "delivery.gas.gasIndex", "")),
			GazTypeRaccordement: get(leadData, "delivery.gas.gasCounterStatus", ""),
			GazDateDebSouhaite: get(leadData, "delivery.startDate", ""),
			GazFournisseurActuel: get(leadData, "delivery.lastSupplier", ""),
			GazNomProduitPartenaire: get(
				simulation,
				"selectedProduct.gas.ProductNameFr",
				""
			)
		};
	}

	return contract;
};

export const productsFilterByProfile = (productList: any, isPro: boolean, postCode: string) => {
	if (isPro) {
		return filter(productList, (product) => product.ProductType === GREY_PRODUCT || product.Product === "604" || product.Product === "641" || product.Product === "638");
	}
	if (brusselsPostCodeCheck(parseInt(postCode, 10))) {
		return [];
	}

	return productList;
};

export const formatSimulationData = (productList: any, postalCode: any) => {
	return orderProducts(
		filter(productList, (element) => {
			return element;
		}));
};

export const getSurveySummary = (surveyAnswers: any[]) => {
	let consumptionList: any = [];
	const fomatedSurvey = map(surveyAnswers, (element) => {
		if (element.questionID == POSTAL_QUESTION_ID) {
			return {
				answerID: get(first(element.selectedAnswer), "answerID"),
				answer: get(first(element.selectedAnswer), "answerValue.code")
			};
		}
		if (
			element.questionID == CONSUMPTION_DETAILS_QUESTION_ID ||
			element.questionID == INJECTION_CONSUMPTION_DETAILS_QUESTION_ID
		) {
			const answersObject = map(element.selectedAnswer, (item) => ({
				answerID: item.answerID,
				answer: item.answerValue
			}));
			consumptionList = consumptionList.concat(answersObject);
		} else {
			return {
				answerID: get(first(element.selectedAnswer), "answerID"),
				answer: get(first(element.selectedAnswer), "answerValue")
			};
		}
	});

	const returnedResult = compact(fomatedSurvey.concat(consumptionList));

	return returnedResult;
};

export const getAnswerByQuestionObject = (question: any, answerID?: any, locality?: any) => {
	// //console.log('***********selectedAnswer****************', get(first(question.selectedAnswer), 'answerValue.code'))
	if (question) {
		if (question.questionID == POSTAL_QUESTION_ID && !locality) {
			const postalAnswer = find(question.selectedAnswer, (item) => item.answerID == 'AN6')
			return get(postalAnswer, "answerValue.code");
		}
		if (question.questionID == POSTAL_QUESTION_ID && locality) {
			const postalAnswer = find(question.selectedAnswer, (item) => item.answerID == 'AN6')
			return get(postalAnswer, "answerValue.locality");
		}
		if (question.questionID == "Q5") {
			const answerObj = find(
				question.selectedAnswer,
				(element) => element.answerID == "AN9" || element.answerID == "AN10"
			);
			return get(answerObj, "answerValue");
		}
		if (question.questionID == "Q8") {
			let answerObj = "";
			const customSolar = find(
				question.selectedAnswer,
				(element) => element.answerID == "AN14"
			);
			if (customSolar) {
				answerObj = customSolar
			} else {
				answerObj = find(
					question.selectedAnswer,
					(element) => element.answerID == "AN15"
				);
			}
			return get(answerObj, "answerValue");
		}
		if (question.questionID == "Q19") {
			let answerObj = "";
			const customSolar = find(
				question.selectedAnswer,
				(element) => element.answerID == "AN47"
			);
			if (customSolar) {
				answerObj = customSolar
			} else {
				answerObj = find(
					question.selectedAnswer,
					(element) => element.answerID == "AN48"
				);
			}
			return get(answerObj, "answerValue");
		}
		if (question.questionID == "Q10" || question.questionID == "Q12") {
			const answerObj = find(
				question.selectedAnswer,
				(element) => element.answerID == answerID
			);
			return get(answerObj, "answerValue", "");
		}
		return get(first(question.selectedAnswer), "answerValue");
	}
	return ""
}

export const buildSelectedProductObject = (product: IProduct, currentSelectedProduct: ISelectedProduct) => {
	if (product.Nrj == "E") {
		return { elec: product, gas: currentSelectedProduct.gas }
	}
	if (product.Nrj == "G") {
		return { elec: currentSelectedProduct.elec, gas: product }
	}
}

export const isProfessionalUser = (answersList: IQuestion[]) => {
	const userTypeObject = find(answersList, (item) => item.questionID == 'Q1');
	if (userTypeObject) {
		const userAnswer = last(userTypeObject.selectedAnswer);
		return get(userAnswer, 'answerValue') == "pro"
	}
	return false
}

export const getInjectionsSamplingAverage = (userAnswers: IQuestion[]) => {
	let panelsPower = 0;
	let brutPanelsPower = 0;
	let dayConso = 0;
	let nightconso = 0;
	let isNetConso = false;
	const isBiHoraire: Boolean = isBiHoraireCounter(userAnswers);
	const isProfessional: Boolean = isProfessionalUser(userAnswers);
	const panelsPowerQuestion = find(userAnswers, (item) => item.questionID == "Q8");
	// //console.log('***********XX panelsPowerQuestion*************', panelsPowerQuestion)
	const ConsoQuestion = find(userAnswers, (item) => item.questionID == "Q10");
	if (panelsPowerQuestion) {
		const panelsPowerAnswer = find(panelsPowerQuestion.selectedAnswer, (item) => item.answerID == "AN14" || item.answerID == "AN15");
		// //console.log('*********panelsPowerAnswer*********', panelsPowerAnswer)
		const panelsPowerValue: any = parseFloat(replace(get(panelsPowerAnswer, 'answerValue', 1), ',', '.'));
		// //console.log('***********XX panelsPowerValue*************', panelsPowerValue)
		panelsPower = panelsPowerValue * 900 * 0.6;
		// //console.log('*********Injection*********', panelsPowerValue)
		brutPanelsPower = (panelsPowerValue * 900 * 0.4);
	}
	if (ConsoQuestion) {
		const isNetConsoAnswer = find(ConsoQuestion.selectedAnswer, (item) => item.answerID == "AN41");
		const dayConsoAnswer = find(ConsoQuestion.selectedAnswer, (item) => item.answerID == "AN19");
		const nightConsoAnswer = find(ConsoQuestion.selectedAnswer, (item) => item.answerID == "AN38");
		isNetConso = get(isNetConsoAnswer, 'answerChecked', false);
		dayConso = parseFloat(get(dayConsoAnswer, 'answerValue', 0));
		nightconso = parseFloat(get(nightConsoAnswer, 'answerValue', 0));
	}

	// //console.log('**********XX (panelsPower * (isProfessional ? 0.8 : 0.7))*********', (panelsPower * (isProfessional ? 0.8 : 0.7)))
	// //console.log('*****XX panelsPower**************', panelsPower)
	const dayInjectionValue = (isBiHoraire ? (panelsPower * (isProfessional ? 0.8 : 0.7)) : panelsPower);
	const nightInjectionValue = isBiHoraire ? (panelsPower * (isProfessional ? 0.2 : 0.3)) : 0;
	const daySamplingValue = isBiHoraire
		? (
			isNetConso
				? ((dayConso + panelsPower))
				: (((dayConso) - brutPanelsPower))
		) : (
			isNetConso
				? ((dayConso + nightconso + panelsPower))
				: (((dayConso + nightconso) - brutPanelsPower))
		);
	const nightSamplingValue = isBiHoraire
		? (
			isNetConso
				? ((nightconso + panelsPower))
				: (((nightconso) - brutPanelsPower)))
		: 0

	// //console.log('******************dayInjectionValue*********', dayInjectionValue)
	// //console.log('******************nightInjectionValue*********', nightInjectionValue)
	// //console.log('******************Math.round(dayInjectionValue)*********', Math.round(dayInjectionValue))
	// //console.log('******************Math.round(nightInjectionValue)*********', Math.round(nightInjectionValue))
	return {
		dayInjection: Math.round(dayInjectionValue),
		nightInjection: Math.round(nightInjectionValue),
		daySampling: (daySamplingValue > 0) ? Math.round(daySamplingValue) : 0,
		nightSampling: (nightSamplingValue > 0) ? Math.round(nightSamplingValue) : 0
	};
}

export const orderProducts = (productList: any) => {

	return orderBy(productList, ['ProductType', 'Ranking'], ['asc', 'asc']);
};

const orderProductsByType = (productList: any) => {
	return orderBy(productList, ['ProductType'], ['asc'])
}

const orderProductsByRanking = (productList: any) => {
	return orderBy(productList, ['Ranking'], ['asc'])
}

export const isHTML = (value: any) => {
	const htmlRegex = new RegExp("/<[a-z/][\s\S]*>/i");
	return htmlRegex.test(value);
}

export const formatBspLead = (lead: any, simulationParam: any, simulation: any, selectedProduct: any) => {
	return {
		...lead,
		liv_zip: get(simulationParam, 'Postal', ''),
		liv_city: get(simulationParam, 'Locality', ''),
		isIndividual: isEqual(get(simulationParam, 'ProfessionalProfil', ''), 0) ? 1 : 0,
		hasDigitalCounter: isEqual(get(simulationParam, 'ElecDigitalMeter', ''), 1) ? true : false,
		askFuelOil: isEqual(get(simulationParam, 'HeatingEnergy', ''), 3) ? true : false,
		NoSimulation: `${get(simulationParam, 'SimulationNumber', '')}`,
		ElecConsoJour: get(simulationParam, "ConsumptionElecDay", ""),
		ElecConsoNuit: get(simulationParam, "ConsumptionElecBiNight", ""),
		ElecConsoExclNuit: get(simulationParam, "ConsumptionElecOnlyNight", ""),
		GazConso: get(simulationParam, "ConsumptionGas", ""),
		MntAcompteE: get(selectedProduct, "elec.monthlyAmount", ""),
		MntAcompteG: get(selectedProduct, "gas.monthlyAmount", ""),
		simulation
	};
}

export const formatBspElec = (elec: any, simulationParam: any) => {

	return {
		...elec,
		hasPanels: get(simulationParam, 'ElecPV', 0),
		CompensationIsAuth: get(simulationParam, "ElecCompensationIsAuth", "") == "false" ? false : true,
		meter_type: get(simulationParam, 'TypeOfCounter', 1),
		index_date: isEmpty(elec.index_date) || includes(toLower(elec.index_date), 'invalid') ? '' : elec.index_date,
		start_date: isEmpty(elec.start_date) || includes(toLower(elec.start_date), 'invalid') ? '' : elec.start_date
	};
}

export const formatBspGas = (gas: any, simulationParam: any) => {
	return {
		...gas,
		index_date: isEmpty(gas.index_date) || includes(toLower(gas.index_date), 'invalid') ? '' : gas.index_date,
		start_date: isEmpty(gas.start_date) || includes(toLower(gas.start_date), 'invalid') ? '' : gas.start_date,
	};
}


export const convertBspContractToLead = (contract: IBspContract) => {
	return {
		personalData: convertBspLeadToUserData(contract),
		delivery: convertBspLeadToDelivery(contract),
		billing: convertBspLeadToBilling(contract),
		confirmation: emptyConfirmation,
		flags: convertBspLeadToFlags(contract),
	}
}

export const convertBspLeadToUserData = (contract: IBspContract) => {
	return {
		isPro: isEqual(contract.isIndividual, 0) ? 1 : 0,
		companyName: contract.company_name,
		companyForm: contract.company_status,
		tvaNumber: contract.tva_number,
		tvaNumberAvailability: 1,
		tvaNumberInProgress: false,
		civility: contract.title,
		firstName: contract.first_name,
		lastName: contract.last_name,
		gsm: contract.gsm,
		email: contract.email,
		dateOfBirth: !isEmpty(contract.Birthdate) ? moment(contract.Birthdate).format('DD/MM/YYYY') : '',
		refCustomerId: "",
		promoCode: ""
	}
}

export const convertBspLeadToDelivery = (contract: IBspContract) => {
	const formatedAddress = convertBspLeadToAddress(contract);
	const formatedElec = convertBspLeadToElec(contract);
	const formatedGas = convertBspLeadToGas(contract);
	return {
		address: formatedAddress,
		elec: formatedElec,
		gas: formatedGas,
		startDate: moment(contract.elec_start_date).format('DD/MM/YYYY') || moment(contract.gas_start_date).format('DD/MM/YYYY'),
		sameAsInvoicing: true,
		hasInternetProduct: false,
		currentSituation: 1
	}
}

export const convertBspLeadToBilling = (contract: IBspContract) => {
	const formatedBillingAddress = convertBspLeadToBillingAddress(contract);
	return {
		address: formatedBillingAddress,
		method: "2",
		frequency: "1",
		invoiceMethod: 1,// Default value: E-mail due to New prices.
		comment: "",
		accountNumber: "",
		bicCode: "",
		accountHolder: "",
		mandateId: "",
		domAuthorization: false,
		mandateDate: "",
		mandateHour: "",
		allInvoices: false
	}
}

export const convertBspLeadToConfirmation = (contract: IBspContract) => {
	return {
		withNewsletter: false,
		withMarketing: false,
		telSupport: false,
		generalConditions: false,
		privacyDate: "",
		privacyHour: "",
		generalCDate: "",
		generalCHour: ""
	}
}

export const convertBspLeadToFlags = (contract: IBspContract) => {
	return {
		hasElec: !isEqual(get(contract, 'idelec'), '') && !isNull(get(contract, 'idelec')),
		hasGas: !isEqual(get(contract, 'idgas'), '') && !isNull(get(contract, 'idgas'))
	}
}

export const convertBspLeadToAddress = (contract: IBspContract) => {
	return {
		street: contract.liv_street_name || contract.fact_street_name,
		streetNumber: contract.liv_street_number || contract.fact_street_number,
		mailBox: contract.liv_zip || contract.fact_zip,
		postalCode: {
			code: contract.liv_zip || contract.fact_zip,
			locality: contract.liv_street_name || contract.fact_street_name
		}
	}
}

export const convertBspLeadToBillingAddress = (contract: IBspContract) => {
	return {
		billStreet: contract.fact_street_name || contract.liv_street_name,
		billStreetNumber: contract.fact_street_number || contract.liv_street_number,
		billMailBox: contract.fact_zip || contract.liv_zip,
		billPostalCode: {
			code: contract.fact_zip || contract.liv_zip,
			locality: contract.fact_street_name || contract.liv_street_name
		}
	}
}

export const convertBspLeadToElec = (contract: IBspContract) => {
	return {
		elecEanCode: contract.elec_ean_code,
		elecExlNightEanCode: contract.elec_ean_code_night,
		hasExlNightEanCode: !isEmpty(contract.elec_ean_code_night),
		hasPanels: contract.elec_hasPanels,
		hasDigitalCounter: false,
		elecCounterStatus: contract.elec_connection_type,
		elecCounterType: contract.elec_meter_type,
		elecCounterNumber: contract.elec_meter_code,
		elecIndexDate: !isEmpty(contract.elec_index_date) ? moment(contract.elec_index_date).format('DD/MM/YYYY') : '',
		elecDayIndex: contract.elec_day_index,
		elecNightIndex: contract.elec_night_index,
		elecExclNightIndex: contract.elec_ex_night_index,
		addInjection: 1
	}
}

export const convertBspLeadToGas = (contract: IBspContract) => {

	return {
		gasEanCode: contract.gas_ean_code,
		gasCounterStatus: contract.gas_connection_type,
		gasCounterNumber: contract.gas_meter_code,
		gasIndexDate: !isEmpty(contract.gas_index_date) ? moment(contract.gas_index_date).format('DD/MM/YYYY') : '',
		gasIndex: contract.gas_index
	}
}

export const getLangByRegion = (
	userLang: string,
	postCode: number | string
) => {
	// Brussels region 1000 - 1299
	if (postCode >= 1000 && postCode <= 1299) {
		return userLang;
	}

	// Wallonie region 1300 - 1499 and 4000 - 7999
	if (
		(postCode >= 1300 && postCode <= 1499) ||
		(postCode >= 4000 && postCode <= 7999)
	) {
		return "fr";
	}

	// Flanders region 1500 - 3999 and 8000+
	if ((postCode >= 1500 && postCode <= 3999) || postCode >= 8000) {
		return "nl";
	}

	return "fr";
};

export const getRegionByPostCode = (
	postCode: number | string
) => {
	// Brussels region 1000 - 1299
	if (postCode >= 1000 && postCode <= 1299) {
		return BRUSSELS;
	}

	// Wallonie region 1300 - 1499 and 4000 - 7999
	if (
		(postCode >= 1300 && postCode <= 1499) ||
		(postCode >= 4000 && postCode <= 7999)
	) {
		return WALLONIA;
	}

	// Flanders region 1500 - 3999 and 8000+
	if ((postCode >= 1500 && postCode <= 3999) || postCode >= 8000) {
		return FLANDERS;
	}

	return null;
};

export const formatGetFunnelPropsReq = (energy: string, userDetails: any) => {

	return {
		Nrj: energy,
		TypeContrat: get(userDetails, "userType", ""),
		Postal: get(userDetails, "postCode", ""),
		Locality: "",
		HaveDigitalMeter: get(userDetails, "hasDigitalMeter", ""),
		HavePV: energy == IS_ELEC ? get(userDetails, "hasPanels", false) : false,
		HaveeDrive: energy == IS_ELEC ? get(userDetails, "hasEDrive", false) : false,
		InjSupConso: get(userDetails, "injSupConso", ""),
		SalesGeneratorAgent: "",
		SalesGeneratorDotNet: "",
		CompensationIsAuth: get(userDetails, "compensationIsAuth", true),
		swDotNet: true
	};
};

export const computeConsumptionAndInjection = (userDetails: any, indexes: any, isConsumptionNet: boolean) => {
	let panelsPower = 0;
	let brutPanelsPower = 0;
	let dayCoef = 0;
	let nightCoef = 0;
	const isBiHoraire: Boolean = !userDetails.isMono;
	const isProfessional: Boolean = userDetails.userType == PROFESSIONAL;

	if (isProfessional) {
		dayCoef = 0.8;
		nightCoef = 0.2;
	} else {
		dayCoef = 0.7;
		nightCoef = 0.3;
	}

	const panelsPowerValue: any = parseFloat(replace(userDetails.panelsPower, ',', '.'));

	panelsPower = panelsPowerValue * 900 * 0.6;

	brutPanelsPower = (panelsPowerValue * 900 * 0.4);

	let dayConso = 0;
	let nightconso = 0;

	let dayInjectionValue;
	let nightInjectionValue;
	let daySamplingValue;
	let nightSamplingValue;

	if (isBiHoraire) {
		dayConso = parseFloat(get(indexes, 'elecIndex', "0"));
		nightconso = parseFloat(get(indexes, 'elecNightIndex', "0"));
		dayInjectionValue = panelsPower * dayCoef;
		nightInjectionValue = panelsPower * nightCoef;

		if (isConsumptionNet) {
			daySamplingValue = dayConso + panelsPower;
			nightSamplingValue = nightconso + panelsPower;
		} else {
			daySamplingValue = dayConso - brutPanelsPower;
			nightSamplingValue = nightconso - brutPanelsPower;
		}
	} else {
		dayConso = parseFloat(get(indexes, 'elecIndex', "0"));
		nightconso = 0;
		dayInjectionValue = panelsPower;
		nightInjectionValue = 0;

		if (isConsumptionNet) {
			daySamplingValue = dayConso + nightconso + panelsPower;
		} else {
			daySamplingValue = (dayConso + nightconso) - brutPanelsPower;
		}
		nightSamplingValue = 0;
	}

	return {
		elecPreIndex: (daySamplingValue > 0) ? Math.round(daySamplingValue) : 0,
		elecPreNightIndex: (nightSamplingValue > 0) ? Math.round(nightSamplingValue) : 0,
		elecInjIndex: Math.round(dayInjectionValue),
		elecInjNightIndex: Math.round(nightInjectionValue)
	};
};

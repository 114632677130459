import React, {useEffect, useState} from "react";
import { Grid, Paper, Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CopyrightIcon from "@mui/icons-material/Copyright";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {Trans, useTranslation} from "react-i18next";
import moment from "moment";
import cx from "classnames";
import theme from "../../theme";
import colors from "../../../utils/colors";
import i18n from "../../../i18n";
// import StarIcon from '../../../';

const useStyle = makeStyles({
	paperElevation0: {
		position: "relative",
		width: "100%"
	},
	upperFooter: {
		[theme.breakpoints.down('md')]: {
			"& img": {
				width: "100%"
			}
		}
	},
	lowerFooter: {
		padding: "0 4%",
		height: "7.3em",
		background: colors.octaBlue,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		flexWrap: "wrap",
		alignItems: "center",
		[theme.breakpoints.down('md')]: {
			flexDirection: "column-reverse",
			flexWrap: "initial",
			padding: "20px 0",
			height: "180px"
		}
	},
	footerAlignment: {
		[theme.breakpoints.down('md')]: {
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center"
		}
	},
	footerIconsAlignment: {
		padding: "0 35px",
		[theme.breakpoints.down('md')]: {
			padding: "8px 35px",
			alignItems: "baseline",
			justifyContent: "center"
		}
	},
	footerText: {
		color: "#b9b2b2",
		fontSize: "12px",
		[theme.breakpoints.only("xs")]: {
			fontSize: "14px"
		},
		"& a": {
			textDecoration: "none",
			textTransform: "uppercase",
			color: "#b9b2b2"
		}
	},
	copyRight: {
		margin: "0 1px",
		width: "14px",
		height: "14px",
		position: "relative",
		top: "2px",
		[theme.breakpoints.only("xs")]: {
			fontSize: "12px",
			width: "12px",
			height: "12px"
		}
	},
	socialMediaIcon: {
		margin: "0 5px",
		width: "22px",
		height: "22px",
		position: "relative",
		top: "2px",
		color: colors.white,
		[theme.breakpoints.only("xs")]: {
			fontSize: "12px",
			width: "20px",
			height: "20px"
		}
	},
	copyrightContainer: {
		paddingBottom: "3px"
	},
	footerDivider: {
		margin: "0 5px",
		color: "#b9b2b2",
		[theme.breakpoints.down('md')]: {
			display: "none"
		}
	}
});

const Footer = () => {
	const classes = useStyle();
	const { t } = useTranslation("common");
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const i18nFlag = i18n.isInitialized;
		console.log("i18nFlag ", i18nFlag);
		if (i18nFlag) {
			setIsLoading(false);
		}
	}, [i18n.isInitialized]);

	return (
        <Paper
			elevation={0}
			square
			classes={{ elevation0: classes.paperElevation0 }}
			className={classes.lowerFooter}
		>
			{(isLoading) ? (
				<></>
			) : (
				<>
					<Grid
						container
						item
						direction="row"
						alignItems="center"
						xs={12}
						sm={7}
						md={5}
						className={classes.footerAlignment}
					>
						<Typography variant="body2" className={cx(classes.footerText, classes.copyrightContainer)}>
							<CopyrightIcon className={classes.copyRight} />
							{t("footer.octaCompany", { year: moment().year() })}
						</Typography>
						<span className={classes.footerDivider}> | </span>
						<Typography variant="body2" className={classes.footerText}>
							<a
								href={t("footer.privacyPolicyLink")}
								target="_blank"
								rel="noreferrer"
							>
								{t("footer.privacyPolicy")}
							</a>
						</Typography>
						<span className={classes.footerDivider}> | </span>
						<Typography variant="body2" className={classes.footerText}>
							<a
								href={t("footer.contact_link")}
								target="_blank"
								rel="noreferrer"
							>
								{t("footer.contact")}
							</a>
						</Typography>
						<span className={classes.footerDivider}> | </span>
						<Typography variant="body2" className={classes.footerText}>
							<Trans
								defaults={t("footer.price_docs")}
								components={[
									<Link
                                        href={t("footer.price_docs_link")}
                                        target="_blank"
                                        rel="noreferrer"
                                        underline="hover"></Link>
								]}
							/>
						</Typography>
						<span className={classes.footerDivider}> | </span>
						<Typography variant="body2" className={classes.footerText}>
							<a
								href={t("footer.jobs_link")}
								target="_blank"
								rel="noreferrer"
							>
								{t("footer.jobs")}
							</a>
						</Typography>
					</Grid>
					<Grid
						container
						item
						direction="row"
						justifyContent="flex-end"
						xs={12}
						sm={5}
						md={4}
						className={cx(classes.footerIconsAlignment)}
					>
						<Typography variant="body2" className={classes.footerText}>
							<a href={t("footer.facebookLink")} target="_blank" rel="noreferrer">
								<img
									src="/img/social-fb.svg"
									className={classes.socialMediaIcon}
								/>
							</a>
						</Typography>
						<Typography variant="body2" className={classes.footerText}>
							<a href={t("footer.twitterLink")} target="_blank" rel="noreferrer">
								<img
									src="/img/social-twitter.svg"
									className={classes.socialMediaIcon}
								/>
							</a>
						</Typography>
						<Typography variant="body2" className={classes.footerText}>
							<a href={t("footer.linkedinLink")} target="_blank" rel="noreferrer">
								<img
									src="/img/social-linkedin.svg"
									className={classes.socialMediaIcon}
								/>
							</a>
						</Typography>
					</Grid>
				</>
			)}
		</Paper>
    );
};

export default Footer;

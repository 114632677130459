import { IBillingAddress, emptyBillingAddress } from "./BillingAddress";

export interface IBilling {
	address: IBillingAddress;
	method: number;
	frequency: number;
	invoiceMethod: number;
	comment: string;
	accountNumber: string;
	bicCode: string;
	accountHolder: string;
	mandateId: string;
	domAuthorization: boolean;
	mandateDate: string;
	mandateHour: string;
	allInvoices: boolean;
}

export const emptyBilling: IBilling = {
	address: emptyBillingAddress,
	method: 2,
	frequency: 1,
	invoiceMethod: 1,// Default value: E-mail due to New prices.
	comment: "",
	accountNumber: "",
	bicCode: "",
	accountHolder: "",
	mandateId: "",
	domAuthorization: false,
	mandateDate: "",
	mandateHour: "",
	allInvoices: false
};

import { StateAction } from "../stateAction";
import { bspLeadActionTypes } from "./actionTypes";
import { IBSP } from "../../Models/BspLead";

export const bspLeadReducer = (state: IBSP, action: StateAction): IBSP => {
    switch (action.type) {
        case bspLeadActionTypes.INIT_BSPLEAD:
            return {
                ...action.payload.value
            };
        case bspLeadActionTypes.UPDATE_BSPLEAD:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };
        default:
            return state;
    }
};

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Footer from "./Footer";
import Header from "./Header";
import theme from "../../theme";
import {useTranslation} from "react-i18next";
import moment from "moment";

const useStyles = makeStyles({
	wrapper: {
		border: "16px solid #041196",
		boxSizing: "border-box",
		paddingTop: "0 !important",
		width: "100%",
		minHeight: "100vh",
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.down('md')]: {
			border: "none"
		}
	},
	publicPageWrapper: {
		padding: "0 !important",
		flex: "1 0 auto",
		alignItems: "flex-start",
		alignContent: "flex-start"
	},
	footer: {
		flexShrink: 0
	},
	invoicingAlertMessage: {
		background: "rgb(255, 0, 0)",
		width: "100%",
		padding: "24px 24px",
		color: "rgb(255, 255, 255)",
		fontSize: "16px"
	}
});

interface WrapperProps {
	children: React.ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => {
	const classes = useStyles();
	const { i18n } = useTranslation();
	const { t } = useTranslation("common");
	const [displayAlertMessage, setDisplayAlertMessage] = useState(false);
/*
	useEffect(() => {
		const now = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
		const startDate = moment("15/12/2023 19:00:00", "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
		const endDate = moment("15/12/2023 22:00:00", "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
		const isBetweenMaintenance = moment(now, "YYYY-MM-DD HH:mm:ss").isBetween(startDate, endDate, "minute", '[]');

		if (isBetweenMaintenance) {
			setDisplayAlertMessage(true);
		}
	}, []);
*/
	return (
		<div className={classes.wrapper}>
			<Grid container item className={classes.publicPageWrapper}>
				<Header />
				{(false) && (
					<Grid container item>
						<div className={classes.invoicingAlertMessage}>
							<span>{t("notificationMessages.alertMessage")}</span>
						</div>
					</Grid>
				)}
				<>{children}</>
			</Grid>
			<Grid container item className={classes.footer}>
				{i18n.language && (
					<Footer/>
				)}
			</Grid>
		</div>
	);
};

export default Wrapper;

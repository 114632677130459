import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    html,
    body,
    #__next {
        height: 100%;
        width: 100%;
    }
    body {
        padding-right: 0px !important;
    }
    #__next {
      @media (min-width: 600px) {
        width: calc(100vw - 5px);
      }
    }
    body::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #eaebf0;
    }
    body::-webkit-scrollbar {
        width: 5px;
        background-color: #eaebf0;
    }
    body::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #d0d2db;
    }
`;

export default GlobalStyle;

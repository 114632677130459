export interface IPersonalData {
	isPro: number; // Retrieve from previous steps
	companyName: string;
	companyForm: string;
	tvaNumber: string;
	tvaNumberAvailability: number;
	tvaNumberInProgress: boolean;
	civility: number;
	firstName: string;
	lastName: string;
	gsm: string;
	email: string;
	dateOfBirth: string;
	refCustomerId: string;
	promoCode: string;
}

export const emptyPersonalData: IPersonalData = {
	isPro: 0,
	companyName: "",
	companyForm: "",
	tvaNumber: "",
	tvaNumberAvailability: 1,
	tvaNumberInProgress: false,
	civility: 0,
	firstName: "",
	lastName: "",
	gsm: "",
	email: "",
	dateOfBirth: "",
	refCustomerId: "",
	promoCode: ""
};

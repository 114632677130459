export interface IBspLead {
    idlead: number,
    idaccount: number,
    idgas: any,
    idelec: number,
    isIndividual: any,
    language: string,
    first_name: string,
    last_name: string,
    title: number,
    fact_street_name: string,
    fact_street_number: string,
    fact_box: string,
    fact_complement: string,
    fact_zip: string,
    fact_city: string,
    fact_country: string,
    liv_street_name: string,
    liv_street_number: string,
    liv_box: string,
    liv_zip: string,
    liv_city: string,
    tel: string,
    gsm: string,
    email: string,
    niss: string,
    tva_number: string,
    company_name: string,
    company_status: number,
    create_date: string,
    simulation: string,
    remark: string,
    askFuelCard: number,
    askFuelOil: number,
    Birthdate: string,
    relocation: number,
    isArchived: any,
    mail_sent: any,
    contract_signed: any,
    promo_disabled: any
}

export interface IBspElec {
    actual_provider: string;
    connection_type: number;
    day_index: number;
    ean_code: string;
    ean_code_night: string;
    ex_night_index: number;
    hasPanels: number;
    idelec: number;
    index_date: string;
    injection: string;
    meter_code: string;
    meter_type: number;
    night_index: number;
    night_meter_code: string;
    relocation: number;
    start_date: string;
}


export const emptyBspElec: IBspElec = {
    actual_provider: "",
    connection_type: 0,
    day_index: 0,
    ean_code: "",
    ean_code_night: "",
    ex_night_index: 0,
    hasPanels: 0,
    idelec: 0,
    index_date: "",
    injection: "",
    meter_code: "",
    meter_type: 0,
    night_index: 0,
    night_meter_code: "",
    relocation: 0,
    start_date: ""
}

export interface IBspGas {
    actual_provider: string;
    connection_type: number;
    ean_code: string;
    idgas: number;
    index: number;
    index_date: string;
    meter_code: string;
    start_date: string;
}

export const emptyBspGas: IBspGas = {
    actual_provider: "",
    connection_type: 0,
    ean_code: "",
    idgas: 0,
    index: 0,
    index_date: "",
    meter_code: "",
    start_date: "",
}

export interface IBspContract {
    idlead: any,
    idaccount: any,
    idgas: any,
    idelec: any,
    isIndividual: any;
    language: string;
    last_name: string;
    first_name: string;
    title: number;
    fact_street_name: string;
    fact_street_number: string;
    fact_box: string;
    fact_complement: string;
    fact_zip: string;
    fact_city: string;
    fact_country: string;
    liv_street_name: string;
    liv_street_number: string;
    liv_box: string;
    liv_zip: string;
    liv_city: string;
    tel: string;
    gsm: string;
    email: string;
    niss: string;
    tva_number: string;
    company_name: string;
    company_status: number;
    simulation: any;
    remark: string;
    askFuelCard: string;
    askFuelOil: string;
    Birthdate: string;
    relocation: string;
    isArchived: any;
    mail_sent: any;
    contract_signed: any;
    promo_disabled: any;
    elec_meter_type: number;
    elec_ean_code: string;
    elec_meter_code: string;
    elec_index_date: string;
    elec_day_index: number;
    elec_night_index: number;
    elec_start_date: string;
    elec_actual_provider: string;
    elec_connection_type: number;
    elec_night_meter_code: string;
    elec_ean_code_night: string;
    elec_ex_night_index: number;
    elec_hasPanels: string;
    gas_meter_code: string;
    gas_ean_code: string;
    gas_index_date: string;
    gas_index: number;
    gas_connection_type: number;
    gas_start_date: string;
    gas_actual_provider: string;
}

export interface IBspTeam {
    ID: string;
    abbreviation: string;
    abbreviationWithoutPromo: string;
    code: string;
    description: string;
    name: string;
    salesGenForSimulation?: string;
}

export const emptyBspTeam: IBspTeam = {
    ID: "",
    abbreviation: "",
    abbreviationWithoutPromo: "",
    code: "",
    description: "",
    name: "",
    salesGenForSimulation: ""
}


export const emptyBspContract: IBspContract = {
    idlead: "",
    idaccount: "",
    idgas: "",
    idelec: "",
    isIndividual: "",
    language: "",
    last_name: "",
    first_name: "",
    title: 0,
    fact_street_name: "",
    fact_street_number: "",
    fact_box: "",
    fact_complement: "",
    fact_zip: "",
    fact_city: "",
    fact_country: "",
    liv_street_name: "",
    liv_street_number: "",
    liv_box: "",
    liv_zip: "",
    liv_city: "",
    tel: "",
    gsm: "",
    email: "",
    niss: "",
    tva_number: "",
    company_name: "",
    company_status: 0,
    promo_disabled: false,
    Birthdate: "",
    relocation: "",
    simulation: "",
    askFuelCard: "",
    askFuelOil: "",
    remark: "",
    isArchived: "",
    mail_sent: "",
    contract_signed: "",
    elec_meter_type: 0,
    elec_ean_code: "",
    elec_meter_code: "",
    elec_index_date: "",
    elec_day_index: 0,
    elec_night_index: 0,
    elec_start_date: "",
    elec_actual_provider: "",
    elec_connection_type: 0,
    elec_night_meter_code: "",
    elec_ean_code_night: "",
    elec_ex_night_index: 0,
    elec_hasPanels: "",
    gas_meter_code: "",
    gas_ean_code: "",
    gas_index_date: "",
    gas_index: 0,
    gas_connection_type: 0,
    gas_start_date: "",
    gas_actual_provider: "",
}

export const emptyBspLead: IBspLead = {
    idlead: 0,
    idaccount: 0,
    idgas: "",
    idelec: 0,
    isIndividual: "",
    language: "",
    first_name: "",
    last_name: "",
    title: 0,
    fact_street_name: "",
    fact_street_number: "",
    fact_box: "",
    fact_complement: "",
    fact_zip: "",
    fact_city: "",
    fact_country: "",
    liv_street_name: "",
    liv_street_number: "",
    liv_box: "",
    liv_zip: "",
    liv_city: "",
    tel: "",
    gsm: "",
    email: "",
    niss: "",
    tva_number: "",
    company_name: "",
    company_status: 0,
    create_date: "",
    simulation: "",
    remark: "",
    askFuelCard: 0,
    askFuelOil: 0,
    Birthdate: "",
    relocation: 0,
    isArchived: false,
    mail_sent: false,
    contract_signed: false,
    promo_disabled: false
};

export interface IBSP {
    bspLead: IBspLead,
    bspElec: IBspElec,
    bspGas: IBspGas,
    bspContract: IBspContract,
    bspTeam: IBspTeam
}

export const emptyBSP: IBSP = {
    bspLead: emptyBspLead,
    bspElec: emptyBspElec,
    bspGas: emptyBspGas,
    bspContract: emptyBspContract,
    bspTeam: emptyBspTeam
}


import React, { useContext, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import { DataContext } from "../../../Contexts/MainContext";
import colors from "../../../../utils/colors";

const dotBricks = `@keyframes dotBricks {
	0% {
		box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff;
	}
	8.333% {
		box-shadow: 10007px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff;
	}
	16.667% {
		box-shadow: 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff, 10007px 0 0 0 #9880ff;
	}
	25% {
		box-shadow: 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff;
	}
	33.333% {
		box-shadow: 10007px 0 0 0 #9880ff, 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff;
	}
	41.667% {
		box-shadow: 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff;
	}
	50% {
		box-shadow: 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff;
	}
	58.333% {
		box-shadow: 9991px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff;
	}
	66.666% {
		box-shadow: 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff, 9991px -16px 0 0 #9880ff;
	}
	75% {
		box-shadow: 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff;
	}
	83.333% {
		box-shadow: 9991px -16px 0 0 #9880ff, 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff;
	}
	91.667% {
		box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff;
	}
	100% {
		box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff;
	}
}`;

const useStyles = makeStyles({
	dialogRoot: {
		alignItems: "center",
		justifyContent: "center",
		padding: "2%"
	},
	paper: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		width: "100%",
		backgroundColor: "transparent",
		boxShadow: "none"
	},
	virtualDialog: {
		background: colors.white,
		border: `1px solid ${colors.borderGray}`,
		borderRadius: "15px",
		padding: "40px 30px"
	},
	spinner: {
		margin: "10px"
	},
	loadingMessage: {
		marginTop: "40px"
	},
	dotBricks: {
		position: "relative",
		top: "8px",
		left: "-9999px",
		width: "20px",
		height: "20px",
		borderRadius: "25px",
		backgroundColor: colors.octaBlue,
		color: colors.octaBlue,
		boxShadow: `9991px -16px 0 0 ${colors.octaBlue}, 9991px 0 0 0 ${colors.octaBlue}, 10007px 0 0 0 ${colors.octaBlue}`,
		animation: `$dotBricks 2s infinite ease`
	},
	"@keyframes dotBricks": {
		"0%": {
			boxShadow: `9991px -16px 0 0 ${colors.octaBlue}, 9991px 0 0 0 ${colors.octaBlue}, 10007px 0 0 0 ${colors.octaBlue}, 10007px -16px 0 0 ${colors.octaBlue}`
		},
		"50%": {
			boxShadow: `9958px -50px 0 0 ${colors.octaBlue}, 9958px 34px 0 0 ${colors.octaBlue}, 10048px 34px 0 0 ${colors.octaBlue}, 10048px -50px 0 0 ${colors.octaBlue}`
		},
		"100%": {
			boxShadow: `9991px -16px 0 0 ${colors.octaBlue}, 9991px 0 0 0 ${colors.octaBlue}, 10007px 0 0 0 ${colors.octaBlue}, 10007px -16px 0 0 ${colors.octaBlue}`
		}
	}
});

const GlobalLoader = () => {
	const classes = useStyles();
	const { state } = useContext(DataContext);
	const { t } = useTranslation("common");

	useEffect(() => {
		// console.log("GlobalLoader: useEffect loader", state.global.loader);
	}, [state.global]);

	return (
		<Dialog
			className={classes.dialogRoot}
			PaperProps={{
				classes: {
					root: classes.paper
				}
			}}
			open={_.get(state, "global.loader")}
			fullWidth
		>
			<div className={classes.dotBricks} />
		</Dialog>
	);
};

export default GlobalLoader;

import { SURVEY_VIEW } from "../../utils/constants";

export interface IStepper {
	activeStep: number;
}

export const emptyStepper: IStepper = {
	activeStep: 0
};

export interface IGlobalState {
	loader: boolean;
	view: string;
	stepper: IStepper;
}

export const emptyGlobalState: IGlobalState = {
	loader: false,
	view: SURVEY_VIEW,
	stepper: emptyStepper
};

export interface IElec {
	elecEanCode: string;
	elecExlNightEanCode: string;
	hasExlNightEanCode: boolean;
	hasPanels: boolean;
	hasDigitalCounter: boolean;
	elecCounterStatus: number;
	elecCounterType: number;
	elecCounterNumber: string;
	elecIndexDate: string;
	elecDayIndex: string;
	elecNightIndex: string;
	elecExclNightIndex: string;
	addInjection: number;
}

export const emptyElec: IElec = {
	elecEanCode: "",
	elecExlNightEanCode: "",
	hasExlNightEanCode: false,
	hasPanels: false,
	hasDigitalCounter: false,
	elecCounterStatus: 0,
	elecCounterType: 0,
	elecCounterNumber: "",
	elecIndexDate: "",
	elecDayIndex: "",
	elecNightIndex: "",
	elecExclNightIndex: "",
	addInjection: 1
};

import React, { Dispatch, useContext } from "react";
import { useTranslation } from "react-i18next";

import {
	Stepper,
	Step,
	StepLabel,
	Grid,
	StepConnector, stepConnectorClasses
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import * as _ from "lodash";
import cx from "classnames";
import { Check } from "@mui/icons-material";
import theme from "../../../theme";
import colors from "../../../../utils/colors";
import OctaButton from "../../Commons/OctaButton";
import { DataContext } from "../../../Contexts/MainContext";
import {styled} from "@mui/material/styles";

const useStyles = makeStyles({
	root: {
		width: "100%",
		[theme.breakpoints.down('md')]: {
			backgroundColor: colors.octaBlue
		}
	},
	rootStepper: {
		borderRadius: "25px 25px 0 0",
		padding: "12px 5px",
		[theme.breakpoints.down('md')]: {
			backgroundColor: colors.octaBlue
		}
	},
	mainContainer: {
		margin: "20px 0 0",
		paddingBottom: "20px",
		minHeight: "434px",
		height: "fit-content"
	},
	backButton: {
		marginRight: theme.spacing(1)
	},
	productPreviewRoot: {
		height: "fit-content",
		boxShadow: "0px 3px 10px #7F8EAA;",
		border: `1px solid rgb(128 142 169 / 40%)`,
		borderRadius: "15px"
	},
	productPreviewHeader: {
		height: "60px",
		backgroundColor: colors.octaBlue,
		borderRadius: "8px 8px 0 0",
		color: colors.white
	},
	productPreviewBody: {
		height: "180px",
		backgroundColor: colors.white,
		borderRadius: "0 0 8px 8px"
	},
	stepperLabel: {
		"& .MuiStepLabel-label": {
			color: "rgba(0, 0, 0, 0.54)",
			[theme.breakpoints.down('md')]: {
				color: colors.white
			}
		}
	},
	productLogo: {},
	gridSpacing: {
		"& > div": {
			margin: "8px"
		}
	}
});

const QontoConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: "15px",
		left: "calc(-50% + 16px)",
		right: "calc(50% + 16px)"
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: colors.octaBlue,
			[theme.breakpoints.down('md')]: {
				borderColor: colors.white
			}
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: colors.octaBlue,
			[theme.breakpoints.down('md')]: {
				borderColor: colors.white
			}
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: "#7F8EAA",
		borderTopWidth: "1px",
		borderRadius: 1
	},
}));

const useQontoStepIconStyles = makeStyles({
	root: {
		color: "#eaeaf0",
		display: "flex",
		border: "1px solid #7F8EAA",
		borderRadius: "50px",
		padding: "4px",
		alignItems: "center",
		[theme.breakpoints.down('md')]: {
			backgroundColor: "#7F8EAA"
		}
	},
	completedSm: {
		[theme.breakpoints.down('md')]: {
			border: `1px solid ${colors.white} !important`
		}
	},
	active: {
		color: "#784af4",
		border: `1px solid ${colors.octaBlue}`,
		[theme.breakpoints.down('md')]: {
			backgroundColor: colors.white
		}
	},
	circle: {
		width: "20px",
		height: "20px",
		borderRadius: "50px",
		backgroundColor: colors.white,
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		fontSize: "16px",
		color: "#7F8EAA",
		[theme.breakpoints.down('md')]: {
			backgroundColor: "#7F8EAA",
			color: colors.white
		}
	},
	activeCircle: {
		border: `1px solid ${colors.octaBlue}`,
		backgroundColor: colors.octaBlue
	},
	completed: {
		color: colors.white,
		zIndex: 1,
		fontSize: "18px",
		border: `1px solid ${colors.octaBlue}`,
		backgroundColor: colors.octaBlue
	}
});

function QontoStepIcon({
	active,
	completed,
	stepNumber
}: {
	active: boolean;
	completed: boolean;
	stepNumber: number;
}) {
	const classes = useQontoStepIconStyles();

	return (
		<div
			className={cx(
				classes.root,
				{
					[classes.active]: active
				},
				{
					[cx(classes.completed, classes.completedSm)]: completed
				}
			)}
		>
			{completed ? (
				<Check className={classes.completed} />
			) : (
				<div
					className={cx(classes.circle, {
						[classes.activeCircle]: active
					})}
				>
					{!active ? stepNumber : ""}
				</div>
			)}
		</div>
	);
}

const FormStepper = () => {
	const classes = useStyles();
	const { state, dispatch } = useContext(DataContext);
	const { t } = useTranslation("common");

	const getSteps = () => [
		t("steps.simulation"),
		t("steps.choose_product"),
		t("steps.souscription"),
		t("steps.confirm")
	];

	const steps = getSteps();

	return (
		<div className={classes.root}>
			<Stepper
				alternativeLabel
				activeStep={_.get(state, "global.stepper.activeStep")}
				connector={<QontoConnector />}
				classes={{ root: classes.rootStepper, alternativeLabel: classes.stepperLabel }}
			>
				{steps.map((label, key) => (
					<Step key={label}>
						<StepLabel
							StepIconComponent={(props) => (
								<QontoStepIcon {...props} stepNumber={key + 1} />
							)}
						>
							{label}
						</StepLabel>
					</Step>
				))}
			</Stepper>
		</div>
	);
};

export default FormStepper;

import { emptyPostCode, IPostCode } from "./PostCode";

export interface IAddress {
	street: string;
	streetNumber: string;
	mailBox: string;
	postalCode: IPostCode;
}

export const emptyAddress: IAddress = {
	street: "",
	streetNumber: "",
	mailBox: "",
	postalCode: emptyPostCode
};

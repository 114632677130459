import { ISimulation } from "../../Models/Simulation";
import { StateAction } from "../stateAction";
import { simulationActionTypes } from "./actionTypes";

export const simulationReducer = (state: ISimulation, action: StateAction): ISimulation => {
	// console.log("simulationReducer", action);
    switch (action.type) {
        case simulationActionTypes.UPDATE_SIMULATION:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };
        default:
            return state;
    }
};

import { emptyPostCode, IPostCode } from "./PostCode";

export interface IBillingAddress {
	billStreet: string;
	billStreetNumber: string;
	billMailBox: string;
	billPostalCode: IPostCode;
}

export const emptyBillingAddress: IBillingAddress = {
	billStreet: "",
	billStreetNumber: "",
	billMailBox: "",
	billPostalCode: emptyPostCode
};

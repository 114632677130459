export interface IProduct {
  Nrj: string;
  ProductType: string;
  Ranking: string;
  FixeVariable: string;
  Product: string;
  ProductNameNl: string;
  ProductNameFr: string;
  Duration: string;
  DurationUnitNl: string;
  DurationUnitFr: string;
  Amount: number;
  AmountUnitNl: string;
  AmountUnitFr: string;
  AmountWithWithoutVATNl: string;
  AmountWithWithoutVATFr: string;
}

export const emptyProduct: IProduct = {
  Nrj: "",
  ProductType: "",
  Ranking: "",
  FixeVariable: "",
  Product: "",
  ProductNameNl: "",
  ProductNameFr: "",
  Duration: "",
  DurationUnitNl: "",
  DurationUnitFr: "",
  Amount: 0,
  AmountUnitNl: "",
  AmountUnitFr: "",
  AmountWithWithoutVATNl: "",
  AmountWithWithoutVATFr: "",
};

export interface ProductSection {
  Type: string;
  Value: number;
  ValueUnitNl: string;
  ValueUnitFr: string;
  UnitValue: number;
  DescriptionNl: string;
  DescriptionFr: string;
  Section: number;
  SectionItem: number;
  UnitValueUnitFr?: string;
  UnitValueUnitNl?: string;
}

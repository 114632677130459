export interface IGas {
	gasEanCode: string;
	gasCounterStatus: number;
	gasCounterNumber: string;
	gasIndexDate: string;
	gasIndex: string;
}

export const emptyGas: IGas = {
	gasEanCode: "",
	gasCounterStatus: 0,
	gasCounterNumber: "",
	gasIndexDate: "",
	gasIndex: ""
};

import { emptyAddress, IAddress } from "./Address";
import { emptyPersonalData, IPersonalData } from "./PersonalData";
import { emptyFlags, IFlags } from "./Flags";
import { emptyDelivery, IDelivery } from "./Delivery";
import { emptyBilling, IBilling } from "./Billing";
import { emptyConfirmation, IConfirmation } from "./Confirmation";

export interface ILead {
	personalData: IPersonalData;
	delivery: IDelivery;
	billing: IBilling;
	confirmation: IConfirmation;
	flags: IFlags;
}

export const emptyLead: ILead = {
	personalData: emptyPersonalData,
	delivery: emptyDelivery,
	billing: emptyBilling,
	confirmation: emptyConfirmation,
	flags: emptyFlags
};

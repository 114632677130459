import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import theme from "../../theme";
import colors from "../../../utils/colors";

dayjs.extend(utc);
(dayjs.utc as any).Ls = dayjs.Ls;

const useStyle = makeStyles({
	button: {
		height: "100%",
		fontSize: 15,
		color: "white",
		align: "center",
		width: "100%",
		[theme.breakpoints.down("md")]: {
			lineHeight: 0.5,
			borderRadius: "50%",
			height: 38,
			width: 38,
			minWidth: 16,
			fontSize: 16,
			color: colors.octaBlue,
			border: `2px solid ${colors.octaBlue}`,
			"&:hover": {
				cursor: "pointer",
				border: "2px solid white",
				fontWeight: 600
			}
		}
	}
});

const SelectLanguage = () => {
	const classes = useStyle();
	const { i18n } = useTranslation();

	useEffect(() => {
		console.log("i18n.language: ", i18n.language);
		if (i18n.language === "fr" || i18n.language === "nl") {
			if (i18n.language === "fr") {
				dayjs.locale("fr");
			} else {
				dayjs.locale("nl");
			}
		}
	}, [i18n.language]);

	const changeLanguage = () => {
		const currentLng = i18n.language;
		if (currentLng === "fr") {
			i18n.changeLanguage("nl");
		} else {
			i18n.changeLanguage("fr");
		}
	};

	return (
		<Button className={classes.button} onClick={changeLanguage}>
			{i18n.language === "fr" ? "nl" : "fr"}
		</Button>
	);
};

export default SelectLanguage;

import { IQuestion } from "../src/Models/Question";
import * as _ from "lodash";
import { FIELD_REQUIRED } from "./error_messages";
import { IS_EG, IS_ELEC, IS_GAS, POSTAL_QUESTION_ID } from "./constants";
import { brusselsPostCodeCheck } from "./validators";
import { emptyAnswer } from "../src/Models/Answer";

export const checkSurveyData = (
    question: IQuestion,
    answersList: IQuestion[],
    partner?: any
) => {
    let errors: any = {};

    if (question.questionID === POSTAL_QUESTION_ID) {
        const profileAnswerObj = _.find(answersList, (item) => item.questionID === "Q1");
        if (profileAnswerObj) {
            let currentAnswer = _.get(question, "selectedAnswer.0", emptyAnswer);
            const questionErr = brusselsPostCodeCheck(_.get(currentAnswer, "answerValue.code", ""));
            if (questionErr) {
                errors[currentAnswer.answerID] = questionErr;
            }
        }
    }

    if (question.questionID == "Q5") {
        const counterTypeObject = _.find(question.selectedAnswer, (item) => item.answerID == "AN9" || item.answerID == "AN10");
        if (!counterTypeObject) errors["AN9"] = FIELD_REQUIRED

    }

    //conso Page
    if (question.questionID == "Q10") {
        const dayConsoObject = _.find(question.selectedAnswer, (item) => item.answerID == "AN19");
        const gasConsoObject = _.find(question.selectedAnswer, (item) => item.answerID == "AN40");
        const nightConsoObject = _.find(question.selectedAnswer, (item) => item.answerID == "AN38");
        const exNightConsoObject = _.find(question.selectedAnswer, (item) => item.answerID == "AN39");
        const energy = getEnergy(answersList);
        if ((energy == IS_ELEC || energy == IS_EG) && _.isUndefined(dayConsoObject) || _.get(dayConsoObject, "answerValue") == "") {
            errors["AN19"] = FIELD_REQUIRED
        }
        if ((energy == IS_GAS || energy == IS_EG) && _.isUndefined(gasConsoObject) || _.get(gasConsoObject, "answerValue") == "") {
            errors["AN40"] = FIELD_REQUIRED
        }
        if ((energy == IS_ELEC || energy == IS_EG) && isBiHoraireCounter(answersList) && (_.isUndefined(nightConsoObject) || _.get(nightConsoObject, 'answerValue', "") == "" || _.get(nightConsoObject, 'answerValue', 0) == 0)) {
            errors["AN38"] = FIELD_REQUIRED
        }
        if ((energy == IS_ELEC || energy == IS_EG) && isExNightCounter(answersList) && (_.isUndefined(exNightConsoObject) || _.get(exNightConsoObject, 'answerValue', "") == "" || _.get(exNightConsoObject, 'answerValue', 0) == 0)) {
            errors["AN39"] = FIELD_REQUIRED
        }
    }

    //Injection Page
    if (question.questionID == "Q12") {
        const daySamplingObject = _.find(question.selectedAnswer, (item) => item.answerID == "AN42");
        const nightSamplingObject = _.find(question.selectedAnswer, (item) => item.answerID == "AN43");
        const dayInjectionObject = _.find(question.selectedAnswer, (item) => item.answerID == "AN44");
        const nightInjectionObject = _.find(question.selectedAnswer, (item) => item.answerID == "AN45");
        if (_.isUndefined(daySamplingObject) || (daySamplingObject.answerValue == "" && daySamplingObject.answerValue != 0)) {
            errors["AN42"] = FIELD_REQUIRED
        }
        if (_.isUndefined(nightSamplingObject) || (nightSamplingObject.answerValue == "" && nightSamplingObject.answerValue != 0)) {
            errors["AN43"] = FIELD_REQUIRED
        }
        if (isBiHoraireCounter(answersList) && (_.isUndefined(nightInjectionObject) || (_.get(nightInjectionObject, 'answerValue', "") == "" && nightSamplingObject.answerValue != 0))) {
            errors["AN45"] = FIELD_REQUIRED
        }
        if (isExNightCounter(answersList) && (_.isUndefined(dayInjectionObject) || (_.get(dayInjectionObject, 'answerValue', "") == "" && dayInjectionObject.answerValue != 0))) {
            errors["AN44"] = FIELD_REQUIRED
        }
    }


    return errors;
}


export const isBiHoraireCounter = (userAnswers: IQuestion[]) => {
    const counterTypeQuestion = _.find(userAnswers, (item) => item.questionID == "Q5")
    if (counterTypeQuestion) {
        const counterTypeAnswer = _.find(counterTypeQuestion.selectedAnswer, (item) => item.answerID == "AN9" || item.answerID == "AN10")
        if (counterTypeAnswer) return (counterTypeAnswer.answerValue == "2" || counterTypeAnswer.answerValue == "AN10") ? true : false
    }
    return false
}

export const isMonoHoraireCounter = (userAnswers: IQuestion[]) => {
    const counterTypeQuestion = _.find(userAnswers, (item) => item.questionID == "Q5")
    if (counterTypeQuestion) {
        const counterTypeAnswer = _.find(counterTypeQuestion.selectedAnswer, (item) => item.answerID == "AN9" || item.answerID == "AN10")
        if (counterTypeAnswer) return (counterTypeAnswer.answerValue == "1" || counterTypeAnswer.answerValue == "AN9") ? true : false
    }
    return false
}

export const isExNightCounter = (userAnswers: IQuestion[]) => {
    const counterTypeQuestion = _.find(userAnswers, (item) => item.questionID == "Q5")
    if (counterTypeQuestion) {
        const counterTypeAnswer = _.find(counterTypeQuestion.selectedAnswer, (item) => item.answerID == "AN11")
        if (counterTypeAnswer) return counterTypeAnswer.answerChecked
    }
    return false
}

export const getEnergy = (userAnswers: IQuestion[]) => {
    const energyQuestion = _.find(userAnswers, (item) => item.questionID == "Q2")
    if (energyQuestion) {
        const energyAnswer = _.get(_.last(energyQuestion.selectedAnswer), 'answerValue')
        if (energyAnswer) return (energyAnswer == "AN3") ? IS_ELEC : ((energyAnswer == "AN4") ? IS_GAS : (energyAnswer == "AN5") ? IS_EG : energyAnswer)
    }
    return false
}
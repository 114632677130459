import get from "lodash/get";

import { StateAction } from "../stateAction";
import { globalActionTypes } from "./actionTypes";
import { IGlobalState } from "../../Models/GlobalState";
import { SURVEY_VIEW } from "../../../utils/constants";

export const globalReducer = (state: IGlobalState, action: StateAction): IGlobalState => {
	switch (action.type) {
		case globalActionTypes.SWITCH_VIEW:
			return {
				...state,
				view: get(action, "payload.viewName", SURVEY_VIEW)
			};
		case globalActionTypes.SET_STEP:
			return {
				...state,
				stepper: {
					...state.stepper,
					activeStep: get(action, "payload.step")
				}
			};
		case globalActionTypes.NEXT_STEP:
			return {
				...state,
				stepper: {
					...state.stepper,
					activeStep: parseInt(String(get(state, "stepper.activeStep")), 10) + 1
				}
			};
		case globalActionTypes.PREV_STEP:
			return {
				...state,
				stepper: {
					...state.stepper,
					activeStep: parseInt(String(get(state, "stepper.activeStep")), 10) - 1
				}
			};
		case globalActionTypes.SET_LOADER_ACTIVE:
			return {
				...state,
				loader: true
			};
		case globalActionTypes.SET_LOADER_INACTIVE:
			return {
				...state,
				loader: false
			};
		default:
			return state;
	}
};

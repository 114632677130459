import get from "lodash/get";
import * as _ from "lodash";
import { StateAction } from "../stateAction";
import { leadActionTypes } from "./actionTypes";
import { ILead } from "../../Models/Lead";
import { emptyElec } from "../../Models/Elec";
import { emptyGas } from "../../Models/Gas";
import { emptyProduct } from "../../Models/Product";
import { INTERNET_PRODUCTS, PROFESSIONAL } from "../../../utils/constants";

export const leadReducer = (state: ILead, action: StateAction): ILead => {
	switch (action.type) {
		case leadActionTypes.SET_DYNAMIC_PARAMS:
			let updatedState = state;
			let userDetails = get(action, "payload.userDetails", {});
			const selectedProducts = _.get(action, "payload.selectedProducts", {});

			return {
				...state,
				personalData: {
					...updatedState.personalData,
					isPro: userDetails.userType == PROFESSIONAL ? 1 : 0
				},
				delivery: {
					...state.delivery,
					address: {
						...state.delivery.address,
						postalCode: {
							code: userDetails.postCode,
							locality: userDetails.locality
						}
					},
					elec: {
						...state.delivery.elec,
						hasDigitalCounter: userDetails.hasDigitalMeter,
						elecCounterType: userDetails.isMono ? 1 : 2,
						hasExlNightEanCode: userDetails.hasExclNight,
						hasPanels: userDetails.hasPanels
					},
					autoDeterminedInj: userDetails.isConsumptionKnown,
					hasInternetProduct:
						_.includes(
							INTERNET_PRODUCTS,
							_.lowerCase(_.get(selectedProducts, "elec.ProductNameFr", ""))
						) &&
						_.includes(
							INTERNET_PRODUCTS,
							_.lowerCase(_.get(selectedProducts, "gas.ProductNameFr", ""))
						)
				},
				flags: {
					hasElec: !_.isEqual(selectedProducts.elec, emptyProduct),
					hasGas: !_.isEqual(selectedProducts.gas, emptyProduct)
				}
			};
		case leadActionTypes.SET_SAVED_SIMU_PARAMS:
			let newState = state;
			let valuesObj = _.get(action, "payload.values", {});


			newState = _.set(newState, "personalData.isPro", valuesObj.isPro);
			newState = _.set(
				newState,
				"delivery.address.postalCode",
				valuesObj.postalCode
			);
			newState = _.set(
				newState,
				"delivery.elec.hasDigitalCounter",
				valuesObj.hasDigitalCounter
			);
			newState = _.set(
				newState,
				"delivery.elec.elecCounterType",
				valuesObj.elecCounterType
			);
			newState = _.set(
				newState,
				"delivery.elec.hasExlNightEanCode",
				valuesObj.hasExlNightEanCode
			);
			newState = _.set(
				newState,
				"delivery.elec.hasPanels",
				valuesObj.hasPanels
			);

			const selProducts = _.get(action, "payload.selectedProducts", {});

			return {
				...newState,
				delivery: {
					...newState.delivery,
					hasInternetProduct:
						_.includes(
							INTERNET_PRODUCTS,
							_.lowerCase(_.get(selProducts, "elec.ProductNameFr", ""))
						) &&
						_.includes(
							INTERNET_PRODUCTS,
							_.lowerCase(_.get(selProducts, "gas.ProductNameFr", ""))
						)
				},
				flags: {
					hasElec: !_.isEqual(selProducts.elec, emptyProduct),
					hasGas: !_.isEqual(selProducts.gas, emptyProduct)
				}
			};
		case leadActionTypes.SET_PROMO_CODE:
			//console.log("leadReducer SET_PROMO_CODE", action.payload);
			return {
				...state,
				personalData: {
					...state.personalData,
					promoCode: action.payload
				}
			};
		case leadActionTypes.SET_REF_ID:
			//console.log("leadReducer SET_REF_ID", action.payload);
			return {
				...state,
				personalData: {
					...state.personalData,
					refCustomerId: action.payload
				}
			};
		case leadActionTypes.SET_USER_DATA:
			//console.log("leadReducer SET_USER_DATA", action.payload);
			// eslint-disable-next-line no-case-declarations
			const fullName = `${_.get(action, "payload.firstName", "")} ${_.get(
				action,
				"payload.lastName",
				""
			)}`;
			// eslint-disable-next-line no-case-declarations
			const tempAccountHolder =
				_.get(state, "billing.accountHolder", "") === ""
					? fullName
					: _.get(state, "billing.accountHolder", "");

			return {
				...state,
				personalData: {
					...state.personalData,
					companyName: _.get(action, "payload.companyName", ""),
					companyForm: _.get(action, "payload.companyForm", ""),
					tvaNumber: _.get(action, "payload.tvaNumber", ""),
					tvaNumberAvailability: _.get(
						action,
						"payload.tvaNumberAvailability",
						1
					),
					tvaNumberInProgress: _.get(action, "payload.tvaNumberInProgress", 0),
					civility: _.get(action, "payload.civility", 0),
					firstName: _.get(action, "payload.firstName", ""),
					lastName: _.get(action, "payload.lastName", ""),
					gsm: _.get(action, "payload.gsm", ""),
					email: _.get(action, "payload.email", ""),
					dateOfBirth: _.get(action, "payload.dateOfBirth", "")
				},
				delivery: {
					...state.delivery,
					address: {
						street: _.get(action, "payload.street", ""),
						streetNumber: _.get(action, "payload.streetNumber", ""),
						mailBox: _.get(action, "payload.mailBox", ""),
						postalCode: {
							code: _.get(action, "payload.postalCode.code", ""),
							locality: _.get(action, "payload.postalCode.locality", "")
						}
					},
					elec: {
						..._.get(state, "delivery.elec"),
						elecEanCode: _.get(action, "payload.elecEanCode", ""),
						elecExlNightEanCode: _.get(
							action,
							"payload.elecExlNightEanCode",
							""
						),
						elecCounterStatus: _.get(action, "payload.elecCounterStatus", 0),
						addInjection: _.get(action, "payload.addInjection", 0)
					},
					gas: {
						..._.get(state, "delivery.gas"),
						gasEanCode: _.get(action, "payload.gasEanCode", ""),
						gasCounterStatus: _.get(action, "payload.gasCounterStatus", 0)
					},
					startDate: _.get(action, "payload.startDate", ""),
					sameAsInvoicing: _.get(action, "payload.sameAsInvoicing", true),
					currentSituation: _.get(action, "payload.currentSituation", ""),
					lastSupplier: _.get(action, "payload.lastSupplier", "")
				},
				billing: {
					..._.get(state, "billing"),
					address: {
						billStreet: _.get(action, "payload.sameAsInvoicing", true)
							? _.get(action, "payload.street", "")
							: _.get(action, "payload.billStreet", ""),
						billStreetNumber: _.get(action, "payload.sameAsInvoicing", true)
							? _.get(action, "payload.streetNumber", "")
							: _.get(action, "payload.billStreetNumber", ""),
						billMailBox: _.get(action, "payload.sameAsInvoicing", true)
							? _.get(action, "payload.mailBox", "")
							: _.get(action, "payload.billMailBox", ""),
						billPostalCode: _.get(action, "payload.sameAsInvoicing", true)
							? _.get(action, "payload.postalCode", {})
							: _.get(action, "payload.billPostalCode", {})
					},
					accountHolder:
						_.get(action, "payload.method", 0) === 1 ? tempAccountHolder : "",
					method: _.get(action, "payload.method", 0),
					frequency: _.get(action, "payload.frequency", 0),
					comment: _.get(action, "payload.comment", "")
				}
			};
		case leadActionTypes.SET_FINANCIAL_DATA:
			//console.log("leadReducer SET_FINANCIAL_DATA", action.payload);
			return {
				...state,
				delivery: {
					...state.delivery,
					elec: {
						..._.get(state, "delivery.elec"),
						elecCounterNumber: _.get(action, "payload.elecCounterNumber", ""),
						elecIndexDate: _.get(action, "payload.elecIndexDate", ""),
						elecDayIndex: _.get(action, "payload.elecDayIndex", ""),
						elecNightIndex: _.get(action, "payload.elecNightIndex", ""),
						elecExclNightIndex: _.get(action, "payload.elecExclNightIndex", "")
					},
					gas: {
						..._.get(state, "delivery.gas"),
						gasCounterNumber: _.get(action, "payload.gasCounterNumber", ""),
						gasIndexDate: _.get(action, "payload.gasIndexDate", ""),
						gasIndex: _.get(action, "payload.gasIndex", "")
					}
				},
				billing: {
					..._.get(state, "billing"),
					mandateId: _.get(action, "payload.mandateId", ""),
					mandateDate: _.get(action, "payload.mandateDate", ""),
					mandateHour: _.get(action, "payload.mandateHour", ""),
					accountNumber: _.get(action, "payload.accountNumber", ""),
					bicCode: _.get(action, "payload.bicCode", ""),
					accountHolder: _.get(action, "payload.accountHolder", ""),
					domAuthorization: _.get(action, "payload.domAuthorization", false),
					allInvoices: _.get(action, "payload.allInvoices", false),
					address: {
						billStreet: _.get(action, "payload.billStreet", ""),
						billStreetNumber: _.get(action, "payload.billStreetNumber", ""),
						billMailBox: _.get(action, "payload.billMailBox", ""),
						billPostalCode: _.get(action, "payload.billPostalCode", {})
					}
				}
			};
		case leadActionTypes.UPDATE_PERSONNAL_DATA:
			//console.log("leadReducer UPDATE_PERSONNAL_DATA", action.payload);
			return {
				...state,
				personalData: {
					...state.personalData,
					...action.payload
				}
			};
		case leadActionTypes.UPDATE_ELEC_DATA:
			//console.log("leadReducer UPDATE_ELEC_DATA", action.payload);
			return {
				...state,
				delivery: {
					...state.delivery,
					elec: {
						...state.delivery.elec,
						...action.payload
					}
				}
			};
		case leadActionTypes.UPDATE_GAS_DATA:
			//console.log("leadReducer UPDATE_GAS_DATA", action.payload);
			return {
				...state,
				delivery: {
					...state.delivery,
					gas: {
						...state.delivery.gas,
						...action.payload
					}
				}
			};
		case leadActionTypes.UPDATE_BILLING_ADDR:
			//console.log("leadReducer UPDATE_BILLING_ADDR", action.payload);
			let isSameAddr = true;

			if (_.get(action, "state.delivery.address.street", "") !== _.get(action, "payload.billStreet", "")) {
				isSameAddr = false;
			}
			if (_.get(action, "state.delivery.address.streetNumber", "") !== _.get(action, "payload.billStreetNumber", "")) {
				isSameAddr = false;
			}
			if (_.get(action, "state.delivery.address.mailBox", "") !== _.get(action, "payload.billMailBox", "")) {
				isSameAddr = false;
			}
			if (_.get(action, "state.delivery.address.postalCode.code", "") !== _.get(action, "payload.billPostalCode.code", "")) {
				isSameAddr = false;
			}

			return {
				...state,
				delivery: {
					...state.delivery,
					sameAsInvoicing: isSameAddr
				},
				billing: {
					...state.billing,
					address: {
						...state.billing.address,
						...action.payload
					}
				}
			};
		case leadActionTypes.UPDATE_BILLING_DATA:
			//console.log("leadReducer UPDATE_BILLING_DATA", action.payload);
			return {
				...state,
				billing: {
					...state.billing,
					...action.payload
				}
			};
		case leadActionTypes.VALIDATE_SIGN_UP:
			//console.log("leadReducer VALIDATE_SIGN_UP", action.payload);
			return {
				...state,
				personalData: {
					...state.personalData
				},
				confirmation: {
					...state.confirmation,
					..._.get(action, "payload.confirmationData", {})
				}
			};
		case leadActionTypes.INIT_LEAD:
			//console.log("INIT_LEAD", action);
			return _.get(action, "payload", {})
		default:
			return state;
	}
};

export const FIELD_REQUIRED = "errors.required";
export const WRONG_FORMAT = "errors.format";
export const INVALID_POSTCODE = "errors.postCodeInvalid";
export const SELECT_OPTION_REQUIRED = "errors.selectOptionRequired";
export const PRO_POWER_RANGE_INCORRECT = "errors.valueRangeIncorrect";
export const IND_POWER_RANGE_INCORRECT = "errors.userPowerLimit";
export const VALID_VALUE_REQUIRED = "errors.validValueRequired";
export const TOO_OLD_ERR = "errors.minBirthDateMessage";
export const TOO_YOUNG_ERR = "errors.maxBirthDateMessage";
export const WRONG_EMAIL_FORMAT = "errors.emailFormat";
export const WRONG_VAT_FORMAT = "errors.vatFormat";
export const GSM_REQUIRED = "errors.gsmRequired";
export const GSM_LENGTH = "errors.gsmLength";
export const MAX_CHANGES_EXCEEDED = "errors.maxChangesExceeded";
export const SAME_EAN_CODE = "errors.sameEanCode";
export const BLOCK_BRUSSELS = "errors.brusselsBlockedZipCode";
export const WRONG_EAN_NUMBER = "errors.wrongEanNumber";

export interface IPartner {
	partnerId: number;
	partnerName: string;
	salesGenerator: string; // AppAffaireRef WEG
	salesGeneratorForSimulation?: string; // AppAffaireRef WEG
	shouldBypassLead: boolean;
	canalId: string;
	agent: string;
	agentMember: string;
	redirectUrl: string;
	language?: string;
	eDrive?: boolean;
}
export const emptyPartner: IPartner = {
	partnerId: 99,
	partnerName: "",
	salesGenerator: "", // AppAffaireRef WEG
	salesGeneratorForSimulation: "",
	shouldBypassLead: false,
	canalId: "",
	agent: "",
	agentMember: "",
	redirectUrl: "",
	language: "",
	eDrive: false
};

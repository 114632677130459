export interface IAnswer { }

export interface IAnswer {
    answerID: string;
    answerLabel: string;
    answerType: string;
    nextQuestionID: string;
    answerValue: any;
    answerChecked?: boolean;
    answerValueType: string;
    isProfessional?: boolean;
    icon?: string;
    keyPath?: string;
    valueType?: string;
    unit?: any;
}

export const emptyAnswer: IAnswer = {
    answerID: "",
    answerLabel: "",
    answerType: "",
    nextQuestionID: "",
    answerValue: "",
    answerValueType: "",
    answerChecked: false,
    isProfessional: false,
    icon: "",
    keyPath: ""
}

/* eslint-disable prettier/prettier */
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// eslint-disable-next-line camelcase
import content_fr from "./misc/locales/fr/common.json";
// eslint-disable-next-line camelcase
import content_nl from "./misc/locales/nl/common.json";

const resources = {
	fr: {
		common: content_fr
	},
	nl: {
		common: content_nl
	}
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: "fr",
		interpolation: {
			escapeValue: false
		},
		load: "languageOnly"
	});

export default i18n;

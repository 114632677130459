import IBAN from "iban";
import replace from "lodash/replace";
import size from "lodash/size";
import startsWith from "lodash/startsWith";
import find from "lodash/find";
import forEach from "lodash/forEach";
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";

import { checkVAT, belgium } from "./lib/jsvat";
import { postalCodeList } from "./PostCodeList";
import {
	BLOCK_BRUSSELS,
	FIELD_REQUIRED,
	GSM_LENGTH,
	GSM_REQUIRED,
	INVALID_POSTCODE,
	MAX_CHANGES_EXCEEDED,
	SAME_EAN_CODE,
	TOO_OLD_ERR,
	TOO_YOUNG_ERR,
	PRO_POWER_RANGE_INCORRECT,
	IND_POWER_RANGE_INCORRECT,
	WRONG_EAN_NUMBER,
	WRONG_EMAIL_FORMAT,
	WRONG_FORMAT,
	WRONG_VAT_FORMAT
} from "./error_messages";

export const REGEX_EMAIL =
	/^(([^<>()\[\]\\.,;éèàêäëôù:!#$%&*+/=?{|}~\s@"]+(\.[^<>()\[\]\\.,;éèàêäëôù:!#$%&*+/=?{|}~\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

// export const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

// const REGEX_PHONE = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/; // eslint-disable-line no-useless-escape
const REGEX_NISS = /^[0-9]{2}.[0-9]{2}.[0-9]{2}-[0-9]{3}.[0-9]{2}$/; // eslint-disable-line no-useless-escape

const stringNotBlank = (value: any) => {
	return !!value && !moment.isMoment(value) && !!value.trim() ? null : (moment.isMoment(value) ? null : FIELD_REQUIRED);
}

const inputNotBlank = (value: any) => {
	return !!value && !!value.trim() ? null : FIELD_REQUIRED;
}

const checkboxValidation = (value: any) => (value ? null : FIELD_REQUIRED);

const selectNotBlank = (value: any) =>
	// eslint-disable-next-line no-restricted-globals
	!!value && !isNaN(value) ? null : FIELD_REQUIRED;

const refCustomerIdValidation = (value: any) => {
	const requiredError = selectNotBlank(value);

	if (requiredError) {
		return requiredError;
	}

	const valueSize = String(value).length;
	// eslint-disable-next-line no-restricted-globals
	return valueSize >= 4 && valueSize <= 7 ? null : WRONG_FORMAT;
};

const numberValidation = (value: any) => {
	const requiredError = inputNotBlank(String(value));

	if (requiredError) {
		return requiredError;
	}

	// eslint-disable-next-line no-restricted-globals
	return !isNaN(value) ? null : WRONG_FORMAT;
};

const phoneValidation = (value: any) => {
	const formattedValue = replace(value, /[./]/g, "");
	const requiredError = selectNotBlank(formattedValue);
	const lengthError = GSM_LENGTH;

	if (size(value) <= 11) {
		return lengthError
	}
	if (requiredError) {
		return requiredError;
	}

	// eslint-disable-next-line no-restricted-globals,radix
	return !isNaN(parseInt(formattedValue)) ? null : WRONG_FORMAT;
};

const mobileValidation = (value: any) => {
	const requiredError = phoneValidation(value);

	if (requiredError) {
		return requiredError;
	}

	if (
		(startsWith(value, "00") || startsWith(value, "+")) &&
		!startsWith(value, "0032") &&
		!startsWith(value, "+32")
	) {
		return null;
	}
	return startsWith(value, "04") ||
		startsWith(value, "00324") ||
		startsWith(value, "+324")
		? null
		: GSM_REQUIRED;
};

const dateValidation = (value: any) => {
	const requiredError = stringNotBlank(value);

	if (requiredError) {
		return requiredError;
	}

	return moment(moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")).isValid()
		? null
		: WRONG_FORMAT;
};

const dateOfBirthValidation = (value: any) => {
	const requiredError = stringNotBlank(value);

	if (requiredError) {
		return requiredError;
	}
	if (!moment(moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")).isValid()) {
		return WRONG_FORMAT;
	}
	if (
		moment(moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")).isBefore(
			moment().subtract(100, "years")
		)
	) {
		return TOO_OLD_ERR;
	}
	if (
		moment(moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")).isAfter(
			moment().subtract(18, "years")
		)
	) {
		return TOO_YOUNG_ERR;
	}

	return null;
};

const emailValidation = (value: any) => {
	const requiredError = stringNotBlank(value);

	if (requiredError) {
		return requiredError;
	}

	return REGEX_EMAIL.test(String(value).toLowerCase())
		? null
		: WRONG_EMAIL_FORMAT;
};

const postalCodeValidation = (value: { code: number; locality: string }) => {
	let requiredError = selectNotBlank(value.code);
	if (requiredError) {
		return requiredError;
	}

	requiredError = stringNotBlank(value.locality);

	if (requiredError) {
		return requiredError;
	}

	return find(
		postalCodeList,
		(item) => item.code === value.code && item.locality === value.locality
	)
		? null
		: INVALID_POSTCODE;
};

const panelsPowerValidation = (value: string, isPro: boolean) => {
	const parsedValue = parseFloat(value);

	let requiredError = selectNotBlank(parsedValue);

	if (requiredError) {
		return requiredError;
	}

	if (isPro) {
		return (parsedValue >= 0 && parsedValue <= 30) ? null : PRO_POWER_RANGE_INCORRECT;
	} else {
		return (parsedValue >= 0 && parsedValue <= 10) ? null : IND_POWER_RANGE_INCORRECT;
	}
};

const findDifference = (firstInput: any, secondInput: any) => {
	let difference = "";
	let splitedCharacter = null;
	let compareTo = "";

	if (firstInput.length >= secondInput.length) {
		splitedCharacter = firstInput.split("");
		compareTo = secondInput;
	} else {
		splitedCharacter = secondInput.split("");
		compareTo = firstInput;
	}
	splitedCharacter =
		firstInput.length >= secondInput.length
			? firstInput.split("")
			: secondInput.split("");
	forEach(splitedCharacter, (element: any, index: number) => {
		if (compareTo[index] !== element) {
			difference += element;
		}
	});
	return difference;
};

/*
const phoneValidation = (value: any) => {
  const requiredError = stringNotBlank(value);

  if (requiredError) {
	return requiredError;
  }

  return REGEX_PHONE.test(String(value).toLowerCase()) ? null : (
	  CONSTANTS.WRONG_FORMAT
  );
}
*/
/*
const nissValidation = (value: any) => {
	const requiredError = stringNotBlank(value);

	if (requiredError) {
		return requiredError;
	}

	if (!REGEX_NISS.test(String(value).toLowerCase())) {
		return WRONG_FORMAT;
	}

	const nissOnlyNumber = value.split(".").join("").split("-").join("");

	if (Number(nissOnlyNumber.substring(2, 4)) > 12) {
		return WRONG_FORMAT;
	}
	if (Number(nissOnlyNumber.substring(4, 6)) > 31) {
		return WRONG_FORMAT;
	}

	const firstNineNumber = Number(nissOnlyNumber.substring(0, 9));
	const rest = firstNineNumber % 97;
	if (
		97 - rest ===
		Number(
			nissOnlyNumber.substring(nissOnlyNumber.length - 2, nissOnlyNumber.length)
		)
	) {
		return null;
	}
	const newNiss = "2".concat(nissOnlyNumber);
	const firstTenNumber = Number(newNiss.substring(0, 10));
	const newRest = firstTenNumber % 97;
	if (
		97 - newRest ===
		Number(newNiss.substring(newNiss.length - 2, newNiss.length))
	) {
		return null;
	}

	{
		return WRONG_FORMAT;
	}
};
*/

// TODO: Check if the validation is correct.
// eslint-disable-next-line consistent-return
const vatValidation = (value: any) => {
	const requiredError = stringNotBlank(value);

	if (requiredError) {
		return requiredError;
	}

	if (!REGEX_NISS.test(String(value).toLowerCase())) {
		return checkVAT(value, [belgium]).isValid ? null : WRONG_VAT_FORMAT;
	}
};

const ibanOriginValidation = (value: any) => {
	if (
		!startsWith(value, "BE") &&
		!startsWith(value, "NL") &&
		!startsWith(value, "FR") &&
		!startsWith(value, "GB") &&
		!startsWith(value, "DE")
	) {
		return WRONG_FORMAT;
	}
	return null;
};

const brusselsPostCodeCheck = (value: number) => {
	if (value >= 1000 && value <= 1299) {
		return BLOCK_BRUSSELS;
	}
	return null;
};

const surveyPostCodeValidation = (value: { code: number; locality: string }) => {

	let requiredError =	postalCodeValidation(value);

	if (requiredError) {
		return requiredError;
	}

	if (value.code >= 1000 && value.code <= 1299) {
		return BLOCK_BRUSSELS;
	}
	return null;
};

const ibanValidation = (value: any) => {
	const requiredError = stringNotBlank(value);

	if (requiredError) {
		return requiredError;
	}

	const IbanCountryError = ibanOriginValidation(value);

	if (IbanCountryError) {
		return IbanCountryError;
	}

	return IBAN.isValid(value) ? null : WRONG_FORMAT;
};

const sameEanCodeValidation = (value: any, ndValue: any) =>
	value !== ndValue ? null : SAME_EAN_CODE;

const levDist = (s: any, t: any) => {
	const d: any = []; // 2d matrix

	// Step 1
	const n = s.length;
	const m = t.length;

	if (n === 0) return m;
	if (m === 0) return n;

	// Create an array of arrays in javascript (a descending loop is quicker)
	for (let k = n; k >= 0; k--) d[k] = [];

	// Step 2
	for (let r = n; r >= 0; r--) d[r][0] = r;
	for (let l = m; l >= 0; l--) d[0][l] = l;

	// Step 3
	for (let i = 1; i <= n; i++) {
		// eslint-disable-next-line camelcase
		const s_i = s.charAt(i - 1);

		// Step 4
		for (let j = 1; j <= m; j++) {
			// Check the jagged ld total so far
			if (i === j && d[i][j] > 4) return n;

			const t_j = t.charAt(j - 1);
			const cost = s_i === t_j ? 0 : 1; // Step 5

			// Calculate the minimum
			let mi = d[i - 1][j] + 1;
			const b = d[i][j - 1] + 1;
			const c = d[i - 1][j - 1] + cost;

			if (b < mi) mi = b;
			if (c < mi) mi = c;

			d[i][j] = mi; // Step 6

			// Damerau transposition
			if (
				i > 1 &&
				j > 1 &&
				s_i === t.charAt(j - 2) &&
				s.charAt(i - 2) === t_j
			) {
				d[i][j] = Math.min(d[i][j], d[i - 2][j - 2] + cost);
			}
		}
	}
	// Step 7
	if (d[n][m] <= 3) {
		return null;
	}
	return MAX_CHANGES_EXCEEDED;
};

const eanCodeValidation = (eanCode: string) => {
	let even = 0;
	let odd = 0;
	let total = 0;
	let res;

	const requiredError = stringNotBlank(eanCode);

	if (requiredError) {
		return requiredError;
	}

	if (eanCode.length !== 18) {
		return WRONG_FORMAT;
	}
	for (let i = 0; i < eanCode.length - 1; i++) {
		if (i % 2 === 0) {
			even += Number(eanCode[i]);
		} else {
			odd += Number(eanCode[i]);
		}
	}
	total = even * 3 + odd;
	res = total % 10;
	if (res !== 0) {
		res = 10 - res;
	}
	if (res === Number(eanCode[eanCode.length - 1])) {
		return null;
	}
	return WRONG_EAN_NUMBER;
};

export {
	stringNotBlank,
	inputNotBlank,
	checkboxValidation,
	selectNotBlank,
	refCustomerIdValidation,
	numberValidation,
	phoneValidation,
	mobileValidation,
	dateValidation,
	dateOfBirthValidation,
	emailValidation,
	postalCodeValidation,
	panelsPowerValidation,
	eanCodeValidation,
	sameEanCodeValidation,
	vatValidation,
	ibanValidation,
	findDifference,
	levDist,
	brusselsPostCodeCheck,
	surveyPostCodeValidation
};

import * as _ from "lodash";
import { StateAction } from "../stateAction";
import { partnerActionTypes } from "./actionTypes";
import { IPartner } from "../../Models/Partner";

export const partnerReducer = (
	state: IPartner,
	action: StateAction
): IPartner => {
	//console.log("partnerReducer", action);
	switch (action.type) {
		case partnerActionTypes.SAVE_PARTNER_INFO:
			//console.log("partnerReducer SAVE_PARTNER_INFO", action.payload);
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};
